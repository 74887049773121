import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import "./ProductsPage.css";
import { Container, Grid, Button, Segment } from "semantic-ui-react";

const ProductsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleWordCloud = () => {
        navigate("/WordCloudEventListPage", { state: { token: location.state.token, username: location.state.username } });
    }

    const handleWordWall = () => {
        navigate("/WordWallEventListPage", { state: { token: location.state.token, username: location.state.username } });
    }

    const handleMosaic = () => {
        navigate("/MosaicEventListPage", { state: { token: location.state.token, username: location.state.username } });

    }

    const handlePLAY = () => {

    }

    const handleQuiz = () => {

    }

    const handleSpinningWheel = () => {

    }


    return (
        <div style={{ backgroundColor: "#EAEAF1", minHeight: "100vh" }}>
            <TopBar />
            <Container textAlign="center">

                <Grid centered columns={1} verticalAlign="middle" style={{ height: "100vh" }}>
                    <Grid.Column style={{ maxWidth: 600 }}>
                        <h1 style={{ textAlign: "center", marginBottom: "5vh" , fontSize:"40px"}}>Products</h1>

                        <Segment style={{ borderRadius: "20px" }}>
                            <Grid columns={3} divided={false} >
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button  className="custom-product-button-wordcloud" onClick={handleWordCloud}>
                                            Word Cloud
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button className="custom-product-button-wordwall" onClick={handleWordWall}>
                                            Word Wall
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button className="custom-product-button-mosaic"   onClick={handleMosaic}>
                                            Mosaic
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button className="custom-product-button-play"onClick={handlePLAY}>
                                            PLAY
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button  className="custom-product-button-quiz" onClick={handleQuiz}>
                                            Quiz
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button  className="custom-product-button-spinningwheel"  onClick={handleSpinningWheel}>
                                            Spinning Wheel
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>

                    </Grid.Column>
                </Grid>
            </Container>
            <Footer />
        </div>

    )
}

export default ProductsPage;