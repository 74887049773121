import React from "react";
import { Container, Header, Menu, Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

const Footer = () => {
  return (
    <Menu fixed="bottom" style={{borderTop: '1px solid black'}}>
      <Container fluid>
        <Header
          as="h1"
          inverted
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            color: "black"
          }}
        >
          Polite Playground
        </Header>
      </Container>
    </Menu>
  );
};

export default Footer;
