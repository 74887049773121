import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, Await } from "react-router-dom";
import Footer from "./components/Footer";
import WordCloudTopBar from "./components/WordCloudTopBar";
import "./WordCloudDashboardPage.css";
import { Container, Grid, Form, Button, Header, Segment, Divider, Input, Icon, Dropdown, Menu, Checkbox, Image, Modal } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChromePicker } from 'react-color';
import { set } from "mongoose";
import Swal from "sweetalert2";



const WordCloudDashboardPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const wordFontSize = (word) => {
        const maxLength = 4; // Maximum length for which to set font size
        const baseFontSize = 16; // Base font size
        const fontSizeDecrement = 0.1; // Font size increment per character

        const adjustedFontSize = Math.min(
            baseFontSize - (word.length - maxLength) * fontSizeDecrement,
            baseFontSize
        );
        return adjustedFontSize;
    };

    const [newOptionText0, setNewOptionText0] = useState('');
    const [newOptionText1, setNewOptionText1] = useState('');
    const [newOptionText2, setNewOptionText2] = useState('');

    const [promptInput, setPromptInput] = useState([]);

    const [filteredWords, setFilteredWords] = useState([]);
    const [filteredWordsInputValue, setFilteredWordsInputValue] = useState('');

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [timezone, setTimezone] = useState();
    const [eventLocation, setEventLocation] = useState();
    const [endUsers, setEndUsers] = useState([]);

    const [activeTab, setActiveTab] = useState('Dashboard');

    //Assume text block has maximum of 3, email 1, phone number 1, number 1, checkbox 3, dropdown 3, urlcheckbox 3
    const [isTextBlockRequired, setIsTextBlockRequired] = useState(false);
    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const [isPhoneNumberRequired, setIsPhoneNumberRequired] = useState(false);
    const [isNumberRequired, setIsNumberRequired] = useState(false);
    const [isCheckBoxRequired, setIsCheckBoxRequired] = useState(false);
    const [isDropdownRequired, setIsDropdownRequired] = useState(false);
    const [isURLCheckboxRequired, setIsURLCheckboxRequired] = useState(false);

    const [isTextBlockRequired1, setIsTextBlockRequired1] = useState(false);
    const [isTextBlockRequired2, setIsTextBlockRequired2] = useState(false);

    const [isCheckBoxRequired1, setIsCheckBoxRequired1] = useState(false);
    const [isCheckBoxRequired2, setIsCheckBoxRequired2] = useState(false);

    const [isDropdownRequired1, setIsDropdownRequired1] = useState(false);
    const [isDropdownRequired2, setIsDropdownRequired2] = useState(false);

    const [isURLCheckboxRequired1, setIsURLCheckboxRequired1] = useState(false);
    const [isURLCheckboxRequired2, setIsURLCheckboxRequired2] = useState(false);

    const [textBlockTitle, setTextBlockTitle] = useState('');
    const [emailTitle, setEmailTitle] = useState('');
    const [phoneNumberTitle, setPhoneNumberTitle] = useState('');
    const [numberTitle, setNumberTitle] = useState('');
    const [checkBoxTitle, setCheckBoxTitle] = useState('');
    const [dropdownTitle, setDropdownTitle] = useState('');
    const [urlCheckboxTitle, setUrlCheckboxTitle] = useState('');

    const [textBlockTitle1, setTextBlockTitle1] = useState('');
    const [textBlockTitle2, setTextBlockTitle2] = useState('');

    const [checkBoxTitle1, setCheckBoxTitle1] = useState('');
    const [checkBoxTitle2, setCheckBoxTitle2] = useState('');

    const [dropdownTitle1, setDropdownTitle1] = useState('');
    const [dropdownTitle2, setDropdownTitle2] = useState('');

    const [urlCheckboxTitle1, setUrlCheckboxTitle1] = useState('');
    const [urlCheckboxTitle2, setUrlCheckboxTitle2] = useState('');


    const [textBlockCount, setTextBlockCount] = useState(0);
    const [emailCount, setEmailCount] = useState(0);
    const [phoneNumberCount, setPhoneNumberCount] = useState(0);
    const [numberCount, setNumberCount] = useState(0);
    const [checkBoxCount, setCheckBoxCount] = useState(0);
    const [dropdownCount, setDropdownCount] = useState(0);
    const [urlCheckboxCount, setUrlCheckboxCount] = useState(0);

    const [selectedDropdownValue0, setSelectedDropdownValue0] = useState();
    const [selectedDropdownValue1, setSelectedDropdownValue1] = useState();
    const [selectedDropdownValue2, setSelectedDropdownValue2] = useState();
    // const [selectedFont, setSelectedFont] = useState('');
    const [selectedShape, setSelectedShape] = useState('');
    const [selectedShapeValue, setSelectedShapeValue] = useState(null);
    const [selectedShapeName, setSelectedShapeName] = useState('');

    const [selectedAnimation, setSelectedAnimation] = useState('');
    const [selectedAnimationValue, setSelectedAnimationValue] = useState('');

    const [draggableElementsArray, setDraggableElementArray] = useState([
        // {
        //     id: 'Text Block0',
        //     title: 'Question Text',
        // },
    ])

    const [draggableItems, updateDraggableItems] = useState(draggableElementsArray);

    const [wordCloudQuestion, setWordCloudQuestion] = useState('');
    const [wordCloudCustomBackground, setWordCloudCustomBackground] = useState(null);
    const [selectedCustomBackground, setSelectedCustomBackground] = useState('');
    const [wordCloudCustomBackgroundName, setWordCloudCustomBackgroundName] = useState('');
    // const [selectedFont, setSelectedFont] = useState('');
    const [wordCloudFont, setWordCloudFont] = useState(null);
    const [wordCloudFontName, setWordCloudFontName] = useState('');
    const [wordCloudLogo, setWordCloudLogo] = useState(null);
    const [selectedLogo, setSelectedLogo] = useState('');
    const [wordCloudLogoName, setWordCloudLogoName] = useState('');
    // const [wordCloudCustomURL, setWordCloudCustomURL] = useState('');

    const [fontColor, setFontColor] = useState('');
    const [buttonFillColor, setButtonFillColor] = useState('');
    const [buttonStrokeColor, setButtonStrokeColor] = useState('');
    const [buttonTextColor, setButtonTextColor] = useState('');


    const [backgroundColour, setBackgroundColour] = useState('');
    const [textColour, setTextColour] = useState('');

    const [displayFontColorPicker, setDisplayFontColorPicker] = useState(false);
    const [displayButtonFillColorPicker, setDisplayButtonFillColorPicker] = useState(false);
    const [displayButtonStrokeColorPicker, setDisplayButtonStrokeColorPicker] = useState(false);
    const [displayButtonTextColorPicker, setDisplayButtonTextColorPicker] = useState(false);

    const [displayBackgroundColourPicker, setDisplayBackgroundColourPicker] = useState(false);
    const [displayTextColourPicker, setDisplayTextColourPicker] = useState(false);

    const [customBackgroundList, setCustomBackgroundList] = useState([]);
    const [logoList, setLogoList] = useState([]);
    const [shapeList, setShapeList] = useState([]);
    const [animationList, setAnimationList] = useState([]);

    const [selectAll, setSelectAll] = useState(false);
    const [jobId, setJobId] = useState(0);
    const [customFontID, setCustomFontID] = useState('');

    const handleFilteredWordsInputChange = () => {
        if (filteredWordsInputValue.trim() !== '') {
            setFilteredWords([...filteredWords, filteredWordsInputValue]);
            setFilteredWordsInputValue('');
        }
    };

    const handleFontColorChange = (newColour) => { setFontColor(newColour.hex) }
    const handleButtonFillColorChange = (newColour) => { setButtonFillColor(newColour.hex) }
    const handleButtonStrokeColorChange = (newColour) => { setButtonStrokeColor(newColour.hex) }
    const handleButtonTextColorChange = (newColour) => { setButtonTextColor(newColour.hex) }
    const handleBackgroundColourChange = (newColour) => { setBackgroundColour(newColour.hex) }

    const handleFontColorChangeFromText = (e, { value }) => { setFontColor(value) }
    const handleButtonFillColorChangeFromText = (e, { value }) => { setButtonFillColor(value) }
    const handleButtonStrokeColorChangeFromText = (e, { value }) => { setButtonStrokeColor(value) }
    const handleButtonTextColorChangeFromText = (e, { value }) => { setButtonTextColor(value) }

    const handleFontColorClose = () => { setDisplayFontColorPicker(false) }
    const handleButtonFillColorClose = () => { setDisplayButtonFillColorPicker(false) }
    const handleButtonStrokeColorClose = () => { setDisplayButtonStrokeColorPicker(false) }
    const handleButtonTextColorClose = () => { setDisplayButtonTextColorPicker(false) }

    const handleBackgroundColourClose = () => { setDisplayBackgroundColourPicker(false) }
    const handleBackgroundColourChangeFromText = (e, { value }) => { setBackgroundColour(value) }

    const handleTextColourChange = (newColour) => { setTextColour(newColour.hex) }
    const handleTextColourClose = () => { setDisplayTextColourPicker(false) }
    const handleTextColourChangeFromText = (e, { value }) => { setTextColour(value) }

    const handleFontColorOnClick = () => { setDisplayFontColorPicker(!displayFontColorPicker) }
    const handleButtonFillColorOnClick = () => { setDisplayButtonFillColorPicker(!displayButtonFillColorPicker) }
    const handleButtonStrokeColorOnClick = () => { setDisplayButtonStrokeColorPicker(!displayButtonStrokeColorPicker) }
    const handleButtonTextColorOnClick = () => { setDisplayButtonTextColorPicker(!displayButtonTextColorPicker) }

    const handlePickerOnClick = () => { setDisplayBackgroundColourPicker(!displayBackgroundColourPicker) }
    const handleTextColourPickerOnClick = () => { setDisplayTextColourPicker(!displayTextColourPicker) }

    const handleAnimationClicked = (item) => {
        setSelectedAnimation(item.id);
        setSelectedAnimationValue(item.name.substring(0, item.name.lastIndexOf(".")));
    }

    const handleShapeClicked = (item) => {
        setSelectedShape(item.id)
        setSelectedShapeName(item.name.substring(0,
            item.name.lastIndexOf(".")))
    }

    const handleCustomBackgroundClicked = (item) => {
        setSelectedCustomBackground(item.id)
        setWordCloudCustomBackgroundName(item.name)
    }

    const handleLogoClicked = (item) => {
        setSelectedLogo(item.id)
        setWordCloudLogoName(item.name)
    }

    const handleOptionTextChange0 = (event) => {
        setNewOptionText0(event.target.value);
    }
    const handleOptionTextChange1 = (event) => {
        setNewOptionText1(event.target.value);
    }
    const handleOptionTextChange2 = (event) => {
        setNewOptionText2(event.target.value);
    }

    const handleAddDropdownOptions0 = () => {
        if (newOptionText0.trim() !== '') {
            const option = {
                key: newOptionText0,
                text: newOptionText0,
                value: newOptionText0,
            };
            setOptions0([...options0, option]);
            setNewOptionText0('');
        }
    }

    const handleAddDropdownOptions1 = () => {
        if (newOptionText1.trim() !== '') {
            const option = {
                key: newOptionText1,
                text: newOptionText1,
                value: newOptionText1,
            };
            setOptions1([...options1, option]);
            setNewOptionText1('');
        }
    }

    const handleAddDropdownOptions2 = () => {
        if (newOptionText2.trim() !== '') {
            const option = {
                key: newOptionText2,
                text: newOptionText2,
                value: newOptionText2,
            };
            setOptions2([...options2, option]);
            setNewOptionText2('');
        }
    }

    const handleCustomBackgroundChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setWordCloudCustomBackground(event.target.files[0]);
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            axios.post('/api/googleUpload/custom-background', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .then((response) => {
                    setWordCloudCustomBackgroundName(response.data.name);
                    fetchCustomBackgroundList();
                    setSelectedCustomBackground(response.data.id);
                    // Reset the form or update the UI as needed
                })
                .catch((error) => {
                    console.error(error);
                    console.log('Error uploading file');
                });
        }
    };

    const handleFontChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setWordCloudFont(event.target.files[0]);
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            axios
                .post("/api/googleUpload/font", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response) => {
                    // console.log(response.data.id)
                    const fileNameWithExt = response.data.name;
                    const fontKeyID = response.data.id;
                    const fileNameWithoutExt = fileNameWithExt.substring(
                        0,
                        fileNameWithExt.lastIndexOf(".")
                    );
                    const option = { key: fontKeyID, text: fileNameWithoutExt, value: fileNameWithoutExt };
                    if (!fontOptions.some((f) => f.key === option.key)) {
                        const updatedFontOptions = [...fontOptions, option];
                        setFontOptions(updatedFontOptions);
                    }
                    setWordCloudFontName(fileNameWithoutExt);
                    setCustomFontID(response.data.id)
                    // Reset the form or update the UI as needed
                })
                .catch((error) => {
                    console.error(error);
                    console.log("Error uploading file");
                });
        }
    };

    const handleLogoChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setWordCloudLogo(event.target.files[0]);
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            axios.post('/api/googleUpload/logo', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .then((response) => {
                    // console.log(response.data);
                    setWordCloudLogoName(response.data.name);
                    fetchLogoList();
                    setSelectedLogo(response.data.id);
                    // Reset the form or update the UI as needed
                })
                .catch((error) => {
                    console.error(error);
                    console.log('Error uploading file');
                });
        }
    };

    const handleShapeChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedShapeValue(event.target.files[0]);
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            axios
                .post("/api/googleUpload/shape", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response) => {
                    const fileNameWithExt = response.data.name;
                    const fileNameWithoutExt = fileNameWithExt.substring(
                        0,
                        fileNameWithExt.lastIndexOf(".")
                    );
                    const option = { key: fileNameWithoutExt, text: fileNameWithoutExt, value: fileNameWithoutExt };
                    if (!shapeOptions.some((s) => s.key === option.key)) {
                        const updatedShapeOptions = [...shapeOptions, option];
                        setShapeOptions(updatedShapeOptions);
                    }
                    setSelectedShapeName(fileNameWithoutExt);
                    fetchShapeList();
                    setSelectedShape(response.data.id);
                    // Reset the form or update the UI as needed
                })
                .catch((error) => {
                    console.error(error);
                    console.log("Error uploading file");
                });
        }
    };

    useEffect(() => {
        updateDraggableItems(draggableElementsArray);
    }, [draggableElementsArray]);

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(draggableItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updateDraggableItems(items);
    }

    function deleteItem(id) {
        const newElementsArray = draggableElementsArray.filter(item => item.id !== id);
        setDraggableElementArray(newElementsArray);
        if (id == 'Text Block0' || id == 'Text Block1' || id == 'Text Block2') {
            if (textBlockCount > 0) {
                setTextBlockCount(textBlockCount - 1);
                if (id == 'Text Block0') {
                    setTextBlockTitle('');
                    setIsTextBlockRequired(false);
                } else if (id == 'Text Block1') {
                    setTextBlockTitle1('');
                    setIsTextBlockRequired1(false);
                } else if (id == 'Text Block2') {
                    setTextBlockTitle2('');
                    setIsTextBlockRequired2(false);
                }
            }
        } else if (id == 'Email0') {
            if (emailCount > 0) {
                setEmailCount(emailCount - 1);
                setEmailTitle('');
                setIsEmailRequired(false)
            }
        } else if (id == 'Phone Number0') {
            if (phoneNumberCount > 0) {
                setPhoneNumberCount(phoneNumberCount - 1);
                setPhoneNumberTitle('');
                setIsPhoneNumberRequired(false)
            }
        } else if (id == 'Number0') {
            if (numberCount > 0) {
                setNumberCount(numberCount - 1);
                setNumberTitle('');
                setIsNumberRequired(false)
            }
        } else if (id == 'Check Box0' || id == 'Check Box1' || id == 'Check Box2') {
            if (checkBoxCount > 0) {
                setCheckBoxCount(checkBoxCount - 1);
                if (id == 'Check Box0') {
                    setCheckBoxTitle('');
                    setIsCheckBoxRequired(false);
                }
                else if (id == 'Check Box1') {
                    setCheckBoxTitle1('');
                    setIsCheckBoxRequired1(false)
                }
                else if (id == 'Check Box2') {
                    setCheckBoxTitle2('');
                    setIsCheckBoxRequired2(false)
                }
            }
        } else if (id == 'Dropdown0' || id == 'Dropdown1' || id == 'Dropdown2') {
            if (dropdownCount > 0) {
                setDropdownCount(dropdownCount - 1);
                if (id == 'Dropdown0') {
                    setDropdownTitle('');
                    setIsDropdownRequired(false)
                    setOptions0([])
                }
                else if (id == 'Dropdown1') {
                    setDropdownTitle1('');
                    setIsDropdownRequired1(false)
                    setOptions1([])

                }
                else if (id == 'Dropdown2') {
                    setDropdownTitle2('');
                    setIsDropdownRequired2(false)
                    setOptions2([])

                }
            }
        } else if (id == 'URL Checkbox0' || id == 'URL Checkbox1' || id == 'URL Checkbox2') {
            if (urlCheckboxCount > 0) {
                setUrlCheckboxCount(urlCheckboxCount - 1);
                if (id == 'URL Checkbox0') {
                    setUrlCheckboxTitle('');
                    setIsURLCheckboxRequired(false)
                }
                else if (id == 'URL Checkbox1') {
                    setUrlCheckboxTitle1('');
                    setIsURLCheckboxRequired1(false)
                }
                else if (id == 'URL Checkbox2') {
                    setUrlCheckboxTitle2('');
                    setIsURLCheckboxRequired2(false)
                }
            }
        }
    }

    const addTextBlock = () => {
        if (textBlockCount < 3) {
            const idExists = draggableElementsArray.some(element => element.id === 'Text Block' + textBlockCount);
            if (!idExists) {
                setDraggableElementArray([...draggableElementsArray, {
                    id: 'Text Block' + textBlockCount,
                    title: 'Question Text',
                    value: ''
                }]);
                setTextBlockTitle('Question Text')
                setTextBlockCount(textBlockCount + 1);
            } else {
                let arr = [0, 1, 2]
                arr = arr.filter(element => element !== textBlockCount);
                const idExists1 = draggableElementsArray.some(element => element.id === 'Text Block' + arr[0]);
                if (!idExists1) {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Text Block' + arr[0],
                        title: 'Question Text',
                        value: ''
                    }]);
                    setTextBlockTitle1('Question Text')
                    setTextBlockCount(textBlockCount + 1);
                } else {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Text Block' + arr[1],
                        title: 'Question Text',
                        value: ''
                    }]);
                    setTextBlockTitle2('Question Text')
                    setTextBlockCount(textBlockCount + 1);
                }
            }
        }
    }

    const addEmail = () => {
        if (emailCount < 1) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Email' + emailCount,
                title: 'Email',
                value: ''
            }]);
            setEmailTitle('Email')
            setEmailCount(emailCount + 1);
        }
    }

    const addPhoneNumber = () => {
        if (phoneNumberCount < 1) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Phone Number' + phoneNumberCount,
                title: 'Phone Number',
                value: ''
            }]);
            setPhoneNumberTitle('Phone Number')
            setPhoneNumberCount(phoneNumberCount + 1);
        }
    }

    const addNumber = () => {
        if (numberCount < 1) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Number' + numberCount,
                title: 'Number',
                value: ''
            }]);
            setNumberTitle('Number')
            setNumberCount(numberCount + 1);
        }
    }

    const addCheckBox = () => {
        if (checkBoxCount < 3) {
            const idExists = draggableElementsArray.some(element => element.id === 'Check Box' + checkBoxCount);
            if (!idExists) {
                setDraggableElementArray([...draggableElementsArray, {
                    id: 'Check Box' + checkBoxCount,
                    title: 'Check Box',
                    value: ''
                }]);
                setCheckBoxTitle('Check Box')
                setCheckBoxCount(checkBoxCount + 1);
            } else {
                let arr = [0, 1, 2]
                arr = arr.filter(element => element !== textBlockCount);
                const idExists1 = draggableElementsArray.some(element => element.id === 'Check Box' + arr[0]);
                if (!idExists1) {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Check Box' + arr[0],
                        title: 'Check Box',
                        value: ''
                    }]);
                    setCheckBoxTitle1('Check Box')
                    setCheckBoxCount(checkBoxCount + 1);
                } else {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Check Box' + arr[1],
                        title: 'Check Box',
                        value: ''
                    }]);
                    setCheckBoxTitle2('Check Box')
                    setCheckBoxCount(checkBoxCount + 1);
                }
            }
        }
    }

    const addDropdown = () => {
        if (dropdownCount < 3) {
            const idExists = draggableElementsArray.some(element => element.id === 'Dropdown' + dropdownCount);
            if (!idExists) {
                setDraggableElementArray([...draggableElementsArray, {
                    id: 'Dropdown' + dropdownCount,
                    title: 'Dropdown',
                    value: ''
                }]);
                setDropdownTitle('Dropdown')
                setDropdownCount(dropdownCount + 1);
            } else {
                let arr = [0, 1, 2]
                arr = arr.filter(element => element !== dropdownCount);
                const idExists1 = draggableElementsArray.some(element => element.id === 'Dropdown' + arr[0]);
                if (!idExists1) {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Dropdown' + arr[0],
                        title: 'Dropdown',
                        value: ''
                    }]);
                    setDropdownTitle1('Dropdown')
                    setDropdownCount(dropdownCount + 1);
                } else {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Dropdown' + arr[1],
                        title: 'Dropdown',
                        value: ''
                    }]);
                    setDropdownTitle2('Dropdown')
                    setDropdownCount(dropdownCount + 1);
                }
            }
        }
    }

    const addURLCheckbox = () => {
        if (urlCheckboxCount < 3) {
            const idExists = draggableElementsArray.some(element => element.id === 'URL Checkbox' + urlCheckboxCount);
            if (!idExists) {
                setDraggableElementArray([...draggableElementsArray, {
                    id: 'URL Checkbox' + urlCheckboxCount,
                    title: 'URL Checkbox',
                    value: ''
                }]);
                setUrlCheckboxTitle('URL Checkbox')
                setUrlCheckboxCount(urlCheckboxCount + 1);
            } else {
                let arr = [0, 1, 2]
                arr = arr.filter(element => element !== urlCheckboxCount);
                const idExists1 = draggableElementsArray.some(element => element.id === 'URL Checkbox' + arr[0]);
                if (!idExists1) {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'URL Checkbox' + arr[0],
                        title: 'URL Checkbox',
                        value: ''
                    }]);
                    setUrlCheckboxTitle1('URL Checkbox')
                    setUrlCheckboxCount(urlCheckboxCount + 1);
                } else {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'URL Checkbox' + arr[1],
                        title: 'URL Checkbox',
                        value: ''
                    }]);
                    setUrlCheckboxTitle2('URL Checkbox')
                    setUrlCheckboxCount(urlCheckboxCount + 1);
                }
            }
        }
    }

    const renderItem = (item) => {
        if (item.id === 'Text Block0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={textBlockTitle != '' ? textBlockTitle : ''}
                                            onChange={(event) => setTextBlockTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isTextBlockRequired} onClick={handleTextBlockRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                            style={{ backgroundColor: "#F2F2F7 !important" }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id == 'Text Block1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={textBlockTitle1 != '' ? textBlockTitle1 : ''}
                                            onChange={(event) => setTextBlockTitle1(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isTextBlockRequired1} onClick={handleTextBlockRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id == 'Text Block2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={textBlockTitle2 != '' ? textBlockTitle2 : ''}
                                            onChange={(event) => setTextBlockTitle2(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isTextBlockRequired2} onClick={handleTextBlockRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Email0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={emailTitle != '' ? emailTitle : ''}
                                            onChange={(event) => setEmailTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isEmailRequired} onClick={handleEmailRequiredChange} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Phone Number0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={phoneNumberTitle != '' ? phoneNumberTitle : ''}
                                            onChange={(event) => setPhoneNumberTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isPhoneNumberRequired} onClick={handlePhoneNumberRequiredChange} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Number0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={numberTitle != '' ? numberTitle : ''}
                                            onChange={(event) => setNumberTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isNumberRequired} onClick={handleNumberRequiredChange} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Check Box0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={checkBoxTitle != '' ? checkBoxTitle : ''}
                                            onChange={(event) => setCheckBoxTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isCheckBoxRequired} onClick={handleCheckBoxRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Check Box1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={checkBoxTitle1 != '' ? checkBoxTitle1 : ''}
                                            onChange={(event) => setCheckBoxTitle1(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isCheckBoxRequired1} onClick={handleCheckBoxRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Check Box2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={checkBoxTitle2 != '' ? checkBoxTitle2 : ''}
                                            onChange={(event) => setCheckBoxTitle2(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isCheckBoxRequired2} onClick={handleCheckBoxRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Dropdown0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={dropdownTitle != '' ? dropdownTitle : ''}
                                            onChange={(event) => setDropdownTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isDropdownRequired} onClick={handleDropdownRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Dropdown
                                            selection
                                            fluid
                                            options={options0}
                                            value={selectedDropdownValue0}
                                            onChange={handleDropdownChange0}
                                            placeholder='' style={{
                                                textAlign: 'center',
                                                backgroundColor: '#f2f2f7'
                                            }}
                                        />
                                        <Grid style={{ marginTop: "1vh" }}>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Input fluid type="text" value={newOptionText0} onChange={handleOptionTextChange0} placeholder="Add Option Here" />
                                                </Grid.Column>
                                                <Grid.Column style={{ textAlign: "left" }}>
                                                    <Button as="label" onClick={handleAddDropdownOptions0} type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                        Add Option
                                                        <Icon name="plus" />
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Dropdown1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={dropdownTitle1 != '' ? dropdownTitle1 : ''}
                                            onChange={(event) => setDropdownTitle1(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isDropdownRequired1} onClick={handleDropdownRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Dropdown
                                            selection
                                            fluid
                                            options={options1}
                                            value={selectedDropdownValue1}
                                            onChange={handleDropdownChange1}
                                            placeholder='' style={{
                                                textAlign: 'center',
                                                backgroundColor: '#f2f2f7'
                                            }}
                                        />
                                        <Grid style={{ marginTop: "1vh" }}>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Input fluid type="text" value={newOptionText1} onChange={handleOptionTextChange1} placeholder="Add Option Here" />
                                                </Grid.Column>
                                                <Grid.Column style={{ textAlign: "left" }}>
                                                    <Button as="label" onClick={handleAddDropdownOptions1} type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                        Add Option
                                                        <Icon name="plus" />
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Dropdown2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={dropdownTitle2 != '' ? dropdownTitle2 : ''}
                                            onChange={(event) => setDropdownTitle2(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isDropdownRequired2} onClick={handleDropdownRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Dropdown
                                            selection
                                            fluid
                                            options={options2}
                                            value={selectedDropdownValue2}
                                            onChange={handleDropdownChange2}
                                            placeholder='' style={{
                                                textAlign: 'center',
                                                backgroundColor: '#f2f2f7'
                                            }}
                                        />
                                        <Grid style={{ marginTop: "1vh" }}>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Input fluid type="text" value={newOptionText2} onChange={handleOptionTextChange2} placeholder="Add Option Here" />
                                                </Grid.Column>
                                                <Grid.Column style={{ textAlign: "left" }}>
                                                    <Button as="label" onClick={handleAddDropdownOptions2} type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                        Add Option
                                                        <Icon name="plus" />
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'URL Checkbox0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)} >
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={urlCheckboxTitle != '' ? urlCheckboxTitle : ''}
                                            onChange={(event) => setUrlCheckboxTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isURLCheckboxRequired} onClick={handleURLCheckboxRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'URL Checkbox1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={urlCheckboxTitle1 != '' ? urlCheckboxTitle1 : ''}
                                            onChange={(event) => setUrlCheckboxTitle1(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isURLCheckboxRequired1} onClick={handleURLCheckboxRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'URL Checkbox2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={urlCheckboxTitle2 != '' ? urlCheckboxTitle2 : ''}
                                            onChange={(event) => setUrlCheckboxTitle2(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isURLCheckboxRequired2} onClick={handleURLCheckboxRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else {

        }
    };


    const handleTextBlockRequiredChange0 = () => {
        setIsTextBlockRequired(!isTextBlockRequired);
    }
    const handleEmailRequiredChange = () => {
        setIsEmailRequired(!isEmailRequired);
    }
    const handlePhoneNumberRequiredChange = () => {
        setIsPhoneNumberRequired(!isPhoneNumberRequired);
    }
    const handleNumberRequiredChange = () => {
        setIsNumberRequired(!isNumberRequired);
    }
    const handleCheckBoxRequiredChange0 = () => {
        setIsCheckBoxRequired(!isCheckBoxRequired);
    }
    const handleDropdownRequiredChange0 = () => {
        setIsDropdownRequired(!isDropdownRequired);
    }
    const handleURLCheckboxRequiredChange0 = () => {
        setIsURLCheckboxRequired(!isURLCheckboxRequired);
    }

    const handleTextBlockRequiredChange1 = () => {
        setIsTextBlockRequired1(!isTextBlockRequired1);
    }

    const handleTextBlockRequiredChange2 = () => {
        setIsTextBlockRequired2(!isTextBlockRequired2);
    }

    const handleCheckBoxRequiredChange1 = () => {
        setIsCheckBoxRequired1(!isCheckBoxRequired1);
    }

    const handleCheckBoxRequiredChange2 = () => {
        setIsCheckBoxRequired2(!isCheckBoxRequired2);
    }

    const handleDropdownRequiredChange1 = () => {
        setIsDropdownRequired1(!isDropdownRequired1);
    }
    const handleDropdownRequiredChange2 = () => {
        setIsDropdownRequired2(!isDropdownRequired2);
    }
    const handleURLCheckboxRequiredChange1 = () => {
        setIsURLCheckboxRequired1(!isURLCheckboxRequired1);
    }
    const handleURLCheckboxRequiredChange2 = () => {
        setIsURLCheckboxRequired2(!isURLCheckboxRequired2);
    }

    const handleDropdownChange0 = (e, { value }) => {
        setSelectedDropdownValue0(value);
    }
    const handleDropdownChange1 = (e, { value }) => {
        setSelectedDropdownValue1(value);
    }
    const handleDropdownChange2 = (e, { value }) => {
        setSelectedDropdownValue2(value);
    }

    const handleFontDropdownChange = (event, data) => {
        const selectedFontOption = fontOptions.find((option) => option.value === data.value);
        if (selectedFontOption) {
            setWordCloudFont(selectedFontOption.value)
            setWordCloudFontName(selectedFontOption.value);
            setCustomFontID(selectedFontOption.key); // Set the ID of the selected font option
        }
    };

    const handleShapeNameChange = (e, { value }) => {
        setSelectedShapeName(value);
        const id = shapeList.find(shape => shape.name.substring(
            0,
            shape.name.lastIndexOf(".")) === value)?.id;
        setSelectedShape(id || null);
    };

    const handleAnimationChange = (e, { value }) => {
        setSelectedAnimationValue(value);
        const id = animationList.find(animation => animation.name.substring(
            0,
            animation.name.lastIndexOf(".")) === value)?.id;
        setSelectedAnimation(id || null);
    };

    const handleButtonShapeChange = (e, { value }) => {
        setWordCloudButtonShapeName(value);
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleWordCloudQuestionChange = (e, { value }) => {
        setWordCloudQuestion(value)
    }

    // const handleWordCloudCustomURLChange = (e, { value }) => {
    //     setWordCloudCustomURL(value)
    // }

    const [options0, setOptions0] = useState([])
    const [options1, setOptions1] = useState([])
    const [options2, setOptions2] = useState([])

    const [fontOptions, setFontOptions] = useState([]);
    const buttonShapeOptions = [
        {
            key: 'Further Rounded Rectangle', text: 'Further Rounded Rectangle', value: 'Further Rounded Rectangle'
        },
        {
            key: 'Rounded Rectangle', text: 'Rounded Rectangle', value: 'Rounded Rectangle'
        },
        {
            key: 'Rectangle', text: 'Rectangle', value: 'Rectangle'
        }
    ]

    const [wordCloudButtonShapeName, setWordCloudButtonShapeName] = useState('')
    const [shapeOptions, setShapeOptions] = useState([])
    const [animationOptions, setAnimationOptions] = useState([])

    const fetchCustomBackgroundList = async () => {
        try {
            const response = await axios.get("/api/googleListFiles/custom-background");
            setCustomBackgroundList(response.data);

        } catch (error) {
            console.error(error);
        }
    };

    const fetchFontList = async () => {
        try {
            const response = await axios.get("/api/googleListFiles/font");
            const updatedFontOptions = response.data.map((item) => {
                const fileNameWithExt = item.name;
                const fileNameWithoutExt = fileNameWithExt.substring(
                    0,
                    fileNameWithExt.lastIndexOf(".")
                );
                const fileID = item.id
                return { key: fileID, text: fileNameWithoutExt, value: fileNameWithoutExt };
            }).filter((option) => !fontOptions.some((f) => f.key === option.key));
            setFontOptions(updatedFontOptions)
            setFontOptions([...fontOptions, ...updatedFontOptions])
        } catch (error) {
            console.error(error);
        }
    };

    const fetchLogoList = async () => {
        try {
            const response = await axios.get("/api/googleListFiles/logo");
            setLogoList(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const fetchShapeList = async () => {
        try {
            const response = await axios.get("/api/googleListFiles/shape");
            setShapeList(response.data);
            const updatedShapeOptions = response.data.map((item) => {
                const fileNameWithExt = item.name;
                const fileNameWithoutExt = fileNameWithExt.substring(
                    0,
                    fileNameWithExt.lastIndexOf(".")
                );
                return { key: item.id, text: fileNameWithoutExt, value: fileNameWithoutExt };
            }).filter((option) => !shapeOptions.some((s) => s.key === option.key));
            setShapeOptions([...shapeOptions, ...updatedShapeOptions]);

        } catch (error) {
            console.error(error);
        }
    };

    const fetchAnimationList = async () => {
        try {
            const response = await axios.get("/api/googleListFiles/animation");
            setAnimationList(response.data);
            const updatedAnimationOptions = response.data.map((item) => {
                const fileNameWithExt = item.name;
                const fileNameWithoutExt = fileNameWithExt.substring(
                    0,
                    fileNameWithExt.lastIndexOf(".")
                );
                return { key: item.id, text: fileNameWithoutExt, value: fileNameWithoutExt };
            }).filter((option) => !animationOptions.some((a) => a.key === option.key));
            setAnimationOptions([...animationOptions, ...updatedAnimationOptions]);

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                    //load custom background list
                    await fetchCustomBackgroundList()
                    // load font list for options
                    await fetchFontList()
                    // load logo list
                    await fetchLogoList()
                    //load shape list
                    await fetchShapeList()
                    //load word cloud text animation lsit
                    await fetchAnimationList()

                    //load main info from mongodb
                    await fetchEventDashboard();
                } else {
                    navigate("/");
                }
            } catch (error) {
                console.error(error);
                navigate("/");
            }
        };
        fetchData();
    }, []);

    const [selectedRecords, setSelectedRecords] = useState([]);
    const handleEntriesCheckbox = (label) => {
        if (selectedRecords.includes(label)) {
            setSelectedRecords((prevSelectedRecords) =>
                prevSelectedRecords.filter((record) => record !== label)
            );
        } else {
            setSelectedRecords((prevSelectedRecords) => [...prevSelectedRecords, label]);
        }
    };

    const handleDeleteSelected = () => {
        setPromptInput((prevPromptInput) => prevPromptInput.filter((word) => !selectedRecords.includes(word)));
        setSelectedRecords([]);
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRecords([]);
        } else {
            setSelectedRecords([...promptInput]);
        }
        setSelectAll(!selectAll);
    };

    const showPrompts = () => {
        let rows = [];
        let currentRow = [];

        for (let i = 0; i < promptInput.length; i++) {
            const word = promptInput[i]// Trim the prompt word
            // const wrappedWord = word + 'a'; // Add an invisible 'a' character after the word
            const wrapFunction = () => {
                let result = [];
                result.unshift(<span style={{ visibility: 'hidden' }}>a</span>)
                result.unshift(<span style={{ wordBreak: 'break-word' }}>{word}</span>)
                return result
            }

            currentRow.unshift(
                <Grid.Column key={i} style={{ textAlign: 'left', overflowWrap: 'anywhere' }}>
                    <Checkbox
                        label={wrapFunction()}
                        style={{ fontSize: wordFontSize(word) }}
                        checked={selectedRecords.includes(word)}
                        onChange={() => handleEntriesCheckbox(word)}
                    />
                </Grid.Column>
            );

            if (currentRow.length === 4 || i === promptInput.length - 1) {
                rows.push(
                    <>
                        <Grid.Row equal columns={4}>
                            {currentRow}
                        </Grid.Row>
                        {i !== promptInput.length - 1 && <Divider />}
                    </>
                );
                currentRow = [];
            }
        }

        return rows;
    }

    const fetchEventDashboard = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/wordCloudDashboard/event-dashboard", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });

            setStartDate(res.data.startDate);
            setEndDate(res.data.endDate);
            setTimezone(res.data.timezone)
            setEventLocation(res.data.eventLocation)
            setEndUsers(res.data.endUsers);
            setWordCloudQuestion(res.data.wordCloudQuestion);
            setWordCloudCustomBackgroundName(res.data.wordCloudCustomBackground);
            setWordCloudFontName(res.data.wordCloudFont);
            setWordCloudLogoName(res.data.wordCloudLogo);
            // setWordCloudCustomURL(res.data.wordCloudCustomURL);
            setFontColor(res.data.wordCloudFontColor)
            setButtonFillColor(res.data.wordCloudButtonFillColor)
            setButtonStrokeColor(res.data.wordCloudButtonStrokeColor)
            setButtonTextColor(res.data.wordCloudButtonTextColor)
            setWordCloudButtonShapeName(res.data.wordCloudButtonShapeName)
            setBackgroundColour(res.data.wordCloudBackgroundColour);
            setTextColour(res.data.wordCloudTextColour);
            setSelectedShapeName(res.data.wordCloudShape);
            setSelectedAnimationValue(res.data.wordCloudAnimation);
            setSelectedCustomBackground(res.data.selectedCustomBackgroundID);
            setSelectedLogo(res.data.selectedLogoID);
            setSelectedShape(res.data.selectedShapeID);
            setSelectedAnimation(res.data.selectedAnimationID);

            //load draggable elements array
            setDraggableElementArray(res.data.dataCaptureList);
            setFilteredWords(res.data.filteredWords);
            setTextBlockCount(res.data.textBlockCount);
            setEmailCount(res.data.emailCount);
            setPhoneNumberCount(res.data.phoneNumberCount);
            setNumberCount(res.data.numberCount);
            setCheckBoxCount(res.data.checkBoxCount);
            setDropdownCount(res.data.dropdownCount);
            setUrlCheckboxCount(res.data.urlCheckboxCount);

            setIsTextBlockRequired(res.data.isTextBlockRequired);
            setIsEmailRequired(res.data.isEmailRequired);
            setIsPhoneNumberRequired(res.data.isPhoneNumberRequired);
            setIsNumberRequired(res.data.isNumberRequired);
            setIsCheckBoxRequired(res.data.isCheckBoxRequired);
            setIsDropdownRequired(res.data.isDropdownRequired)
            setIsURLCheckboxRequired(res.data.isURLCheckboxRequired)
            setIsTextBlockRequired1(res.data.isTextBlockRequired1)
            setIsTextBlockRequired2(res.data.isTextBlockRequired2)
            setIsCheckBoxRequired1(res.data.isCheckBoxRequired1)
            setIsCheckBoxRequired2(res.data.isCheckBoxRequired2)

            setIsDropdownRequired1(res.data.isDropdownRequired1)
            setIsDropdownRequired2(res.data.isDropdownRequired2)
            setIsURLCheckboxRequired1(res.data.isURLCheckboxRequired1)
            setIsURLCheckboxRequired2(res.data.isURLCheckboxRequired2)

            setTextBlockTitle(res.data.textBlockTitle)
            setTextBlockTitle1(res.data.textBlockTitle1)
            setTextBlockTitle2(res.data.textBlockTitle2)
            setEmailTitle(res.data.emailTitle)
            setPhoneNumberTitle(res.data.phoneNumberTitle)
            setNumberTitle(res.data.numberTitle)
            setCheckBoxTitle(res.data.checkBoxTitle)
            setCheckBoxTitle1(res.data.checkBoxTitle1)
            setCheckBoxTitle2(res.data.checkBoxTitle2)
            setDropdownTitle(res.data.dropdownTitle)
            setDropdownTitle1(res.data.dropdownTitle1)
            setDropdownTitle2(res.data.dropdownTitle2)
            setUrlCheckboxTitle(res.data.urlCheckboxTitle)
            setUrlCheckboxTitle1(res.data.urlCheckboxTitle1)
            setUrlCheckboxTitle2(res.data.urlCheckboxTitle2)

            setOptions0(res.data.dropdownOptions0)
            setOptions1(res.data.dropdownOptions1)
            setOptions2(res.data.dropdownOptions2)

            setPromptInput(res.data.promptInput)
            setJobId(res.data.jobId)
            setCustomFontID(res.data.customFontID)

        } catch (error) {
            console.error(error);
        }
    }

    function jsonToCSV(jsonArray) {
        // Create a set to store unique keys
        const keysSet = new Set();

        // Fill the set with keys from all objects
        jsonArray.forEach(obj => Object.keys(obj).forEach(key => keysSet.add(key)));

        // Convert the set to an array to get the CSV header
        const header = Array.from(keysSet);

        // Create a map for faster access to key indexes
        const keyIndex = new Map(header.map((key, index) => [key, index]));

        // Convert the JSON array to a 2D array
        const csvArray = jsonArray.map(obj => {
            // Initialize an array filled with empty strings
            const row = Array(header.length).fill('');

            // Fill the array with values from the object
            Object.keys(obj).forEach(key => {
                const index = keyIndex.get(key);
                row[index] = JSON.stringify(obj[key]);
            });

            return row;
        });

        // Add the header to the beginning of the 2D array
        csvArray.unshift(header);

        // Convert the 2D array to a CSV string
        const csvString = csvArray.map(row => row.join(',')).join('\r\n');

        return csvString;
    }

    const downloadEndUsersData = async () => {
        // Fetch endUsers data from your backend
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get("https://wordwallrebuild.herokuapp.com/wordCloudDashboard/event-dashboard-endusers", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });

            const endUsersData = response.data;
            // Convert endUsers data to CSV format
            const csvContent = jsonToCSV(endUsersData);
            // Create a download link and trigger the download
            const downloadLink = document.createElement('a');
            const fileBlob = new Blob([csvContent], { type: 'text/csv' });
            const fileURL = URL.createObjectURL(fileBlob);

            downloadLink.href = fileURL;
            downloadLink.download = location.state.eventName + '_' + startDate + '_' + endDate + '.csv';
            downloadLink.click();

            // Clean up the URL object
            URL.revokeObjectURL(fileURL);
        }
        catch (error) {
            console.error(error);
        }
    }

    const updatingDashboard = async () => {
        try {
            const token = localStorage.getItem("token");

            const headers = {
                'Custom-Header': 'CustomHeaderValue',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            };

            const data = {
                eventName: location.state.eventName, // replace with the name you want to search for
                wordCloudQuestion: wordCloudQuestion,
                wordCloudFont: wordCloudFontName,
                wordCloudCustomBackground: wordCloudCustomBackgroundName,
                wordCloudLogo: wordCloudLogoName,
                // wordCloudCustomURL: wordCloudCustomURL,
                wordCloudFontColor: fontColor,
                wordCloudButtonFillColor: buttonFillColor,
                wordCloudButtonStrokeColor: buttonStrokeColor,
                wordCloudButtonTextColor: buttonTextColor,
                wordCloudButtonShapeName: wordCloudButtonShapeName,
                wordCloudBackgroundColour: backgroundColour,
                wordCloudTextColour: textColour,
                wordCloudShape: selectedShapeName,
                wordCloudAnimation: selectedAnimationValue,
                selectedCustomBackground: selectedCustomBackground,
                selectedLogo: selectedLogo,
                selectedShape: selectedShape,
                selectedAnimation: selectedAnimation,

                filteredWords: filteredWords,

                dataCaptureList: draggableItems,
                textBlockCount: textBlockCount,
                emailCount: emailCount,
                phoneNumberCount: phoneNumberCount,
                numberCount: numberCount,
                checkBoxCount: checkBoxCount,
                dropdownCount: dropdownCount,
                urlCheckboxCount: urlCheckboxCount,
                isTextBlockRequired: isTextBlockRequired,
                isEmailRequired: isEmailRequired,
                isPhoneNumberRequired: isPhoneNumberRequired,
                isNumberRequired: isNumberRequired,
                isCheckBoxRequired: isCheckBoxRequired,
                isDropdownRequired: isDropdownRequired,
                isURLCheckboxRequired: isCheckBoxRequired,

                isTextBlockRequired1: isTextBlockRequired1,
                isTextBlockRequired2: isTextBlockRequired2,

                isCheckBoxRequired1: isCheckBoxRequired1,
                isCheckBoxRequired2: isCheckBoxRequired2,

                isDropdownRequired1: isDropdownRequired1,
                isDropdownRequired2: isDropdownRequired2,

                isURLCheckboxRequired1: isURLCheckboxRequired1,
                isURLCheckboxRequired2: isURLCheckboxRequired2,

                textBlockTitle: textBlockTitle,
                textBlockTitle1: textBlockTitle1,
                textBlockTitle2: textBlockTitle2,
                emailTitle: emailTitle,
                phoneNumberTitle: phoneNumberTitle,
                numberTitle: numberTitle,
                checkBoxTitle: checkBoxTitle,
                checkBoxTitle1: checkBoxTitle1,
                checkBoxTitle2: checkBoxTitle2,
                dropdownTitle: dropdownTitle,
                dropdownTitle1: dropdownTitle1,
                dropdownTitle2: dropdownTitle2,
                urlCheckboxTitle: urlCheckboxTitle,
                urlCheckboxTitle1: urlCheckboxTitle1,
                urlCheckboxTitle2: urlCheckboxTitle2,

                dropdownOptions0: options0,
                dropdownOptions1: options1,
                dropdownOptions2: options2,

                promptInput: promptInput,
                customFontID: customFontID,

            };
            const response = await axios.put('https://wordwallrebuild.herokuapp.com/wordCloudDashboard/updateDashboard', data, { headers: headers });

            //show success message sweet alert
            // res.json({message:"success update"});
            if (response.status == 200) {
                Swal.fire('Success', 'Update successful', 'success');
            } else {
                Swal.fire('Error', 'Something went wrong while saving, please try again after re-login', 'error');
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleEventDateChange = () => {
        Swal.fire({
            title: 'Redirect to Event Update page',
            text: 'Please cancel and Click Save before redirecting to another Page, otherwise progress might be lost. If you have already saved progress, click Continue',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                // Redirect the user to the Login page
                navigate("/WordCloudUpdateEventPage", { state: { eventName: location.state.eventName } });
                // window.location.href = 'https://wordwallrebuild.herokuapp.com';
            }
        });
    }

    const handleFilteredWordsDeleteClick = (index) => {
        const updatedWords = [...filteredWords];
        updatedWords.splice(index, 1);
        setFilteredWords(updatedWords);
    }

    const handleSaveButton = () => {
        updatingDashboard();
    }

    const handlePreviewButton = () => {
        Swal.fire({
            title: 'Redirect to Live Link',
            text: 'Please cancel and Click Save before redirecting Event Page, otherwise progress might be lost. If you have already saved progress, click Continue',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                // Redirect the user to the Login page
                navigate("/WordCloud/LiveIntroPage" + jobId, { state: { eventName: location.state.eventName } });
                // window.location.href = 'https://wordwallrebuild.herokuapp.com';
            }
        });
    };

    const renderShapePreview = () => {
        switch (wordCloudButtonShapeName) {
            case 'Further Rounded Rectangle':
                return <div className="FurtherRoundedRectangle-preview" ></div>;
            case 'Rounded Rectangle':
                return <div className="RoundedRectangle-preview" ></div>;
            case 'Rectangle':
                return <div className="Rectangle-preview"></div>;
            default:
                return null;
        }
    };

    return (
        <div >
            <Grid style={{}}>
                <Grid.Column width={4} style={{ textAlign: "center", paddingRight: 0, paddingBottom: 0 }}>
                    <Grid.Row style={{ marginBottom: "10vh", marginTop: "5vh", fontSize: "30px" }}>
                        Polite Playground.
                    </Grid.Row>

                    <Button onClick={() => handleTabClick('Dashboard')} size="massive" fluid icon labelPosition="left"
                        className={activeTab === "Dashboard" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}

                    >
                        <Icon name="dashboard" />
                        Dashboard
                    </Button>
                    <Button onClick={() => handleTabClick('Data Capture')} size="massive" fluid icon labelPosition="left"
                        className={activeTab === "Data Capture" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}

                    >
                        <Icon name="database" />
                        Data Capture
                    </Button>
                    <Button onClick={() => handleTabClick('App Settings')} size="massive" fluid icon labelPosition="left"
                        className={activeTab === "App Settings" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}

                    >
                        <Icon name="tablet alternate" />
                        App Settings
                    </Button>
                    <Button onClick={() => handleTabClick('Projection Settings')} size="massive" fluid icon labelPosition="left"
                        className={activeTab === "Projection Settings" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}

                    >
                        <Icon name="tv" />
                        Projection Settings
                    </Button>
                </Grid.Column>
                <Grid.Column
                    width={12}
                    style={{
                        textAlign: "center",
                        paddingLeft: 0,
                        paddingBottom: 0,
                        backgroundColor: "#EAEAF1",
                    }}
                >
                    {activeTab === 'Dashboard' && (
                        <div >
                            <Grid.Row style={{ textAlign: "right", marginRight: "3vw", marginTop: "1vh" }}>
                                <Dropdown
                                    text='Menu'
                                    icon="bars"
                                    floating
                                    labeled
                                    inverted
                                    button
                                    className="icon"
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item text="Word Cloud Events" onClick={() => {
                                            navigate("/WordCloudEventListPage", { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Products" onClick={() => {
                                            navigate('/ProductsPage', { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Log Out" onClick={() => { navigate('/') }} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Grid.Row>
                            <Grid.Row style={{ marginLeft: "4vw", textAlign: "left", marginBottom: "2vh", fontSize: "28px", fontWeight: "bold" }} >
                                Dashboard
                            </Grid.Row>

                            <Grid style={{ overflow: 'auto' }}>
                                <Grid.Row  >
                                    <Grid.Column width={5} style={{ paddingLeft: "3vw" }}>
                                        <Segment className="custom-wordcloud-inside-segment" style={{ height: "30vh", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Grid.Row style={{ textAlign: "center", fontSize: "20px", marginTop: "2vh", fontWeight: "bold" }}>
                                                            Unique Entries
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column style={{ alignSelf: "center", textAlign: "center" }}>
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column width={6}>
                                                                </Grid.Column>
                                                                <Grid.Column width={4} style={{ textAlign: "center", backgroundColor: "#EAEAF1", fontSize: "28px" }}>
                                                                    <Grid.Row style={{ textAlign: "center", fontSize: "28px", margin: "7px" }}>
                                                                        {endUsers.length}
                                                                    </Grid.Row>
                                                                </Grid.Column>
                                                                <Grid.Column width={6}>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Grid.Row style={{ textAlign: "center" }}>
                                                            <Button size="large" onClick={downloadEndUsersData} className="custom-wordcloud-inside-segment-button" style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>Download</Button>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column width={11} style={{ paddingRight: "3vw" }}>
                                        <Segment className="custom-wordcloud-inside-segment" style={{ height: "30vh" }}>
                                            <Grid>
                                                <Grid.Row columns={3} divided={false} style={{ paddingTop: 0, marginTop: "1vh" }}>
                                                    <Grid.Column width={8}>
                                                        <Grid.Row style={{ textAlign: "left", paddingBottom: 0, fontSize: "20px", fontWeight: "bold", marginLeft: "3vw" }}>
                                                            Event Name:
                                                        </Grid.Row>
                                                        <Grid.Row style={{ marginLeft: "2vw" }}>
                                                            <Grid.Column>
                                                                <Segment className="custom-wordcloud-inside-segment" style={{ paddingTop: "7px", paddingBottom: "7px", textAlign: "left", background: "#F2F2F7", marginTop: "14px" }}>
                                                                    <Grid.Row>
                                                                        {location.state.eventName}
                                                                    </Grid.Row>
                                                                </Segment>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                    <Grid.Column width={4}>
                                                        <Grid.Row style={{ textAlign: "left", paddingBottom: 0, fontSize: "20px", fontWeight: "bold", marginLeft: "1vw" }}>
                                                            Location:
                                                        </Grid.Row>
                                                        <Grid.Row style={{}}>
                                                            <Grid.Column>
                                                                <Segment className="custom-wordcloud-inside-segment" style={{ paddingTop: "7px", paddingBottom: "7px", textAlign: "left", background: "#F2F2F7", marginTop: "14px" }}>
                                                                    <Grid.Row>
                                                                        {eventLocation}
                                                                    </Grid.Row>
                                                                </Segment>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                    <Grid.Column width={4}>
                                                        <Grid.Row style={{ textAlign: "left", paddingBottom: 0, fontSize: "20px", fontWeight: "bold", marginLeft: "1vw" }}>
                                                            Timezone:
                                                        </Grid.Row>
                                                        <Grid.Row style={{}}>
                                                            <Grid.Column>
                                                                <Segment className="custom-wordcloud-inside-segment" style={{ paddingTop: "7px", paddingBottom: "7px", textAlign: "left", background: "#F2F2F7", marginTop: "14px" }}>
                                                                    <Grid.Row>
                                                                        {timezone}
                                                                    </Grid.Row>
                                                                </Segment>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row columns={2} divided={false} style={{ paddingTop: 0, marginLeft: "2vw" }}>
                                                    <Grid.Column >
                                                        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0, textAlign: "left", marginLeft: "1vw", fontSize: "20px", fontWeight: "bold" }}>
                                                            Start Date:
                                                        </Grid.Row>
                                                        <Segment className="custom-wordcloud-inside-segment" style={{ paddingTop: "7px", paddingBottom: "7px", textAlign: "left", background: "#F2F2F7" }}>
                                                            <Grid.Row>
                                                                {startDate}
                                                            </Grid.Row>
                                                        </Segment>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0, textAlign: "left", marginLeft: "1vw", fontSize: "20px", fontWeight: "bold" }}>
                                                            End Date:
                                                        </Grid.Row>
                                                        <Segment className="custom-wordcloud-inside-segment" style={{ paddingTop: "7px", paddingBottom: "7px", textAlign: "left", background: "#F2F2F7" }}>
                                                            <Grid.Row>
                                                                {endDate}
                                                            </Grid.Row>
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row style={{ paddingTop: 0 }}>
                                                    <Grid.Column>
                                                        <Grid.Row style={{ textAlign: "center" }}>
                                                            <Button size="large" onClick={handleEventDateChange} className="custom-wordcloud-inside-segment-button" style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>Edit</Button>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row style={{ paddingTop: 0 }}>
                                    <Grid.Column style={{ paddingLeft: "3vw", paddingRight: "3vw" }}>

                                        <Segment className="custom-wordcloud-inside-segment">
                                            <Grid.Row style={{ textAlign: 'left', marginTop: '1vh', marginBottom: '1vh', fontSize: "24px", fontWeight: "bold" }}>
                                                Moderation
                                            </Grid.Row>
                                            <Grid>
                                                <Grid.Column width={5}>
                                                    <Grid.Row style={{ textAlign: 'left', marginTop: '1vh', marginBottom: '1vh', paddingLeft: "14px", fontSize: "16px", fontWeight: "bold" }}>
                                                        Filtered Words
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid textAlign="left">
                                                            <Grid.Column width={10}>
                                                                <Input
                                                                    size='mini'
                                                                    value={filteredWordsInputValue}
                                                                    placeholder='Type Here'
                                                                    onChange={(e) => setFilteredWordsInputValue(e.target.value)}
                                                                    style={{ background: "#F2F2F7 !important" }}
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column width={6}>
                                                                <Button size='tiny' onClick={handleFilteredWordsInputChange} className="custom-wordcloud-button-general" style={{ color: "#fff", background: "#695fca", borderRadius: "20px !important" }}>
                                                                    Submit
                                                                </Button>
                                                            </Grid.Column>
                                                        </Grid>
                                                    </Grid.Row>

                                                    <Segment className='custom-wordcloud-inside-segment-segment' style={{ background: "#F2F2F7" }}>
                                                        <Grid>
                                                            <Grid.Column style={{ scrollbarColor: '#695fca #dbdaee', overflow: 'auto', scrollbarWidth: 'thin', maxHeight: '30vh', direction: 'rtl' }}>
                                                                {filteredWords.map((word, index) => (
                                                                    <>
                                                                        <Grid.Row key={index}>
                                                                            <Grid>
                                                                                <Grid.Column style={{ direction: 'ltr' }}>
                                                                                    <Grid>
                                                                                        <Grid.Row>
                                                                                            <Grid.Column width={10} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                                {word}
                                                                                            </Grid.Column>
                                                                                            <Grid.Column width={6}>
                                                                                                <Button icon mini className="custom-wordcloud-button-general-no-border" style={{ color: "#fff", background: "#695fca", borderRadius: "0px !important" }} onClick={() => handleFilteredWordsDeleteClick(index)}>
                                                                                                    <Icon name='delete' size='small' />
                                                                                                </Button>
                                                                                            </Grid.Column>
                                                                                        </Grid.Row>
                                                                                    </Grid>
                                                                                </Grid.Column>
                                                                            </Grid>
                                                                        </Grid.Row>
                                                                        <Divider />
                                                                    </>
                                                                ))}
                                                            </Grid.Column>
                                                        </Grid>
                                                    </Segment>

                                                </Grid.Column>
                                                <Grid.Column width={11}>
                                                    <Grid.Row>
                                                        <Grid>
                                                            <Grid.Row columns={3} relaxed>
                                                                <Grid.Column width={10} textAlign="left" style={{ paddingLeft: "28px", display: 'flex', justifyContent: 'left', alignItems: 'center', fontSize: "16px", fontWeight: "bold" }} >
                                                                    Entries
                                                                </Grid.Column>
                                                                <Grid.Column width={3} textAlign="center" >
                                                                    <Button size="mini" className="custom-wordcloud-button-general" onClick={handleSelectAll} style={{ borderRadius: "30px !important" }}>
                                                                        Select All Entries
                                                                    </Button>
                                                                </Grid.Column>
                                                                <Grid.Column width={3}>
                                                                    <Button size="mini" textAlign="center" className="custom-wordcloud-button-general" onClick={handleDeleteSelected} style={{ borderRadius: "30px !important" }}>
                                                                        Delete Selected
                                                                    </Button>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Row>

                                                    <Segment className='custom-wordcloud-inside-segment-segment' style={{ background: "#F2F2F7" }}>
                                                        <Grid>
                                                            <Grid.Column style={{ scrollbarColor: '#695fca #dbdaee', overflow: 'auto', scrollbarWidth: 'thin', maxHeight: '33.5vh', direction: 'rtl' }}>
                                                                <Grid>
                                                                    {showPrompts()}
                                                                </Grid>
                                                            </Grid.Column>
                                                        </Grid>

                                                    </Segment>

                                                </Grid.Column>
                                            </Grid>
                                        </Segment>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    )}

                    {activeTab === 'Data Capture' && (
                        <div>
                            <Grid.Row style={{ textAlign: "right", marginRight: "3vw", marginTop: "1vh" }}>
                                <Dropdown
                                    text='Menu'
                                    icon="bars"
                                    floating
                                    labeled
                                    inverted
                                    button
                                    className="icon"
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item text="Word Cloud Events" onClick={() => {
                                            navigate("/WordCloudEventListPage", { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Products" onClick={() => {
                                            navigate('/ProductsPage', { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Log Out" onClick={() => { navigate('/') }} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Grid.Row>
                            <Grid.Row style={{ marginLeft: "4vw", textAlign: "left", marginBottom: "2vh", fontSize: "28px", fontWeight: "bold" }} >
                                Data Capture
                            </Grid.Row>

                            {/* <Divider className="custom-wordcloud-right-divider" /> */}

                            <Grid>

                                <Grid.Row columns={2} divided={false}>
                                    <Grid.Column width={6} style={{ paddingLeft: "3vw", marginTop: "14px" }} >
                                        <Segment className="custom-wordcloud-inside-segment" style={{ position: 'sticky', top: 0 }}>
                                            <Grid>
                                                <Grid.Row style={{ marginLeft: "28px", marginTop: "14px", fontSize: "20px", fontWeight: "bold" }} >
                                                    Add Cell
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='text width' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Text Block
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addTextBlock} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='mail' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Email
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addEmail} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='mobile alternate' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Phone Number
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addPhoneNumber} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='phone' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Number
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle">
                                                        <Button tiny icon onClick={addNumber} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='check square outline' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Check Box
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addCheckBox} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='dropdown' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Dropdown
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addDropdown} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", marginBottom: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='linkify' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        URL Checkbox
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addURLCheckbox} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column width={10} style={{ paddingRight: "2vw", overflow: 'auto' }}>
                                        <DragDropContext onDragEnd={handleOnDragEnd}>
                                            <Droppable droppableId="draggableItems">
                                                {(provided) => (
                                                    <ul className="draggableItems" {...provided.droppableProps} ref={provided.innerRef}>
                                                        {draggableItems.map((item, index) => {
                                                            return (
                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                    {(provided) => (
                                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                            {renderItem(item)}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </ul>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    )}

                    {activeTab === 'App Settings' && (
                        <div>
                            <Grid.Row style={{ textAlign: "right", marginRight: "3vw", marginTop: "1vh" }}>
                                <Dropdown
                                    text='Menu'
                                    icon="bars"
                                    floating
                                    labeled
                                    inverted
                                    button
                                    className="icon"
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item text="Word Cloud Events" onClick={() => {
                                            navigate("/WordCloudEventListPage", { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Products" onClick={() => {
                                            navigate('/ProductsPage', { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Log Out" onClick={() => { navigate('/') }} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Grid.Row>
                            <Grid.Row style={{ marginLeft: "4vw", textAlign: "left", marginBottom: "2vh", fontSize: "28px", fontWeight: "bold" }} >
                                App Settings
                            </Grid.Row>

                            <Grid verticalAlign="middle" centered>
                                <Grid.Row style={{ overflow: 'auto' }}>

                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Segment className="custom-wordcloud-inside-segment">
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                        Word Cloud Question
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Input
                                                            size='big'
                                                            fluid
                                                            value={wordCloudQuestion}
                                                            onChange={handleWordCloudQuestionChange}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Segment className="custom-wordcloud-inside-segment">
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                        Custom Background
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                    <Grid.Column width={3} >
                                                        <Button as="label" htmlFor="file-custom-background" type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                            <Icon name="file image outline" />
                                                            Choose File
                                                        </Button>
                                                        <input
                                                            type="file"
                                                            id="file-custom-background"
                                                            hidden
                                                            onChange={handleCustomBackgroundChange}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={13} style={{ textAlign: "left" }}>
                                                        {wordCloudCustomBackgroundName == '' ? "No File Chosen" : wordCloudCustomBackgroundName}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                {/* <Grid.Row>
                                                    <Grid.Column style={{ textAlign: "left" }}>
                                                        <Segment className="custom-wordcloud-inside-segment">
                                                            <Image.Group size='tiny'>
                                                                {customBackgroundList.slice(0, 7).map((image) => (
                                                                    <Image
                                                                        className={selectedCustomBackground === image.id ? "gallery-item-chosen-style" : "gallery-item-style"}
                                                                        key={image.id} src={image.thumbnailLink} alt={image.name}
                                                                        onClick={() => handleCustomBackgroundClicked(image)}
                                                                        style={{ cursor: "pointer" }} />
                                                                    // <Image className="gallery-item-style" key={image.id} src={image.thumbnailLink} alt={image.name} style={{ marginRight: '10px' }} />
                                                                ))}
                                                            </Image.Group>
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row> */}
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                                    Font
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row >
                                                                <Grid.Column width={10} >
                                                                    <Dropdown
                                                                        selection
                                                                        options={fontOptions}
                                                                        value={wordCloudFontName}
                                                                        onChange={handleFontDropdownChange}
                                                                        placeholder='Select Font' style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#f2f2f7'
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={6} >
                                                                    <Button as="label" htmlFor="file-font" type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                                        <Icon name="plus" />
                                                                        Add Font
                                                                    </Button>
                                                                    <input
                                                                        type="file"
                                                                        id="file-font"
                                                                        hidden
                                                                        onChange={handleFontChange}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                                    Button Shape
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row >
                                                                <Grid.Column width={10} >
                                                                    <Dropdown
                                                                        selection
                                                                        options={buttonShapeOptions}
                                                                        value={wordCloudButtonShapeName}
                                                                        onChange={handleButtonShapeChange}
                                                                        placeholder='Select shape' style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#f2f2f7'
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={6} >
                                                                    <Grid>
                                                                        <Grid.Row columns={2}>
                                                                            <Grid.Column>
                                                                                {renderShapePreview()}
                                                                            </Grid.Column>
                                                                            <Grid.Column>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>

                                            </Grid.Row>

                                        </Grid>

                                    </Grid.Column>
                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid.Row style={{ fontSize: "20px", fontWeight: "bold", marginBottom: '2vh' }}>
                                                            Font Color
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid style={{ textAlign: "left" }}>
                                                                <Grid.Row columns={3} divided={false}>
                                                                    <Grid.Column>
                                                                        <Input
                                                                            value={fontColor}
                                                                            onChange={handleFontColorChangeFromText}
                                                                            size='mini'
                                                                            fluid
                                                                            style={{ background: "#BABABA !important" }}
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <div className="myswatch" onClick={handleFontColorOnClick} style={{ background: fontColor }}>
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>

                                                            {displayFontColorPicker ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={handleFontColorClose} />
                                                                    <ChromePicker
                                                                        color={fontColor}
                                                                        onChange={handleFontColorChange}
                                                                        presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                        format='hex' />
                                                                </div> : null}

                                                        </Grid.Row>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid.Row style={{ fontSize: "20px", fontWeight: "bold", marginBottom: '2vh' }}>
                                                            Button Fill Color
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid style={{ textAlign: "left" }}>
                                                                <Grid.Row columns={3} divided={false}>
                                                                    <Grid.Column>
                                                                        <Input
                                                                            value={buttonFillColor}
                                                                            onChange={handleButtonFillColorChangeFromText}
                                                                            size='mini'
                                                                            fluid
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <div className="myswatch" onClick={handleButtonFillColorOnClick} style={{ background: buttonFillColor }}>
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>

                                                            {displayButtonFillColorPicker ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={handleButtonFillColorClose} />
                                                                    <ChromePicker
                                                                        color={buttonFillColor}
                                                                        onChange={handleButtonFillColorChange}
                                                                        presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                        format='hex' />
                                                                </div> : null}

                                                        </Grid.Row>
                                                    </Segment>
                                                </Grid.Column>

                                            </Grid.Row>

                                        </Grid>

                                    </Grid.Column>
                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid.Row style={{ fontSize: "20px", fontWeight: "bold", marginBottom: '2vh' }}>
                                                            Button Stroke Color
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid style={{ textAlign: "left" }}>
                                                                <Grid.Row columns={3} divided={false}>
                                                                    <Grid.Column>
                                                                        <Input
                                                                            value={buttonStrokeColor}
                                                                            onChange={handleButtonStrokeColorChangeFromText}
                                                                            size='mini'
                                                                            fluid
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <div className="myswatch" onClick={handleButtonStrokeColorOnClick} style={{ background: buttonStrokeColor }}>
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>

                                                            {displayButtonStrokeColorPicker ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={handleButtonStrokeColorClose} />
                                                                    <ChromePicker
                                                                        color={buttonStrokeColor}
                                                                        onChange={handleButtonStrokeColorChange}
                                                                        presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                        format='hex' />
                                                                </div> : null}

                                                        </Grid.Row>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid.Row style={{ fontSize: "20px", fontWeight: "bold", marginBottom: '2vh' }}>
                                                            Button Text Color
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid style={{ textAlign: "left" }}>
                                                                <Grid.Row columns={3} divided={false}>
                                                                    <Grid.Column>
                                                                        <Input
                                                                            value={buttonTextColor}
                                                                            onChange={handleButtonTextColorChangeFromText}
                                                                            size='mini'
                                                                            fluid
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <div className="myswatch" onClick={handleButtonTextColorOnClick} style={{ background: buttonTextColor }}>
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>

                                                            {displayButtonTextColorPicker ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={handleButtonTextColorClose} />
                                                                    <ChromePicker
                                                                        color={buttonTextColor}
                                                                        onChange={handleButtonTextColorChange}
                                                                        presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                        format='hex' />
                                                                </div> : null}
                                                        </Grid.Row>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Segment className="custom-wordcloud-inside-segment">
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                        Logo
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                    <Grid.Column width={3} >
                                                        <Button as="label" htmlFor="file-logo" type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                            <Icon name="file image outline" />
                                                            Choose File
                                                        </Button>
                                                        <input
                                                            type="file"
                                                            id="file-logo"
                                                            hidden
                                                            onChange={handleLogoChange}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={13} style={{ textAlign: "left" }}>
                                                        {wordCloudLogoName == '' ? "No File Chosen" : wordCloudLogoName}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                {/* <Grid.Row>
                                                    <Grid.Column style={{ textAlign: "left" }}>
                                                        <Segment className="custom-wordcloud-inside-segment">
                                                            <Image.Group size='tiny'>
                                                                {logoList.slice(0, 7).map((image) => (
                                                                    <Image
                                                                        className={selectedLogo === image.id ? "gallery-item-chosen-style" : "gallery-item-style"}
                                                                        key={image.id} src={image.thumbnailLink} alt={image.name}
                                                                        onClick={() => handleLogoClicked(image)}
                                                                        style={{ cursor: "pointer" }} />
                                                                ))}
                                                            </Image.Group>
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row> */}
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                    {/* <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Segment className="custom-wordcloud-inside-segment">
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                        Custom URL
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                    <Grid.Column width={8} style={{ textAlign: "left" }} >
                                                        www.politeplayground.com/wordcloud/
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Input
                                                            size='small'
                                                            fluid
                                                            value={wordCloudCustomURL}
                                                            onChange={handleWordCloudCustomURLChange}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column> */}
                                </Grid.Row>
                            </Grid>
                        </div>
                    )}

                    {activeTab === 'Projection Settings' && (
                        <div>
                            <Grid.Row style={{ textAlign: "right", marginRight: "3vw", marginTop: "1vh" }}>
                                <Dropdown
                                    text='Menu'
                                    icon="bars"
                                    floating
                                    labeled
                                    inverted
                                    button
                                    className="icon"
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item text="Word Cloud Events" onClick={() => {
                                            navigate("/WordCloudEventListPage", { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Products" onClick={() => {
                                            navigate('/ProductsPage', { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Log Out" onClick={() => { navigate('/') }} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Grid.Row>
                            <Grid.Row style={{ marginLeft: "4vw", textAlign: "left", marginBottom: "2vh", fontSize: "28px", fontWeight: "bold" }} >
                                Projection Settings
                            </Grid.Row>


                            <Grid style={{ textAlign: "left" }} >

                                <Grid.Row style={{ overflow: 'auto' }}>
                                    <Grid.Column>
                                        <Grid style={{ margin: 0 }}>
                                            <Grid.Row columns={2} divided={false}>
                                                <Grid.Column style={{ paddingLeft: "2vw" }}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid.Row style={{ fontSize: "20px", fontWeight: "bold", marginBottom: '2vh' }}>
                                                            Background Colour
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid style={{ textAlign: "left" }}>
                                                                <Grid.Row columns={3} divided={false}>
                                                                    <Grid.Column>
                                                                        <Input
                                                                            value={backgroundColour}
                                                                            onChange={handleBackgroundColourChangeFromText}
                                                                            size='mini'
                                                                            fluid
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <div className="myswatch" onClick={handlePickerOnClick} style={{ background: backgroundColour }}>
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>

                                                            {displayBackgroundColourPicker ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={handleBackgroundColourClose} />
                                                                    <ChromePicker
                                                                        color={backgroundColour}
                                                                        onChange={handleBackgroundColourChange}
                                                                        presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                        format='hex' />
                                                                </div> : null}

                                                        </Grid.Row>
                                                    </Segment>

                                                </Grid.Column>
                                                <Grid.Column style={{ paddingRight: "2vw" }}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid.Row style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "2vh" }}>
                                                            Text Colour
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid style={{ textAlign: "left" }}>
                                                                <Grid.Row columns={3} divided={false}>
                                                                    <Grid.Column>
                                                                        <Input
                                                                            value={textColour}
                                                                            onChange={handleTextColourChangeFromText}
                                                                            size='mini'
                                                                            fluid
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <div className="myswatch" onClick={handleTextColourPickerOnClick} style={{ background: textColour }}>
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                            {displayTextColourPicker ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={handleTextColourClose} />
                                                                    <ChromePicker
                                                                        color={textColour}
                                                                        onChange={handleTextColourChange}
                                                                        presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                        format='hex' />
                                                                </div> : null}
                                                        </Grid.Row>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>

                                        <Grid.Row style={{ marginBottom: '14px' }}>
                                            <Grid.Column style={{ paddingLeft: "2vw", paddingRight: "2vw", textAlign: "left" }}>
                                                <Segment className="custom-wordcloud-inside-segment">
                                                    <Grid.Row textAlign="" style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "2vh" }}>
                                                        Word Cloud Shape
                                                    </Grid.Row>
                                                    <Grid>
                                                        <Grid.Row columns={2}>
                                                            <Grid.Column width={6}>
                                                                <Dropdown
                                                                    selection
                                                                    options={shapeOptions}
                                                                    value={selectedShapeName}
                                                                    onChange={handleShapeNameChange}
                                                                    placeholder='Select Shape' style={{
                                                                        textAlign: 'center',
                                                                        backgroundColor: '#f2f2f7'
                                                                    }}
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column width={4}>
                                                                <Button as="label" htmlFor="file-shape" type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                                    <Icon name="plus" />
                                                                    Upload SVG
                                                                </Button>
                                                                <input
                                                                    type="file"
                                                                    id="file-shape"
                                                                    hidden
                                                                    onChange={handleShapeChange}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column style={{ textAlign: "left" }}>
                                                                <Segment className="custom-wordcloud-inside-segment">
                                                                    <Image.Group size='tiny'>
                                                                        {shapeList.slice(0, 7).map((image) => (
                                                                            <Image
                                                                                className={selectedShape === image.id ? "gallery-item-chosen-style" : "gallery-item-style"}
                                                                                key={image.id} src={image.thumbnailLink} alt={image.name}
                                                                                onClick={() => handleShapeClicked(image)}
                                                                                style={{ cursor: "pointer" }} />
                                                                        ))}
                                                                    </Image.Group>
                                                                </Segment>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row >
                                            <Grid.Column style={{ paddingLeft: "2vw", paddingRight: "2vw", textAlign: "left" }}>
                                                <Segment className="custom-wordcloud-inside-segment">
                                                    <Grid.Row style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "2vh" }}>
                                                        Word Cloud Animation
                                                    </Grid.Row>
                                                    <Grid.Row >
                                                        <Grid.Column width={10} >
                                                            <Dropdown
                                                                selection
                                                                options={animationOptions}
                                                                value={selectedAnimationValue}
                                                                onChange={handleAnimationChange}
                                                                placeholder='Select Animation' style={{
                                                                    textAlign: 'center',
                                                                    backgroundColor: '#f2f2f7'
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{ marginTop: '14px' }}>
                                                        <Grid.Column style={{ textAlign: "left" }}>
                                                            <Segment className="custom-wordcloud-inside-segment">
                                                                <Image.Group size='small'>
                                                                    {animationList.slice(0, 7).map((image) => (
                                                                        <Image
                                                                            className={selectedAnimation === image.id ? "gallery-item-chosen-style" : "gallery-item-style"}
                                                                            key={image.id} src={image.thumbnailLink} alt={image.name}
                                                                            onClick={() => handleAnimationClicked(image)}
                                                                            style={{ cursor: "pointer" }} />
                                                                    ))}
                                                                </Image.Group>
                                                            </Segment>
                                                        </Grid.Column>
                                                    </Grid.Row>

                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </div>
                    )}
                    <Grid centered columns={3} textAlign="center" style={{ padding: 0, margin: 0 }} >
                        <Grid.Column>

                        </Grid.Column>
                        <Grid.Column textAlign="bottom" style={{ paddingBottom: 0 }}>
                            <Button size="large" className="custom-wordcloud-dashboard-save-button" onClick={handleSaveButton} style={{ color: "#fff", background: "#695fca", marginBottom: "50vh", borderRadius: "20px !important" }}>
                                Save
                            </Button>
                        </Grid.Column>
                        <Grid.Column textAlign="bottom-right" style={{ paddingBottom: 0, paddingRight: "2vw" }}>
                            <Button size="large" className="custom-wordcloud-dashboard-save-button" onClick={handlePreviewButton} style={{ color: "#fff", background: "#695fca", borderRadius: "20px !important" }} >
                                {/* <Icon name='desktop' /> */}
                                Preview
                            </Button>
                        </Grid.Column>
                    </Grid>

                </Grid.Column>

            </Grid>
        </div>
    )
}

export default WordCloudDashboardPage;