import React from "react";
import { Container, Header, Menu, Dropdown, Button } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { useNavigate } from "react-router-dom";


const WorldWallTopBar = () => {
  const navigate = useNavigate();
  const navigateToWordWallEventListPage = () =>{
    navigate("/WordWallEventListPage", { state: { token: localStorage.getItem("token") , name: localStorage.getItem("name") } });
  }

  const navigateToProducts = () =>{
    navigate('/ProductsPage',{state: { token: localStorage.getItem("token") , name: localStorage.getItem("name") } });
  }

  return (
    <Menu fixed="top" inverted>
      <Container fluid>
        <Header
          as="h1"
          inverted
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Word Wall
        </Header>
        
        
        <Menu.Item position="right">
          <Dropdown
            text="Menu"
            pointing="top right"
            icon="caret down"
            floating
            labeled
            inverted
          >
            <Dropdown.Menu>
              <Dropdown.Item text="Word Wall Events" onClick={navigateToWordWallEventListPage}/>
              <Dropdown.Item text="Products" onClick={navigateToProducts}/>
              <Dropdown.Item text="Option 3" />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default WorldWallTopBar;
