import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Container, Grid, Button, Image, Segment, TextArea, Loader } from "semantic-ui-react";
import Swal from 'sweetalert2';
import "./LiveUserInputPage3.css";
import Filter from 'bad-words';
import backgroundImage from "../../img/wordcloudImages/quest_background.jpg";
import logoImage from "../../img/wordcloudImages/quest_logo.png";

function getQueryParamValue(paramName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
}

const LiveUserInputPage3 = () => {
    const filter = new Filter();

    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const [logoID, setLogoID] = useState("");
    const [newPromptInput, setNewPromptInput] = useState("");
    const [promptInput1, setPromptInput1] = useState([]);
    const [saveResult, setSaveResult] = useState(null);

    const [fontColor, setFontColor] = useState("");
    const [buttonFillColor, setButtonFillColor] = useState("");
    const [buttonStrokeColor, setButtonStrokeColor] = useState("");
    const [buttonTextColor, setButtonTextColor] = useState("");
    const [buttonShape, setButtonShape] = useState("");
    const eventName = getQueryParamValue("eventName");
    const [device, setDevice] = useState("desktop");  // default to desktop
    const logoWidth = device === "phone" ? "30vw" : device === "tablet" ? "25vw" : "16vw";
    const textAreaWidth = device === "phone" ? "65vw" : device === "tablet" ? "55vw" : "50vw";
    const headerFontSizeResponsive = device === "phone" ? "25px" : device === "tablet" ? "45px" : "55px";
    const questionSizeResponsive = device === "phone" ? "28px" : device === "tablet" ? "35px" : "42px";
    const textAreaHeight = device === "phone" ? "60px" : device === "tablet" ? "90px" : "100px";

    const containsSwearWords = (newPromptInput) => {
        return filter.isProfane(newPromptInput);
    };

    const handleNewLine = (e) => {
        setNewPromptInput(e.target.value.replace(/\n/g, ' '))
    }

    useEffect(() => {
        const setResponsiveness = () => {
            if (window.innerWidth < 768) {
                setDevice("phone");
            } else if (window.innerWidth < 992) {
                setDevice("tablet");
            } else {
                setDevice("desktop");
            }
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    useEffect(() => {
        // Fetch background image and logo from MongoDB
        fetchData();
    }, []);

    useEffect(() => {
        // Check if all required data has been fetched
        if (
            fontColor !== "" &&
            buttonFillColor !== "" &&
            buttonStrokeColor !== "" &&
            buttonTextColor !== "" &&
            buttonShape !== ""
        ) {
            setIsLoading(false);
        }
    }, [fontColor, buttonFillColor, buttonStrokeColor, buttonTextColor, buttonShape]);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                "https://wordwallrebuild.herokuapp.com/wordCloudDashboard/event-dashboard",
                {
                    params: {
                        eventName: eventName ? eventName : location.state.eventName // replace with the name you want to search for
                    },
                }
            );
            setLogoID(response.data.selectedLogoID);
            setPromptInput1(response.data.promptInput1);
            setFontColor(response.data.wordCloudFontColor);
            setButtonFillColor(response.data.wordCloudButtonFillColor);
            setButtonStrokeColor(response.data.wordCloudButtonStrokeColor);
            setButtonTextColor(response.data.wordCloudButtonTextColor);
            setButtonShape(response.data.wordCloudButtonShapeName);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (saveResult === 'success') {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'The prompt has been saved successfully.',
            }).then(() => {
                navigate('/WordCloud/LiveThankyouPage3', { state: { eventName: eventName ? eventName : location.state.eventName } });
            });
        }
        else if (saveResult != null) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'It seems the prompt has not been saved successfully, please check if it is valid and try again',
            });
        }
    }, [saveResult]);

    const redirectToNextPage = async () => {
        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);

        // Update promptInfo on MongoDB
        if (!newPromptInput || newPromptInput.length === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Caution',
                text: 'Please input your prompt.',
            });
            setIsSubmitting(false);
        } else if (containsSwearWords(newPromptInput)) {
            Swal.fire({
                icon: 'warning',
                title: 'Caution',
                text: 'Please refrain from using inappropriate language.',
            });
            setIsSubmitting(false);
        } else if (newPromptInput.length > 80) {
            Swal.fire({
                icon: 'warning',
                title: 'Caution',
                text: 'Text input seems too long.',
            });
            setIsSubmitting(false);
        } else {
            try {
                const res = await axios.put("https://wordwallrebuild.herokuapp.com/wordCloudDashboard/updatePromptInput3", {
                    params: {
                        eventName: eventName ? eventName : location.state.eventName,
                        newPromptInput: newPromptInput
                    },
                });
                setSaveResult(res.data);
            } catch (error) {
                console.error("Error updating prompt input:", error);
                setSaveResult(null);
                setIsSubmitting(false);
            }
        }
    };

    const renderButtonShape = (shape) => {
        if (shape === "Further Rounded Rectangle") {
            return '30px';
        }
        else if (shape === "Rounded Rectangle") {
            return '10px';
        } else if (shape === "Rectangle") {
            return '0px';
        } else {
            // Default value if shape is unknown or not provided
            return "0";
        }
    };

    return (
        <>
            <style>
                {`
                @media only screen and (max-width:767px){
                    .ui.container{
                        margin-left:0!important;
                        margin-right:0!important;
                    }
                  }
                  html, body, #root {
                    height: 100%;
                    margin: 0;
                    padding: 0;
                }
                `}
            </style>
            <Container fluid>
                {isLoading ? (
                    <Loader active>Loading...</Loader>
                ) : (
                    <>
                        <Grid
                            centered verticalAlign="middle" style={{
                                height: '15vh',
                                backgroundImage: `url(${backgroundImage})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                margin: 0
                            }}>
                        </Grid>
                        <Grid centered verticalAlign="middle" style={{
                            height: "85vh", margin: 0,
                            background: '#BF6440'
                        }}>

                            <Grid.Row>
                                <Grid.Column textAlign="center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Segment basic style={{ display: 'contents', margin: 0 }}>
                                        <Image style={{ width: '40vw', marginBottom: '10vh' }} src={logoImage} alt="" />
                                    </Segment>

                                    <Segment basic style={{ display: 'contents' }}>
                                        <p style={{ fontWeight: '400', width: '65vw', fontSize: questionSizeResponsive, color: fontColor, margin: '0', fontFamily: 'quest_font' }}>
                                            What’s your                                         </p>
                                        <p style={{ fontWeight: '400', width: '65vw', fontSize: questionSizeResponsive, color: fontColor, margin: '0', fontFamily: 'quest_font' }}>
                                            dream vacation                                        </p>
                                        <p style={{ fontWeight: '400', width: '65vw', fontSize: questionSizeResponsive, color: fontColor, margin: '0', fontFamily: 'quest_font' }}>
                                            spot in Australia?                                        </p>

                                        <TextArea
                                            className="custom-textarea"
                                            style={{
                                                height: textAreaHeight, color: 'white', margin: '4vh', width: textAreaWidth, fontSize: questionSizeResponsive,
                                                fontFamily: 'quest_font', border: 'none',
                                                textAlign: 'center',
                                                background: 'radial-gradient(closest-side, rgba(255,255,255,0.35), transparent) !important',
                                                resize: 'none',
                                                outline: 'none',
                                                fontWeight: '400'
                                            }}
                                            placeholder="Answer Here"
                                            value={newPromptInput}
                                            onChange={(e) => handleNewLine(e)}
                                            rows={3}  // adjust this as necessary
                                        />

                                        <Button
                                            style={{
                                                fontFamily: 'quest_font',
                                                fontSize: '16px',
                                                margin: '2vh',
                                                background: buttonFillColor,
                                                border: `2px solid ${buttonStrokeColor}`,
                                                color: buttonTextColor,
                                                borderRadius: renderButtonShape(buttonShape)
                                            }}
                                            primary
                                            onClick={redirectToNextPage}
                                            disabled={isSubmitting}>
                                            Submit
                                            {isSubmitting && <Loader active inline size='small' />}
                                        </Button>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </>)}

            </Container>
        </>
    )
}

export default LiveUserInputPage3;
