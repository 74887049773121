import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "./WordCloudAddNewEventPage.css";
import { Container, Grid, Form, Button, Header, Segment, Divider, Input, Icon, Dropdown } from "semantic-ui-react";
import { DateTimeInput } from 'semantic-ui-calendar-react';
import Swal from 'sweetalert2';


const WordCloudAddNewEventPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [dataCaptureNeeded, setDataCaptureNeeded] = useState(false);

    const [eventName, setEventName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [saveResult, setSaveResult] = useState(null);

    const [eventLocation, setEvenetLocation] = useState('');
    const [timezone, setTimezone] = useState('');

    const timezoneOptions = [
        //fill up with timezones according to UTC from -12:00 to +13:00
        { key: 'UTC00:00', value: 'UTC00:00', text: 'UTC00:00' },
        { key: 'UTC+01:00', value: 'UTC+01:00', text: 'UTC+01:00' },
        { key: 'UTC+02:00', value: 'UTC+02:00', text: 'UTC+02:00' },
        { key: 'UTC+03:00', value: 'UTC+03:00', text: 'UTC+03:00' },
        { key: 'UTC+03:30', value: 'UTC+03:30', text: 'UTC+03:30' },
        { key: 'UTC+04:00', value: 'UTC+04:00', text: 'UTC+04:00' },
        { key: 'UTC+04:30', value: 'UTC+04:30', text: 'UTC+04:30' },
        { key: 'UTC+05:00', value: 'UTC+05:00', text: 'UTC+05:00' },
        { key: 'UTC+05:30', value: 'UTC+05:30', text: 'UTC+05:30' },
        { key: 'UTC+05:45', value: 'UTC+05:45', text: 'UTC+05:45' },
        { key: 'UTC+06:00', value: 'UTC+06:00', text: 'UTC+06:00' },
        { key: 'UTC+06:30', value: 'UTC+06:30', text: 'UTC+06:30' },
        { key: 'UTC+07:00', value: 'UTC+07:00', text: 'UTC+07:00' },
        { key: 'UTC+08:00', value: 'UTC+08:00', text: 'UTC+08:00' },
        { key: 'UTC+09:00', value: 'UTC+09:00', text: 'UTC+09:00' },
        { key: 'UTC+09:30', value: 'UTC+09:30', text: 'UTC+09:30' },
        { key: 'UTC+10:00', value: 'UTC+10:00', text: 'UTC+10:00' },
        { key: 'UTC+11:00', value: 'UTC+11:00', text: 'UTC+11:00' },
        { key: 'UTC+12:00', value: 'UTC+12:00', text: 'UTC+12:00' },
        { key: 'UTC+13:00', value: 'UTC+13:00', text: 'UTC+13:00' },
        { key: 'UTC-01:00', value: 'UTC-01:00', text: 'UTC-01:00' },
        { key: 'UTC-03:00', value: 'UTC-03:00', text: 'UTC-03:00' },
        { key: 'UTC-03:30', value: 'UTC-03:30', text: 'UTC-03:30' },
        { key: 'UTC-04:00', value: 'UTC-04:00', text: 'UTC-04:00' },
        { key: 'UTC-04:30', value: 'UTC-04:30', text: 'UTC-04:30' },
        { key: 'UTC-05:00', value: 'UTC-05:00', text: 'UTC-05:00' },
        { key: 'UTC-06:00', value: 'UTC-06:00', text: 'UTC-06:00' },
        { key: 'UTC-07:00', value: 'UTC-07:00', text: 'UTC-07:00' },
        { key: 'UTC-08:00', value: 'UTC-08:00', text: 'UTC-08:00' },
        { key: 'UTC-11:00', value: 'UTC-11:00', text: 'UTC-11:00' },
        { key: 'UTC-12:00', value: 'UTC-12:00', text: 'UTC-12:00' }
    ]

    const handleStartDateChange = (event, { value }) => { setStartDate(value) }

    const handleEndDateChange = (event, { value }) => { setEndDate(value) }

    const handleTimezoneChange = (event, { value }) => { setTimezone(value) }

    const changeEventLocation = (event, { value }) => { setEvenetLocation(value) }

    const changeEventName = (event, { value }) => { setEventName(value) }

    const handleDataCapture = () => { setDataCaptureNeeded(!dataCaptureNeeded) }

    const handleBack = () => { navigate("/WordCloudEventListPage") }

    useEffect(() => {
        if (saveResult === 'success') {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'The save operation completed successfully, Click Finish Button to continue',
            }).then(() => {
                // navigate('/WordCloudDashboard');
            });
        } else if (saveResult != null) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please try with another event name since no duplicate event name are allowed in database',
            });
        }
    }, [saveResult]);

    const processSave = async () => {
        try {
            const token = localStorage.getItem("token");
            const headers = {
                'Custom-Header': 'CustomHeaderValue',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            };

            const data = {
                eventName: eventName,
                startDate: startDate,
                endDate: endDate,
                timezone: timezone,
                eventLocation: eventLocation,
                dataCapture: dataCaptureNeeded
            };

            const response = await axios.post('https://wordwallrebuild.herokuapp.com/wordCloudEventList/addNewEvent', data, { headers: headers });
            if (response.status == 200) {
                setSaveResult('success');
            }
            else if (response.status == 400) {
                setSaveResult('error')
            }
        } catch (error) {
            setSaveResult('error');
            console.error(error);
        }
    };

    const handleSave = () => { processSave() }

    const handleFinish = async () => {
        if (saveResult === 'success') {
            navigate("/WordCloudDashboardPage", { state: { eventName: eventName } });
        } else {
            Swal.fire({
                title: 'Save Before Continue',
                icon: 'info',
                text: 'Please save current event first by clicking "Save" button before continue',
            })
        }
    };

    return (
        <div >
            <Grid style={{ margin: 0, height: "100vh" }}>
                <Grid.Column width={3}
                    style={{ backgroundColor: "#EAEAF1" }}>
                </Grid.Column>
                <Grid.Column width={10}
                    style={{ backgroundColor: "#EAEAF1" }}>
                    <Grid centered columns={3} verticalAlign="top" style={{ height: "20vh" }}>
                        <Grid.Column>
                        </Grid.Column>
                        <Grid.Column >
                            <h1 style={{ textAlign: "center", marginTop: "13vh", fontSize: "40px" }}>New Event Options</h1>
                        </Grid.Column>
                        <Grid.Column>
                        </Grid.Column>
                    </Grid>
                    <Grid centered columns={1} verticalAlign="middle" style={{ height:   "60vh", textAlign: "center" }}>
                        <Grid.Column>
                            <Segment style={{ height: "60vh" }}>
                                <Grid centered columns={3} verticalAlign="middle" style={{ height: "60vh" }}>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={8}>
                                                    <Grid.Row>
                                                        <label style={{ marginLeft: "14px", fontSize: "20px", fontWeight: "bold" }}>
                                                            Event Name:
                                                        </label>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Input value={eventName} onChange={changeEventName}  size='huge' fluid placeholder='Event Name' />
                                                    </Grid.Row>
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    <Grid.Row>
                                                        <label style={{ marginLeft: "14px", fontSize: "20px", fontWeight: "bold" }}>
                                                            Location:
                                                        </label>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Input value={eventLocation} onChange={changeEventLocation}size='huge' fluid placeholder='Location' />
                                                    </Grid.Row>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>

                                        <Grid>
                                            <Grid.Row columns={3}>

                                                <Grid.Column width={6}>
                                                    <Grid.Row>
                                                        <label style={{ marginLeft: "14px", fontSize: "20px", fontWeight: "bold" }}>
                                                            Start Date:
                                                        </label>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <DateTimeInput
                                                            clearable
                                                            clearIcon={<Icon name="remove" color="red" />}
                                                            fluid
                                                            name="startDate"
                                                            placeholder=""
                                                            value={startDate}
                                                            iconPosition="right"
                                                            onChange={handleStartDateChange}
                                                            dateFormat="YYYY-MM-DD"
                                                            startMode="day"
                                                            preserveViewMode={false}
                                                            closable
                                                        />
                                                    </Grid.Row>
                                                </Grid.Column>
                                                <Grid.Column width={6}>
                                                    <Grid.Row>
                                                        <label style={{ marginLeft: "14px", fontSize: "20px", fontWeight: "bold" }}>
                                                            End Date:
                                                        </label>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <DateTimeInput
                                                            clearable
                                                            clearIcon={<Icon name="remove" color="red" />}
                                                            fluid
                                                            name="endDate"
                                                            placeholder=""
                                                            value={endDate}
                                                            iconPosition="right"
                                                            onChange={handleEndDateChange}
                                                            dateFormat="YYYY-MM-DD"
                                                            startMode="day"
                                                            preserveViewMode={false}
                                                            closable
                                                        />
                                                    </Grid.Row>
                                                </Grid.Column>
                                                <Grid.Column width={4}>
                                                    <Grid.Row>
                                                        <label style={{ marginLeft: "14px", fontSize: "20px", fontWeight: "bold" }}>
                                                            Timezone:
                                                        </label>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Dropdown
                                                            placeholder=''
                                                            fluid
                                                            selection
                                                            value={timezone}
                                                            onChange={handleTimezoneChange}
                                                            options={timezoneOptions}
                                                            style={{
                                                                textAlign: 'center',
                                                                backgroundColor: '#f2f2f7'
                                                            }}
                                                        />
                                                    </Grid.Row>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <Divider className="custom-new-event-divider" />
                                        <Grid.Row>
                                            <Button size='massive' className={dataCaptureNeeded ? 'wordcloud-clicked-data-capture-button' : ''} onClick={handleDataCapture} style={{ margin: "auto" }}>
                                                Data Capture
                                            </Button>
                                        </Grid.Row>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                    <Grid centered columns={3} verticalAlign="middle" style={{ height: "10vh", textAlign: "center", marginTop: "5vh" }}>
                        <Grid.Column style={{ textAlign: "left" }}>
                            <Button className="custom-new-event-back-button-no-margin" size='large' onClick={handleBack}>Back</Button>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "center" }}>
                            <Button className="custom-new-event-save-button-no-margin" size='large' onClick={handleSave}>Save</Button>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "right" }}>
                            <Button className="custom-new-event-finish-button-no-margin" size='large' onClick={handleFinish}>Finish</Button>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={3}
                    style={{ backgroundColor: "#EAEAF1" }}>
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default WordCloudAddNewEventPage;

