import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import WordWallTopBar from "./components/WordWallTopBar";
import "./WordWallAddNewEventPage.css";
import { Container, Grid, Form, Button, Header, Segment, Divider, Input } from "semantic-ui-react";
import { DateInput } from 'semantic-ui-calendar-react';
import Swal from 'sweetalert2';


const WordWallAddNewEventPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [dataCaptureNeeded, setDataCaptureNeeded] = useState(false);

    const [eventName, setEventName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [saveResult, setSaveResult] = useState(null);


    const handleStartDateChange = (event, { value }) => {setStartDate(value)}
    const handleEndDateChange = (event, { value }) => {setEndDate(value)}
    const changeEventName = (event, { value }) => {setEventName(value)}
    const handleDataCapture = () => {setDataCaptureNeeded(!dataCaptureNeeded)}
    const handleBack = () => {navigate("/WordWallEventListPage")}

    useEffect(() => {
        if (saveResult === 'success') {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'The save operation completed successfully, Click Finish Button to continue',
            }).then(() => {
                // navigate('/WordCloudDashboard');
            });
        } else if(saveResult != null) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'The save operation failed, please do not add duplicate event or blank date and then retry, if the issue persists please report the issue',
            });
        }
    }, [saveResult]);

    const processSave = async () => {
        try {
            const token = localStorage.getItem("token");
            const headers = {
                'Custom-Header': 'CustomHeaderValue',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            };

            const data = {
                eventName: eventName,
                startDate: startDate,
                endDate: endDate,
                dataCapture: dataCaptureNeeded
            };

            const response = await axios.post('https://wordwallrebuild.herokuapp.com/wordWallEventList/addNewEvent', data, { headers: headers });
            if(response.status == 200){
                setSaveResult( 'success' );
            }
            else if(response.status == 400){
                setSaveResult('error')
            }
        } catch (error) {
            setSaveResult('error');
            console.error(error);
        }
    };

    const handleSave = () => {
        processSave();
    };

    const handleFinish = async () => {
        if (saveResult === 'success') {
            navigate("/WordWallDashboardPage", { state: { eventName: eventName } });
        } else {
            Swal.fire({
                title: 'Save Before Continue',
                icon: 'info',
                text: 'Please save current event first by clicking "Save" button before continue',
              })
        }
    };

    return (
        <div>
            <WordWallTopBar />
            <Grid>
                <Grid.Column width={3}>
                </Grid.Column>
                <Grid.Column width={10}>
                    <Grid centered columns={3} verticalAlign="top" style={{ height: "20vh" }}>
                        <Grid.Column>
                            <Button className="custom-new-event-back-button" size='large' onClick={handleBack}>Back</Button>
                        </Grid.Column>
                        <Grid.Column >
                            <h1 style={{ textAlign: "center", marginTop: "13vh" }}>Features</h1>
                        </Grid.Column>
                        <Grid.Column>
                        </Grid.Column>
                    </Grid>
                    <Grid centered columns={1} verticalAlign="middle" style={{ height: "60vh", textAlign: "center" }}>
                        <Grid.Column>
                            <Segment className="custom-new-event-segment" style={{ height: "60vh" }}>
                                <Grid centered columns={3} verticalAlign="middle" style={{ height: "60vh" }}>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        <Grid>
                                            <Grid.Row columns={3}>
                                                <Grid.Column width={8}>
                                                    <Grid.Row>
                                                        <label style={{ marginLeft: "14px" }}>
                                                            Event Name:
                                                        </label>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Input value={eventName} onChange={changeEventName} className="custom-new-event-input-field" size='huge' fluid placeholder='Event Name' />
                                                    </Grid.Row>
                                                </Grid.Column>
                                                <Grid.Column width={4}>
                                                    <Grid.Row>
                                                        <label style={{ marginLeft: "14px" }}>
                                                            Start Date:
                                                        </label>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <DateInput
                                                            fluid
                                                            className="custom-new-event-date-picker"
                                                            name="startDate"
                                                            placeholder=""
                                                            value={startDate}
                                                            iconPosition="right"
                                                            onChange={handleStartDateChange}
                                                            dateFormat="YYYY-MM-DD"
                                                            closable
                                                        />
                                                    </Grid.Row>
                                                </Grid.Column>
                                                <Grid.Column width={4}>
                                                    <Grid.Row>
                                                        <label style={{ marginLeft: "14px" }}>
                                                            End Date:
                                                        </label>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <DateInput
                                                            fluid
                                                            className="custom-new-event-date-picker"
                                                            name="endDate"
                                                            placeholder=""
                                                            value={endDate}
                                                            iconPosition="right"
                                                            onChange={handleEndDateChange}
                                                            dateFormat="YYYY-MM-DD"
                                                            closable
                                                        />
                                                    </Grid.Row>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>

                                        <Divider className="custom-new-event-divider" />
                                        <Grid.Row>
                                            <Button size='massive' className={dataCaptureNeeded ? 'wordwall-clicked-data-capture-button' : 'default-data-capture-button'} onClick={handleDataCapture} >
                                                Data Capture
                                            </Button>
                                        </Grid.Row>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                    <Grid centered columns={3} verticalAlign="bottom" style={{ height: "10vh", textAlign: "center" }}>
                        <Grid.Column>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "center" }}>
                            <Button className="custom-new-event-save-button" size='large' onClick={handleSave}>Save</Button>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "right" }}>
                            <Button className="custom-new-event-finish-button" size='large' onClick={handleFinish}>Finish</Button>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column width={3}>
                </Grid.Column>
            </Grid>

            <Footer />
        </div>
    )
}

export default WordWallAddNewEventPage;


