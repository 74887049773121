import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Hourglass } from 'react-loader-spinner';
import axios from "axios";
import { Container, Grid, Button, Image, Segment, Input, Dropdown, Modal } from "semantic-ui-react";
import Swal from 'sweetalert2';
import { array } from "joi";
import "./LiveMosaicLoginPage.css"

function getQueryParamValue(paramName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
}

const LiveMosaicLoginPage = ({ myEventName }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const eventName = myEventName ? myEventName : getQueryParamValue("eventName");
    // const [eventName,setEventName] = useState('');
    // const eventName = location.state.eventName ? location.state.eventName : new URLSearchParams(location.search).get('eventName');

    const [selectedImage, setSelectedImage] = useState(null);
    const [mosaicLogoData, setMosaicLogoData] = useState('')
    const [curEndUsers, setCurEndUsers] = useState([]);
    const [newUser, setNewUser] = useState({});
    const [curImageInput, setCurImageInput] = useState([]);
    const [dataCaptureList, setDataCaptureList] = useState([]);
    const [inputs, setInputs] = useState(dataCaptureList);
    const [mosaicButtonFillColor, setMosaicButtonFillColor] = useState("");
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const [isTextBlockRequired, setIsTextBlockRequired] = useState(false);
    const [isTextBlockRequired1, setIsTextBlockRequired1] = useState(false);
    const [isTextBlockRequired2, setIsTextBlockRequired2] = useState(false);
    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const [isPhoneNumberRequired, setIsPhoneNumberRequired] = useState(false);
    const [isNumberRequired, setIsNumberRequired] = useState(false);
    const [isCheckBoxRequired, setIsCheckBoxRequired] = useState(false);
    const [isCheckBoxRequired1, setIsCheckBoxRequired1] = useState(false);
    const [isCheckBoxRequired2, setIsCheckBoxRequired2] = useState(false);
    const [isDropdownRequired, setIsDropdownRequired] = useState(false);
    const [isDropdownRequired1, setIsDropdownRequired1] = useState(false);
    const [isDropdownRequired2, setIsDropdownRequired2] = useState(false);
    const [isURLCheckboxRequired, setIsURLCheckboxRequired] = useState(false);
    const [isURLCheckboxRequired1, setIsURLCheckboxRequired1] = useState(false);
    const [isURLCheckboxRequired2, setIsURLCheckboxRequired2] = useState(false);

    const [possiblePositionsArray, setPossiblePositionsArray] = useState([])

    const [postModerationButton,setPostModerationButton] = useState('')

    const fileInputRef = useRef(null);

    const [deviceType, setDeviceType] = useState('desktop');

    const [comment, setComment] = useState('');

    const updateDeviceType = () => {
        const width = window.innerWidth;
        if (width < 768) {
            setDeviceType('mobile');
        } else if (width >= 768 && width < 1024) {
            setDeviceType('tablet');
        } else {
            setDeviceType('desktop');
        }
    };

    useEffect(() => {
        updateDeviceType();
        window.addEventListener('resize', updateDeviceType);
        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', updateDeviceType);
        };
    }, []);

    useEffect(() => {
        // Fetch background image, logo, and options from MongoDB
        const fetchDataAndLoad = async () => {
            Swal.fire({
                title: "Loading Contents...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
            await fetchData();
            Swal.close();
        };
        fetchDataAndLoad();
    }, []);

    const fetchData = async () => {
        // console.log(eventName)
        try {
            const response = await axios.get(
                "https://wordwallrebuild.herokuapp.com/mosaicDashboard/event-dashboard",
                {
                    params: {
                        eventName: eventName != null ? eventName : location.state.eventName,
                    },
                }
            );

            setMosaicLogoData(response.data.mosaicLogoData)
            setDataCaptureList(response.data.dataCaptureList);
            setMosaicButtonFillColor(response.data.mosaicButtonFillColor);

            setDropdownOptions([response.data.dropdownOptions0, response.data.dropdownOptions1, response.data.dropdownOptions2]);
            setIsTextBlockRequired(response.data.isTextBlockRequired);
            setIsTextBlockRequired1(response.data.isTextBlockRequired1);
            setIsTextBlockRequired2(response.data.isTextBlockRequired2);
            setIsEmailRequired(response.data.isEmailRequired);
            setIsPhoneNumberRequired(response.data.isPhoneNumberRequired);
            setIsNumberRequired(response.data.isNumberRequired);
            setIsCheckBoxRequired(response.data.isCheckBoxRequired);
            setIsCheckBoxRequired1(response.data.isCheckBoxRequired1);
            setIsCheckBoxRequired2(response.data.isCheckBoxRequired2);
            setIsDropdownRequired(response.data.isDropdownRequired);
            setIsDropdownRequired1(response.data.isDropdownRequired1);
            setIsDropdownRequired2(response.data.isDropdownRequired2);
            setIsURLCheckboxRequired(response.data.isURLCheckboxRequired);
            setIsURLCheckboxRequired1(response.data.isURLCheckboxRequired1);
            setIsURLCheckboxRequired2(response.data.isURLCheckboxRequired2);

            setCurEndUsers(response.data.endUsers);
            setCurImageInput(response.data.imageInput)
            setPossiblePositionsArray(response.data.possiblePositionsArray)

            setPostModerationButton(response.data.postModerationButton)

            // console.log(response.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        // For each item in dataCaptureList, add the input is required or not to each item in dataCaptureList according to item's id,
        // regardless if the item has the field of isRequired or not
        const updatedDataCaptureList = dataCaptureList.map((item) => {
            if (item.id === "Text Block0") {
                return {
                    ...item,
                    isRequired: isTextBlockRequired,
                };
            } else if (item.id === "Text Block1") {
                return {
                    ...item,
                    isRequired: isTextBlockRequired1,
                };
            } else if (item.id === "Text Block2") {
                return {
                    ...item,
                    isRequired: isTextBlockRequired2,
                };
            } else if (item.id === "Email0") {
                return {
                    ...item,
                    isRequired: isEmailRequired,
                };
            } else if (item.id === "Phone Number0") {
                return {
                    ...item,
                    isRequired: isPhoneNumberRequired,
                };
            } else if (item.id === "Number0") {
                return {
                    ...item,
                    isRequired: isNumberRequired,
                };
            } else if (item.id === "Check Box0") {
                return {
                    ...item,
                    isRequired: isCheckBoxRequired,
                };
            } else if (item.id === "Check Box1") {
                return {
                    ...item,
                    isRequired: isCheckBoxRequired1,
                };
            } else if (item.id === "Check Box2") {
                return {
                    ...item,
                    isRequired: isCheckBoxRequired2,
                };
            } else if (item.id === "Dropdown0") {
                return {
                    ...item,
                    isRequired: isDropdownRequired,
                };
            } else if (item.id === "Dropdown1") {
                return {
                    ...item,
                    isRequired: isDropdownRequired1,
                };
            } else if (item.id === "Dropdown2") {
                return {
                    ...item,
                    isRequired: isDropdownRequired2,
                };
            } else if (item.id === "URL Checkbox0") {
                return {
                    ...item,
                    isRequired: isURLCheckboxRequired,
                };
            } else if (item.id === "URL Checkbox1") {
                return {
                    ...item,
                    isRequired: isURLCheckboxRequired1,
                };
            } else if (item.id === "URL Checkbox2") {
                return {
                    ...item,
                    isRequired: isURLCheckboxRequired2,
                };
            }
            return item; // return the item as is if no condition matches
        });

        setInputs(updatedDataCaptureList);
    }, [dataCaptureList]);

    const handleDataCaptureInputChange = (id, title, event) => {
        setInputs((inputs) =>
            inputs.map((input) =>
                input.id === id ? { ...input, value: event.target.value } : input
            )
        );

        setNewUser((prevNewUser) => ({
            ...prevNewUser,
            [title]: event.target.value,
        }));
    };

    const handleCommentInputChange = (event) => {
        setComment(event.target.value);

        setNewUser((prevNewUser) => ({
            ...prevNewUser,
            ['Comment']: event.target.value,
        }));
    }


    const showLoading = async () => {
        Swal.fire({
            title: 'Uploading...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {

            // Inject new user into endUsers array and also update imageInput list
            const updatedEndUsers = newUser;
            setCurEndUsers(updatedEndUsers);
            //get the lucky index first

            
            const randomIndex = Math.floor(Math.random() * possiblePositionsArray.length);
            // Remove the item from possible positions array and retrieve the position values
            const removedItem = possiblePositionsArray.splice(randomIndex, 1);
            const position = removedItem[0].imagePosition;
            const { x, y } = position;

            let uploadedImageData = selectedImage ? selectedImage.split(',')[1] : '';

            let updatedImageInput;
            if(postModerationButton == 'Automatic'){
                updatedImageInput =
                {
                    imageData: uploadedImageData,
                    imagePosition: { x, y },
                    printingDevice: '',
                    printingTime: '',
                    printed: '',
                    posted:'Approved',
                    postTime:new Date().toLocaleString(),
                    generatedImageData: '',
                    // compressedImageData: ''
                }
            }else if(postModerationButton == 'Manual'){
                updatedImageInput =
                {
                    imageData: uploadedImageData,
                    imagePosition: { x, y },
                    printingDevice: '',
                    printingTime: '',
                    printed: '',
                    posted:'Pending',
                    postTime:new Date().toLocaleString(),
                    generatedImageData: '',
                    // compressedImageData: ''
                }
            }
           
            let uploadImageId;
            try {
                const res = await axios.post(
                    "https://wordwallrebuild.herokuapp.com/mosaicDashboard/uploadImage",
                    {
                        params: {
                            eventName: eventName ? eventName : location.state.eventName,
                            imageInput: updatedImageInput,
                        },
                    }
                );
                uploadImageId = res.data.id; // Capture the uploaded image id
                const updatedNewUser = { ...newUser, uploadImageId };

                // Second API Call: Updating End Users
                try {
                    const res1 = await axios.put(
                        "https://wordwallrebuild.herokuapp.com/mosaicDashboard/updateEndUsers",
                        {
                            params: {
                                eventName: eventName ? eventName : location.state.eventName,
                                endUsers: updatedNewUser,
                            },
                        }
                    );
                    // console.log(updatedNewUser)
                    console.log('End users updated successfully', res1.data);
                } catch (error) {
                    console.error('Error updating end users:', error.response ? error.response.data : error.message);
                    throw new Error('Updating end users failed');
                }

                console.log('Image uploaded successfully', res.data);
            } catch (error) {
                console.error('Error uploading image:', error.response ? error.response.data : error.message);
                throw new Error('Image upload failed');
            }

            // Third API Call: Updating Possible Positions Array
            let response1;
            if (possiblePositionsArray.length !== 0) {
                try {
                    response1 = await axios.delete(
                        "https://wordwallrebuild.herokuapp.com/mosaicDashboard/deleteFromPossiblePositionsArray", 
                        {
                            params: {
                                eventName: eventName ? eventName : location.state.eventName,
                                x: x,
                                y: y
                            }
                        }
                    );
                    console.log('Possible positions array updated successfully', response1.data);
                } catch (error) {
                    console.error('Error updating possible positions array:', error.response ? error.response.data : error.message);
                    throw new Error('Updating possible positions array failed');
                }
            }
            

            // Success handling
            Swal.close();
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Data submitted successfully',
                showConfirmButton: false,
                timer: 1500,
            });

        } catch (error) {
            // Handle any errors that occur during the Axios request
            console.error(error);
            // Show a Swal with an error message for network or other errors
            Swal.fire({
                title: 'Error!',
                text: 'A critical error occurred while processing the request.',
                type: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }

    };


    const handleSubmit = async (event) => {

        const isAllRequiredFieldsFilled = !inputs.some((input) => {
            return input.isRequired && (input.value === undefined || input.value.trim() === "");
        });

        if (!isAllRequiredFieldsFilled) {

            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please enter required fields marked with "*" symbol',
            });
        } else {
            try {
                await showLoading()
            } catch (error) {
                console.error(error);
                console.log('Error uploading file');
            }
        }

        // Then navigate to the next page

        // navigate("/WordCloud/LiveUserInputPage1", {
        //     state: { eventName: location.state.eventName },
        // });
    }


    const handleDropdownChange = (title, value) => {
        setNewUser((prevNewUser) => ({
            ...prevNewUser,
            [title]: value,
        }));
    };

    const renderDynamicDropdown = (input) => {
        const dropdownID = input.id;
        const fieldTitle = input.title;
        const dropdownIndex = parseInt(dropdownID.replace("Dropdown", ""));
        const options = dropdownOptions[dropdownIndex] || [];

        return options.length > 0 ? (
            <Dropdown
                style={{ marginTop: "1vh", marginBottom: "1vh" }}
                id={dropdownID}
                selection
                options={options}
                onChange={(_, { value }) => handleDropdownChange(fieldTitle, value)}
                placeholder="Select an option"
            />
        ) : null;
    };

    const processImage = (image, fileType) => {
        const canvas = document.createElement('canvas');
        canvas.width = 1000;
        canvas.height = 1000;
        const ctx = canvas.getContext('2d');

        // Calculate scaling and centering
        let scaleFactor, offsetX, offsetY;
        if (image.width > image.height) {
            scaleFactor = 1000 / image.height;
            offsetX = (1000 - image.width * scaleFactor) / 2;
            offsetY = 0;
        } else {
            scaleFactor = 1000 / image.width;
            offsetX = 0;
            offsetY = (1000 - image.height * scaleFactor) / 2;
        }

        // Draw the centered and scaled image on the canvas
        ctx.drawImage(image, offsetX, offsetY, image.width * scaleFactor, image.height * scaleFactor);

        return canvas.toDataURL(fileType);
    };

    const handleUserPhotoInputChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                const originalImage = new window.Image();
                originalImage.onload = () => {
                    const processedImage = processImage(originalImage, file.type);
                    setSelectedImage(processedImage);
                };
                originalImage.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <Grid centered verticalAlign="middle" style={{ margin: 0 }}>
                <Grid.Row>
                    <Grid.Column textAlign="center" style={{ display: "flex", alignItems: "center" }}>
                        <Segment basic style={{ display: "contents" }}>
                            <Image className="responsiveLogo" src={`data:image/jpeg;base64,${mosaicLogoData}`} alt="" style={{ marginBottom: "4vh" }} />
                            {inputs.map((input) => (
                                <Grid key={input.id}>
                                    <Grid.Row style={{ padding: 0 }}>
                                        <Grid.Column style={{ textAlign: "center" }}>
                                            <label style={{ fontSize: "24px" }}>
                                                {input.isRequired && <span style={{}}>*</span>}

                                                {input.title}
                                            </label>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            {input.id.includes("Dropdown") ? (
                                                renderDynamicDropdown(input)
                                            ) : (
                                                <Input
                                                    className="responsiveInput"
                                                    style={{ marginTop: "1vh", marginBottom: "1vh", padding: '1em' }}
                                                    type="text"
                                                    name={input.title}
                                                    value={input.value}
                                                    placeholder={input.title}
                                                    onChange={(e) => handleDataCaptureInputChange(input.id, input.title, e)}
                                                />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            ))}

                            <Grid>
                                <Grid.Row style={{ padding: 0 }}>
                                    <Grid.Column style={{ textAlign: "center" }}>
                                        <label style={{ fontSize: "24px" }}>
                                            Comment
                                        </label>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <textarea
                                            className="responsiveInput"
                                            style={{
                                                marginTop: "1vh",
                                                marginBottom: "1vh",
                                                height: '20vh',
                                                textAlign: 'left',
                                                resize: 'none', // Optional, prevents resizing the textarea
                                                borderStyle: 'solid',
                                            }}
                                            name='Comment'
                                            value={comment}
                                            placeholder='Comment'
                                            onChange={(e) => handleCommentInputChange(e)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Segment className="responsivePhotoUploadSegment">
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <input
                                        type="file"
                                        id="user-image"
                                        hidden
                                        ref={fileInputRef}
                                        onChange={handleUserPhotoInputChange}
                                    />
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            border: '1px solid #ccc',
                                            background: selectedImage ? `url(${selectedImage}) center / cover no-repeat` : '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#ccc',
                                            fontSize: '16px'
                                        }}
                                    >
                                        {!selectedImage && (
                                            <>
                                                <Button
                                                    as="label"
                                                    htmlFor="user-image"
                                                    type="button"
                                                    large
                                                    icon
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        color: "#fff",
                                                        background: "#A3ADBE",
                                                    }}
                                                >
                                                    Choose Photo/Take Photo
                                                </Button>

                                                <p style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                                                    Please upload your photo by pressing the button above.
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Segment>


                            <Button
                                style={{
                                    margin: "2vh",
                                    background: mosaicButtonFillColor,
                                    border: `2px solid`,
                                }}
                                primary
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default LiveMosaicLoginPage;