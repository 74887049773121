import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import LoginPage from "./LoginPage";
import DashBoard from "./DashBoard";
import ProductsPage from "./ProductsPage";
import WordCloudEventListPage from "./WordCloudEventListPage";
import WordCloudAddNewEventPage from "./WordCloudAddNewEventPage";
import WordCloudDashboardPage from "./WordCloudDashboardPage";

import WordWallEventListPage from "./WordWallEventListPage";
import WordWallAddNewEventPage from "./WordWallAddNewEventPage";
import WordWallDashboardPage from "./WordWallDashboardPage";

import ClientProductsPage from "./ClientProductsPage";
import WordCloudClientEventList from "./WordCloudClientEventList";

import WordCloudUpdateEventPage from "./WordCloudUpdateEventPage";

import LiveIntroPage1 from "./wordcloudpages/1/LiveIntroPage1";
import LiveLoginPage1 from "./wordcloudpages/1/LiveLoginPage1";
import LiveUserInputPage1 from "./wordcloudpages/1/LiveUserInputPage1";
import LiveThankyouPage1 from "./wordcloudpages/1/LiveThankyouPage1";

import LiveIntroPage2 from "./wordcloudpages/2/LiveIntroPage2";
import LiveLoginPage2 from "./wordcloudpages/2/LiveLoginPage2";
import LiveUserInputPage2 from "./wordcloudpages/2/LiveUserInputPage2";
import LiveThankyouPage2 from "./wordcloudpages/2/LiveThankyouPage2";

import LiveIntroPage3 from "./wordcloudpages/3/LiveIntroPage3";
import LiveLoginPage3 from "./wordcloudpages/3/LiveLoginPage3";
import LiveUserInputPage3 from "./wordcloudpages/3/LiveUserInputPage3";
import LiveThankyouPage3 from "./wordcloudpages/3/LiveThankyouPage3";

// import LiveIntroPage4 from "./wordcloudpages/4/LiveIntroPage4";
// import LiveLoginPage4 from "./wordcloudpages/4/LiveLoginPage4";
// import LiveUserInputPage4 from "./wordcloudpages/4/LiveUserInputPage4";
// import LiveThankyouPage4 from "./wordcloudpages/4/LiveThankyouPage4";


import MosaicAddNewEventPage from "./MosaicAddNewEventPage";
import MosaicEventListPage from "./MosaicEventListPage";
import MosaicDashboardPage from "./MosaicDashboardPage";

import LiveDigitalMosaic from "./mosaicpages/LiveDigitalMosaic"
import LiveMosaicLoginPage from "./mosaicpages/LiveMosaicLoginPage";

import LivePhotoImportPortal from "./mosaicpages/LivePhotoImportPortal";

import LiveDigitalMosaicAnimation from "./mosaicpages/LiveDigitalMosaicAnimation";
import LiveMosaicLoginPageMobile from "./mosaicpages/LiveMosaicLoginPageMobile";

import MosaicSimplifiedEventListPage from "./MosaicSimplifiedEventListPage";
import MosaicSimplifiedDashboard from "./MosaicSimplifiedDashboard";

import MosaicUpdateEventPage from "./MosaicUpdateEventPage"
// import liveUserInputPage1 from "./wordcloudpages/1/liveUserInputPage"

// import liveIntroPage2 from "./wordcloudpages/2/liveIntroPage"
// import liveLoginPage2 from "./wordcloudpages/2/liveLoginPage"
// import liveUserInputPage2 from "./wordcloudpages/2/liveUserInputPage"

import axios from "axios";
import "./index.css";
import { CookiesProvider } from 'react-cookie';


const App = () => {
  useEffect(() => {
    if (window.location.protocol !== 'https:') {
      window.location.href = window.location.href.replace('http', 'https');
    }
  }, []);
  return (
    <CookiesProvider>
      <Router>
        <div className="App" >
          <Routes>
            <Route index element={<LoginPage />} />
            <Route path='/ProductsPage' element={<ProductsPage />} />
            <Route path='/WordCloudEventListPage' element={<WordCloudEventListPage />} />
            <Route path='/WordCloudAddNewEventPage' element={<WordCloudAddNewEventPage />} />
            <Route path='/WordCloudDashboardPage' element={<WordCloudDashboardPage />} />
            <Route path='/WordWallEventListPage' element={<WordWallEventListPage />} />
            <Route path='/WordWallAddNewEventPage' element={<WordWallAddNewEventPage />} />
            <Route path='/WordWallDashboardPage' element={<WordWallDashboardPage />} />

            <Route path='/WordCloudClientEventList' element={<WordCloudClientEventList />} />
            <Route path='/ClientProductsPage' element={<ClientProductsPage />} />
            <Route path='/WordCloudUpdateEventPage' element={<WordCloudUpdateEventPage />} />

            <Route path='/MosaicEventListPage' element={<MosaicEventListPage />} />
            <Route path='/MosaicAddNewEventPage' element={<MosaicAddNewEventPage />} />
            <Route path='/MosaicDashboardPage' element={<MosaicDashboardPage />} />

            <Route path='/MosaicUpdateEventPage' element={<MosaicUpdateEventPage />} />


            <Route path='/WordCloud/LiveIntroPage1' element={<LiveIntroPage1 />} />
            <Route path='/WordCloud/LiveLoginPage1' element={<LiveLoginPage1 />} />
            <Route path='/WordCloud/LiveUserInputPage1' element={<LiveUserInputPage1 />} />
            <Route path='/WordCloud/LiveThankyouPage1' element={<LiveThankyouPage1 />} />


            {/* <Route path='/WordCloud/LiveIntroPage4' element={<LiveIntroPage4 />} />
            <Route path='/WordCloud/LiveLoginPage4' element={<LiveLoginPage4 />} />
            <Route path='/WordCloud/LiveUserInputPage4' element={<LiveUserInputPage4 />} />
            <Route path='/WordCloud/LiveThankyouPage4' element={<LiveThankyouPage4 />} /> */}

            <Route path='/WordCloud/LiveIntroPage2' element={<LiveIntroPage2 />} />
            <Route path='/WordCloud/LiveLoginPage2' element={<LiveLoginPage2 />} />
            <Route path='/WordCloud/LiveUserInputPage2' element={<LiveUserInputPage2 />} />
            <Route path='/WordCloud/LiveThankyouPage2' element={<LiveThankyouPage2 />} />

            <Route path='/WordCloud/LiveIntroPage3' element={<LiveIntroPage3 />} />
            <Route path='/WordCloud/LiveLoginPage3' element={<LiveLoginPage3 />} />
            <Route path='/WordCloud/LiveUserInputPage3' element={<LiveUserInputPage3 />} />
            <Route path='/WordCloud/LiveThankyouPage3' element={<LiveThankyouPage3 />} />
      
            <Route path='/Mosaic/LiveDigitalMosaic' element={<LiveDigitalMosaic />} />
            <Route path='/Mosaic/LiveMosaicLoginPage' element={<LiveMosaicLoginPage />} />
            <Route path='/Mosaic/LiveDigitalMosaicAnimation' element={<LiveDigitalMosaicAnimation />} />
            <Route path='/Mosaic/LiveMosaicLoginPageMobile' element={<LiveMosaicLoginPageMobile />} />

            
            <Route path='/MosaicSimplifiedEventListPage' element={<MosaicSimplifiedEventListPage />} />
            <Route path='/MosaicSimplifiedDashboard' element={<MosaicSimplifiedDashboard />} />

            <Route path='/LivePhotoImportPortal' element={<LivePhotoImportPortal />} />


            <Route path='/dashboard' element={<DashBoard />} />

          </Routes>
        </div>
      </Router>
    </CookiesProvider>


  );
};

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";

document.head.appendChild(styleLink);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
