import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import "./MosaicSimplifiedEventListPage.css";
import { Container, Grid, Form, Button, Header, Dropdown, Segment, Search } from "semantic-ui-react";

const MosaicSimplifiedEventListPage = () => {
    const [liveEventList, setLiveEventList] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    const [liveExpanded, setLiveExpanded] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                    await getLiveEvents(token);
                } else {
                    navigate("/");
                }
            } catch (error) {
                console.error(error);
                navigate("/");
            }
        };

        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 1000 * 10); // poll every 10s

        // Return the interval clearing function from the useEffect itself
        return () => clearInterval(interval);
    }, []);

    const getLiveEvents = async () => {
        console.log("getting live events")
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicEventList/liveEventsSummary", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setLiveEventList(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleLiveEventClick = (event) => {
        navigate("/MosaicSimplifiedDashboard", { state: { eventName: event.eventName } });
    };

    const resultRenderer = ({ eventName, startDate, timezone, eventLocation }) => {
        return (
            <Button
                fluid
                className="custom-event-item-button-no-border"
                onClick={() =>
                    handleLiveEventClick({
                        eventName,
                        startDate,
                        timezone,
                        eventLocation
                    })
                }
            >
                <div style={{ fontSize: "20px", color: '#ffffff' }}>{eventName}</div>
                <div style={{ opacity: 0 }}>s</div>
                <div style={{ color: '#ffffff' }}>{startDate}</div>
                <div style={{ color: '#ffffff' }}>{timezone + " " + eventLocation}</div>
            </Button>
        );
    };


    return (
        <div style={{ backgroundColor: "#EAEAF1", minHeight: "100vh" }}>

            <Grid centered columns={3} verticalAlign="top" style={{ height: "10vh" }}>
                <Grid.Column>
                    <Grid.Row style={{ marginTop: "7vh", marginLeft: "12vw", fontSize: "30px" }}>
                        Polite Playground
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <h1 style={{ textAlign: "center", marginTop: "10vh", fontSize: "40px" }}>Mosaic Events</h1>
                </Grid.Column>
                <Grid.Column>
                    <Grid.Row style={{ textAlign: "right", marginRight: "7vw", marginTop: "5vh" }}>
                        <Dropdown
                            text='Menu'
                            icon="bars"
                            floating
                            labeled
                            inverted
                            button
                            className="icon"
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item text="Mosaic Events" onClick={() => {
                                    navigate("/MosaicSimplifiedEventListPage", { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                }} />
                                <Dropdown.Item text="Log Out" onClick={() => { navigate('/') }} />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Grid.Row>
                </Grid.Column>
            </Grid>

            <Grid verticalAlign='top' columns={3} centered style={{ marginTop: "15vh" }}  >
                <Grid.Row>
                    <Grid.Column width={2}>
                    </Grid.Column>
                    <Grid.Column width={12} >
                        <Segment style={{ borderRadius: "20px" }}>
                            <Grid style={{ marginTop: "1vh" }}>
                                <Grid.Row columns={3} divided={false}>
                                    <Grid.Column style={{ marginLeft: "14px", textAlign: "left", fontSize: "22px" }}>
                                        Live Events:
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>

                            <Grid style={{ marginTop: 0 }}>
                                <Grid.Row columns={3} divided={false}>
                                    {liveEventList.map((event, index) => (
                                        <Grid.Column key={index} style={{ marginTop: "2vh" }}>
                                            <Button
                                                fluid
                                                className="custom-event-item-button-no-border"
                                                onClick={() => handleLiveEventClick(event)}
                                            >
                                                <div style={{ fontSize: "20px" }}>{event.eventName}</div>
                                                <div style={{ opacity: 0 }}>s</div>
                                                <div>{event.startDate}</div>
                                                <div>{event.timezone + " " + event.eventLocation}</div>
                                            </Button>
                                        </Grid.Column>
                                    ))}
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={2}>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )

}

export default MosaicSimplifiedEventListPage
