import React from "react";
import { Container, Header, Menu, Dropdown, Button } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { useNavigate } from "react-router-dom";


const TopBar = () => {
  const navigate = useNavigate();

  const navigateToProducts = () =>{
    navigate('/ProductsPage',{state: { token: localStorage.getItem("token") , name: localStorage.getItem("name") } });
  }

  const navigateToLogin = () =>{
    navigate('/')
  }

  return (
    <Menu fixed="top" inverted>
      <Container fluid>
        <Header
          as="h1"
          inverted
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Polite Playground
        </Header>
        
        
        <Menu.Item position="right">
          <Dropdown
            text="Menu"
            pointing="top right"
            icon="caret down"
            floating
            labeled
            inverted
          >
            <Dropdown.Menu>
              <Dropdown.Item text="Log Out" onClick={navigateToLogin}/>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default TopBar;
