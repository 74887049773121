import React, { useState, useEffect, useRef } from "react";
import { Grid, Button, Modal, Image, Header, Segment, Input, Select, Dropdown } from "semantic-ui-react";
import "./LiveDigitalMosaic.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import logo from '.././img/samplelogo2.png';
import LiveMosaicLoginPage from "./LiveMosaicLoginPage"
import Swal from 'sweetalert2';



function getQueryParamValue(paramName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
}

const LiveDigitalMosaicAnimation = () => {
    const clickableTilesOptions = [
        {
            key: 'Disabled',
            value: 'Disabled',
            text: 'Disabled'
        },
        {
            key: 'Enabled',
            value: 'Enabled',
            text: 'Enabled'
        }
    ]
    const expandableTilesOptions = [
        {
            key: 'Disabled',
            value: 'Disabled',
            text: 'Disabled'
        },
        {
            key: 'Enabled',
            value: 'Enabled',
            text: 'Enabled'
        }
    ]
    const [animatedTiles, setAnimatedTiles] = useState('Enabled')
    const [ClickableTiles, setClickableTiles] = useState('Enabled')
    const [expandableTiles, setExpandableTiles] = useState('Enabled')

    //---ended here-----

    const hoveredTileRef = useRef(null);

    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    const [welcomeOpen, setWelcomeOpen] = useState(false)
    const [loginOpen, setLoginOpen] = useState(false)

    //   const [showNameAndComment, setShowNameAndComment] = useState(false)
    const [emptyImage, setEmptyImage] = useState('')
    const location = useLocation();
    const [open, setOpen] = useState(false)
    const [settingOpen, setSettingOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);

    const [mosaicSize, setMosaicSize] = useState('');
    const [mosaicOverlayImageOpacity, setMosaicOverlayImageOpacity] = useState('');
    const [mosaicBackgroundImageOpacity, setMosaicBackgroundImageOpacity] = useState('')

    const [mosaicButtonFillColor, setMosaicButtonFillColor] = useState('')
    const [loginTextColor, setLoginTextColor] = useState('')
    const [loginButtonColor, setLoginButtonColor] = useState('')

    const [mosaicOverlayImageData, setMosaicOverlayImageData] = useState('');
    const [mosaicBackgroundImageData, setMosaicBackgroundImageData] = useState('');

    const [uploaderButton, setUploaderButton] = useState('')

    const [imageInput, setImageInput] = useState([])
    const eventName = getQueryParamValue("eventName");

    const [isLoading, setIsLoading] = useState(true);
    const [startAnimation, setStartAnimation] = useState(false);

    const totalTiles = 16 * 9; // Total number of tiles in a 16x9 grid
    const animationDuration = 0.8; // Duration of the animation for each tile in seconds
    const animationDelay = 0.1; // Delay before starting the animation for each tile in seconds

    // Calculate total duration for one complete cycle across all tiles
    const totalAnimationTime = totalTiles * animationDelay + animationDuration;

    const [animationCycle, setAnimationCycle] = useState(0);


    const handleAnimatedTilesChange = (e, data) => {
        setAnimatedTiles(data.value);
        console.log(data.value);
    };


    const handleExpandableTilesChange = (e, data) => {
        setExpandableTiles(data.value)
        console.log(data.value)
    }

    const handleClickableTilesChange = (e, data) => {
        setClickableTiles(data.value);
        console.log(data.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 's' || event.key === 'S') {
            console.log('The "s" key was pressed');
            setSettingOpen(true)

        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);


    useEffect(() => {
        // Lock the scroll when the component mounts
        document.body.style.overflow = 'hidden';

        // Unlock the scroll when the component unmounts
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fetchDataAndSetLoading = async () => {
            try {
                Swal.fire({
                    title: 'Loading Contents...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                await fetchData();
                Swal.close();
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchDataAndSetLoading();
    }, []);

    useEffect(() => {
        if (!isLoading) {
            setWelcomeOpen(true);
            setStartAnimation(true);
        }
    }, [isLoading]);

    useEffect(() => {
        if (startAnimation) {
            // Calculate total duration for one complete cycle across all tiles
            const totalAnimationTime = totalTiles * animationDuration;

            // Timer to increment the animation cycle
            const timer = setTimeout(() => {
                setAnimationCycle(prevCycle => prevCycle + 1);
            }, totalAnimationTime * 1000);

            // Cleanup function to clear the timer
            return () => clearTimeout(timer);
        }
    }, [startAnimation, animationCycle]);


    const fetchData = async () => {
        console.log(eventName)
        try {
            const response = await axios.get(
                "https://wordwallrebuild.herokuapp.com/mosaicDashboard/event-dashboard",
                {
                    params: {
                        eventName: eventName ? eventName : location.state.eventName,
                    },
                }
            );

            setEmptyImage(response.data.emptyImage)

            setMosaicSize(response.data.mosaicSize)
            setMosaicBackgroundImageOpacity(response.data.mosaicBackgroundImageOpacity);
            setMosaicOverlayImageOpacity(response.data.mosaicOverlayImageOpacity);
            setMosaicOverlayImageData(response.data.mosaicOverlayImageData)
            setMosaicBackgroundImageData(response.data.mosaicBackgroundImageData)
            //   setShowNameAndComment(response.data.showNameAndComment)

            setMosaicButtonFillColor(response.data.mosaicButtonFillColor)
            setLoginTextColor(response.data.loginTextColor)
            setLoginButtonColor(response.data.loginButtonColor)

            setImageInput(response.data.imageInput)

            setUploaderButton(response.data.uploaderButton)

            console.log(response.data)

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // Generate an array of grid cells 32*24
    const gridCells = Array.from({ length: 32 * 24 }, (_, index) => index + 1);
    // 24*18
    const gridCellsMiddle = Array.from({ length: 24 * 18 }, (_, index) => index + 1);
    // 16*12
    const gridCellsSmall = Array.from({ length: 16 * 9 }, (_, index) => index + 1);

    return (
        <div
            style={{
                backgroundImage: `url(data:image/jpg;base64,${mosaicBackgroundImageData})`,
                backgroundSize: 'cover',
                width: '100%',
                height: '100vh',
                opacity: mosaicBackgroundImageOpacity / 100,
                margin: 0,
                padding: 0
            }}
        >
            <Grid
                centered
                // className="background-image"
                style={{ height: "100vh", position: "relative", padding: 0, marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0 }}
            >
                <Grid.Column
                    width={16}
                    className="image-layer"
                    textAlign="center"
                    verticalAlign="middle"
                    style={{ height: "100vh", padding: 0, margin: 0 }}
                >
                    <Modal
                        onClose={() => setWelcomeOpen(false)}
                        onOpen={() => setWelcomeOpen(true)}
                        open={welcomeOpen}
                        size="tiny"
                    >
                        <Modal.Header style={{ textAlign: 'center' }}>
                            <Image src={logo} style={{ display: 'inline-block' }} />
                        </Modal.Header>
                        <Modal.Content style={{ textAlign: 'center' }}>
                            <Modal.Description>
                                <Header style={{ color: loginTextColor }}>
                                    Welcome
                                </Header>
                                <p style={{ color: loginTextColor }} >
                                    lllll
                                </p>
                            </Modal.Description>
                            <Button onClick={() => { setWelcomeOpen(false); setStartAnimation(true); }} style={{ display: 'inline-block', color: loginTextColor, background: loginButtonColor }}>
                                Continue
                            </Button>

                        </Modal.Content>
                    </Modal>

                    <Modal
                        onClose={() => setLoginOpen(false)}
                        onOpen={() => setLoginOpen(true)}
                        open={loginOpen}
                        size='tiny'
                    >
                        <LiveMosaicLoginPage myEventName={eventName}
                        />
                    </Modal>

                    {uploaderButton == 'Enabled' ? <Button
                        onClick={() => setLoginOpen(true)}
                        size='big'
                        style={{
                            zIndex: 10,
                            position: 'absolute',
                            background: mosaicButtonFillColor,
                            color: loginTextColor,
                            top: '10vh',
                            right: '10vw',
                            animation: "floatAnimation 4s infinite",
                        }}
                    >
                        Upload Photo
                    </Button> : ''}

                    <Modal
                        onClose={() => setSettingOpen(false)}
                        onOpen={() => setSettingOpen(true)}
                        open={settingOpen}
                        size='tiny'
                    >
                        <Grid centered verticalAlign="middle" style={{ margin: 0 }}>

                            <Grid.Row>
                                <Grid.Column textAlign="center" style={{ display: "flex", alignItems: "center" }}>
                                    <Segment basic style={{ display: "contents" }}>
                                        <Grid.Row style={{ padding: 0 }}>
                                            <Grid.Column style={{ textAlign: "center" }}>
                                                <label style={{ fontSize: "24px" }}>
                                                    Clickable Tiles
                                                </label>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Select
                                                    placeholder="Select"
                                                    options={clickableTilesOptions}
                                                    onChange={handleClickableTilesChange}
                                                    value={ClickableTiles}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign="center" style={{ display: "flex", alignItems: "center" }}>
                                    <Segment basic style={{ display: "contents" }}>
                                        <Grid.Row style={{ padding: 0 }}>
                                            <Grid.Column style={{ textAlign: "center" }}>
                                                <label style={{ fontSize: "24px" }}>
                                                    Expandable Tiles
                                                </label>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Select
                                                    placeholder="Select"
                                                    options={expandableTilesOptions}
                                                    onChange={handleExpandableTilesChange}
                                                    value={expandableTiles}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Button onClick={() => { setSettingOpen(false) }}>
                                Save Settings
                            </Button>
                        </Grid>

                    </Modal>

                    {mosaicSize == '16x9 - 144 tiles' &&
                        <Grid columns={16} className="container-layer" style={{ padding: 0, margin: 0, position: "relative" }}>
                            {Array.from({ length: 9 }).map((_, rowIndex) => (
                                <Grid.Row key={rowIndex} style={{ padding: 0, margin: 0 }}>
                                    {gridCellsSmall.slice(rowIndex * 16, (rowIndex + 1) * 16).map((cell, columnIndex) => {
                                        const currentImage = imageInput.find(img => img.imagePosition.x === columnIndex && img.imagePosition.y === rowIndex);
                                        const imageData = currentImage ? currentImage.imageData : emptyImage;
                                        // const highResolutionImageData = currentImage? currentImage.imageData: emptyImage;
                                        const handleTileHover = currentImage ? (e, x, y) => {
                                            const tileWidth = window.innerWidth / 16; // Assuming 16 tiles horizontally
                                            const tileHeight = window.innerHeight / 9; // Assuming 9 tiles vertically
                                            const tileCenterX = (x * tileWidth) + (tileWidth / 2); // Center x-coordinate of the tile
                                            const tileCenterY = (y * tileHeight) + (tileHeight / 2); // Center y-coordinate of the tile

                                            const tile = e.currentTarget;
                                            const image = tile.querySelector('img');
                                            if (image) {
                                                image.style.position = 'inherit'
                                                image.style.zIndex = 5; // Set to a value higher than the overlay image's z-index
                                                hoveredTileRef.current = image;
                                            }

                                            const clipPathValue = `inset(${y * tileHeight}px ${window.innerWidth - (x + 1) * tileWidth}px ${window.innerHeight - (y + 1) * tileHeight}px ${x * tileWidth}px)`;

                                            const overlayImage = document.querySelector('.overlay-tile');
                                            overlayImage.style.clipPath = clipPathValue;
                                            overlayImage.style.transform = currentImage ? `scale(1.3)` : ''
                                            overlayImage.style.transformOrigin = `${tileCenterX}px ${tileCenterY}px`;  // Set the transform-origin to the center of the tile
                                            overlayImage.style.transition = `transform 0.3s ease-in-out`

                                            overlayImage.style.opacity = mosaicOverlayImageOpacity / 100
                                        } : null;

                                        const columnsPerRow = 16;
                                        // Calculate index based on row and column indices
                                        const index = rowIndex * columnsPerRow + columnIndex;

                                        // const animationDelay = startAnimation ? `${index * 0.1}s` : '0s'; // Apply delay only if startAnimation is true
                                        const animationDelay = (index * animationDuration) % totalAnimationTime;

                                        const tileStyle = {
                                            width: "calc(100vw / 16)",
                                            padding: 0,
                                            margin: 0,
                                            height: "calc(100vh / 9)",
                                            boxSizing: 'border-box',
                                            position: 'relative',
                                            animation: startAnimation
                                                ? `floatInAnimation 0.8s ease-out ${animationDelay + animationCycle * totalAnimationTime}s`
                                                : 'none',
                                        };


                                        const handleTileMouseOut = () => {
                                            const overlayImage = document.querySelector('.overlay-tile');
                                            overlayImage.style.transform = `scale(1)`
                                            overlayImage.style.opacity = 0
                                            overlayImage.style.transition = `transform 0.3s ease-in-out,z-index 0.3s`

                                            if (hoveredTileRef.current) {
                                                hoveredTileRef.current.style.zIndex = ''; // Reset the z-index
                                                hoveredTileRef.current = null;
                                            }
                                        };

                                        return (
                                            <Grid.Column
                                                // key={cell}
                                                key={cell}
                                                data-tile={`${columnIndex}-${rowIndex}`} // Unique identifier for each tile
                                                onMouseOver={handleTileHover ? (e) => handleTileHover(e, columnIndex, rowIndex) : null}
                                                onMouseOut={currentImage ? handleTileMouseOut : null}
                                                style={tileStyle}
                                            >
                                                <Modal
                                                    closeIcon
                                                    onClose={() => setOpen(false)}
                                                    onOpen={() => {

                                                    }}
                                                    open={open}
                                                    trigger={
                                                        <img
                                                            className={currentImage ? "zoom-image" : ''}
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                zIndex: 3,
                                                                opacity: 1,
                                                                padding: 0,
                                                                margin: 0
                                                            }}
                                                            src={`data:image/jpg;base64,${imageData}`}
                                                            alt="Zoomable Image"
                                                            {...(isTouchDevice ? {
                                                                onTouchEnd: (e) => {
                                                                    //tiles on touch devices should not be able to be clicked to open

                                                                    // setOpen(open => true);
                                                                    // setSelectedImage(imageData);
                                                                    // e.preventDefault();
                                                                    // e.stopPropagation();
                                                                }
                                                            } : {
                                                                onClick: (e) => {
                                                                    if (ClickableTiles == 'Enabled') {

                                                                        e.preventDefault();
                                                                        e.stopPropagation();

                                                                        setOpen(true)
                                                                        setSelectedImage(imageData);
                                                                    }
                                                                    else {

                                                                    }

                                                                }
                                                            })}

                                                        />
                                                    }
                                                >
                                                    <Modal.Content image>
                                                        {
                                                            expandableTiles == 'Enabled' ?
                                                                <Image size='large' src={`data:image/jpg;base64,${selectedImage}`} wrapped />
                                                                :
                                                                <Image size='massive' src={`data:image/jpg;base64,${selectedImage}`} wrapped />
                                                        }
                                                        {
                                                            expandableTiles == 'Enabled' ? <Modal.Description>
                                                                <p>
                                                                    Submitted by:
                                                                </p>
                                                                <p>
                                                                    AKA
                                                                </p>
                                                                <p>
                                                                    Kool
                                                                </p>
                                                            </Modal.Description>
                                                                :
                                                                ''
                                                        }
                                                    </Modal.Content>
                                                    <Modal.Actions>

                                                    </Modal.Actions>
                                                </Modal>

                                            </Grid.Column>
                                        )
                                    })}
                                </Grid.Row>
                            ))}
                        </Grid>
                    }

                    {/* the background image */}

                    {/* the overlay image */}
                    <img
                        className="overlay-tile"  // Add this class
                        style={{
                            pointerEvents: "none",   // This line makes it ignore pointer events
                            width: "100%",
                            height: "100%",
                            // objectFit: "cover",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 6,
                            // opacity: mosaicOverlayImageOpacity / 100
                        }}
                        src={`data:image/jpg;base64,${mosaicOverlayImageData}`}
                        // src={`https://drive.google.com/uc?export=view&id=${mosaicOverlayImageID}`}
                        // loading="lazy"
                        alt=""
                    />
                    <img
                        className="overlay-tile-background"  // Add this class
                        style={{
                            pointerEvents: "none",   // This line makes it ignore pointer events
                            width: "100%",
                            height: "100%",
                            // objectFit: "cover",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 4,
                            opacity: mosaicOverlayImageData / 100
                        }}
                        src={`data:image/jpg;base64,${mosaicOverlayImageData}`}
                        // src={`https://drive.google.com/uc?export=view&id=${mosaicOverlayImageID}`}
                        // loading="lazy"
                        alt=""
                    />
                </Grid.Column>

            </Grid>

        </div>
    );
};

export default LiveDigitalMosaicAnimation;
