import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import WordWallTopBar from "./components/WordWallTopBar";
import "./WordWallDashboardPage.css";
import { Container, Grid, Form, Button, Header, Segment, Divider, Input, Icon, Dropdown, Menu, Checkbox } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChromePicker } from 'react-color';


const WordWallDashboardPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [endUsers, setEndUsers] = useState([]);

    const [activeTab, setActiveTab] = useState('Dashboard');
    //Assume text block has maximum of 3, email 1, phone number 1, number 1, checkbox 3, dropdown 3, urlcheckbox 3

    const [isTextBlockRequired, setIsTextBlockRequired] = useState(false);
    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const [isPhoneNumberRequired, setIsPhoneNumberRequired] = useState(false);
    const [isNumberRequired, setIsNumberRequired] = useState(false);
    const [isCheckBoxRequired, setIsCheckBoxRequired] = useState(false);
    const [isDropdownRequired, setIsDropdownRequired] = useState(false);
    const [isURLCheckboxRequired, setIsURLCheckboxRequired] = useState(false);

    const [isTextBlockRequired1, setIsTextBlockRequired1] = useState(false);
    const [isTextBlockRequired2, setIsTextBlockRequired2] = useState(false);

    const [isCheckBoxRequired1, setIsCheckBoxRequired1] = useState(false);
    const [isCheckBoxRequired2, setIsCheckBoxRequired2] = useState(false);

    const [isDropdownRequired1, setIsDropdownRequired1] = useState(false);
    const [isDropdownRequired2, setIsDropdownRequired2] = useState(false);

    const [isURLCheckboxRequired1, setIsURLCheckboxRequired1] = useState(false);
    const [isURLCheckboxRequired2, setIsURLCheckboxRequired2] = useState(false);

    const [textBlockCount, setTextBlockCount] = useState(0);
    const [emailCount, setEmailCount] = useState(0);
    const [phoneNumberCount, setPhoneNumberCount] = useState(0);
    const [numberCount, setNumberCount] = useState(0);
    const [checkBoxCount, setCheckBoxCount] = useState(0);
    const [dropdownCount, setDropdownCount] = useState(0);
    const [urlCheckboxCount, setUrlCheckboxCount] = useState(0);


    const [selectedDropdownValue0, setSelectedDropdownValue0] = useState();
    const [selectedDropdownValue1, setSelectedDropdownValue1] = useState();
    const [selectedDropdownValue2, setSelectedDropdownValue2] = useState();
    // const [selectedFont, setSelectedFont] = useState('');
    const [selectedShapeValue, setSelectedShapeValue] = useState('');
    const [selectedAnimationValue, setSelectedAnimationValue] = useState('');
    const [selectedSizeValue, setSelectedSizeValue] = useState('');
    const [draggableElementsArray, setDraggableElementArray] = useState([
        // {
        //     id: 'Text Block0',
        //     title: 'Question Text',
        // },
    ])

    const [backgroundColour, setBackgroundColour] = useState('');
    const [textColour, setTextColour] = useState('');

    const [displayBackgroundColourPicker, setDisplayBackgroundColourPicker] = useState(false);
    const [displayTextColourPicker, setDisplayTextColourPicker] = useState(false);


    const handleBackgroundColourChange = (newColour) => {setBackgroundColour(newColour.hex)}
    const handleBackgroundColourClose = () => {setDisplayBackgroundColourPicker(false)}
    const handleBackgroundColourChangeFromText = (e, { value }) => {setBackgroundColour(value)}

    const handleTextColourChange = (newColour) => {setTextColour(newColour.hex)}

    const handleTextColourClose = () => {setDisplayTextColourPicker(false)}
    const handleTextColourChangeFromText = (e, { value }) => {setTextColour(value)}
    const handlePickerOnClick = () => {setDisplayBackgroundColourPicker(!displayBackgroundColourPicker)}
    const handleTextColourPickerOnClick = () => {setDisplayTextColourPicker(!displayTextColourPicker)}

    const [draggableItems, updateDraggableItems] = useState(draggableElementsArray);

    const [wordWallQuestion, setWordWallQuestion] = useState('');
    const [wordWallCustomBackground, setWordWallCustomBackground] = useState('No File Chosen');
    const [wordWallFont, setWordWallFont] = useState('');
    const [wordWallLogo, setWordWallLogo] = useState('No File Chosen');
    const [wordWallCustomURL, setWordWallCustomURL] = useState('');

    useEffect(() => {
        updateDraggableItems(draggableElementsArray);
    }, [draggableElementsArray]);

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(draggableItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updateDraggableItems(items);
    }

    function deleteItem(id) {
        const newElementsArray = draggableElementsArray.filter(item => item.id !== id);
        setDraggableElementArray(newElementsArray);

        if (id == 'Text Block0' || id == 'Text Block1' || id == 'Text Block2') {
            setTextBlockCount(textBlockCount - 1);
        } else if (id == 'Email') {
            setEmailCount(emailCount - 1);
        } else if (id == 'Phone Number') {
            setPhoneNumberCount(phoneNumberCount - 1);
        } else if (id == 'Number') {
            setNumberCount(numberCount - 1);
        } else if (id == 'Check Box0' || id == 'Check Box1' || id == 'Check Box2') {
            setCheckBoxCount(checkBoxCount - 1);
        } else if (id == 'Dropdown' || id == 'Dropdown1' || id == 'Dropdown2') {
            setDropdownCount(dropdownCount - 1);
        } else if (id == 'URL Checkbox' || id == 'URL Checkbox1' || id == 'URL Checkbox2') {
            setCheckBoxCount(checkBoxCount - 1);
        }
    }

    const addTextBlock = () => {
        if (textBlockCount < 3) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Text Block' + textBlockCount,
                title: 'Question Text'
            }]);
            setTextBlockCount(textBlockCount + 1);
        }
    }

    const addEmail = () => {
        if (emailCount < 1) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Email' + emailCount,
                title: 'Email'
            }]);
            setEmailCount(emailCount + 1);
        }
    }

    const addPhoneNumber = () => {
        if (phoneNumberCount < 1) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Phone Number' + phoneNumberCount,
                title: 'Phone Number'
            }]);
            setPhoneNumberCount(phoneNumberCount + 1);
        }
    }

    const addNumber = () => {
        if (numberCount < 1) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Number' + numberCount,
                title: 'Number'
            }]);
            setNumberCount(numberCount + 1);
        }
    }

    const addCheckBox = () => {
        if (checkBoxCount < 3) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Check Box' + checkBoxCount,
                title: 'Check Box'
            }]);
            setCheckBoxCount(checkBoxCount + 1);
        }
    }

    const addDropdown = () => {
        if (dropdownCount < 3) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Dropdown' + dropdownCount,
                title: 'Dropdown'
            }]);
            setDropdownCount(dropdownCount + 1);
        }
    }

    const addURLCheckbox = () => {
        if (urlCheckboxCount < 3) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'URL Checkbox' + urlCheckboxCount,
                title: 'URL Checkbox'
            }]);
            setUrlCheckboxCount(urlCheckboxCount + 1);
        }
    }

    const renderItem = (item) => {
        if (item.id === 'Text Block0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isTextBlockRequired} onClick={handleTextBlockRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id == 'Text Block1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isTextBlockRequired1} onClick={handleTextBlockRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id == 'Text Block2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isTextBlockRequired2} onClick={handleTextBlockRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Email0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isEmailRequired} onClick={handleEmailRequiredChange} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Phone Number0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isPhoneNumberRequired} onClick={handlePhoneNumberRequiredChange} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Number0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isNumberRequired} onClick={handleNumberRequiredChange} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Check Box0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isCheckBoxRequired} onClick={handleCheckBoxRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Check Box1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isCheckBoxRequired1} onClick={handleCheckBoxRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Check Box2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isCheckBoxRequired2} onClick={handleCheckBoxRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Dropdown0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isDropdownRequired} onClick={handleDropdownRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Dropdown
                                            selection
                                            options={options0}
                                            value={selectedDropdownValue0}
                                            onChange={handleDropdownChange0}
                                            placeholder='' style={{
                                                textAlign: 'center',
                                            }}
                                        />

                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Dropdown1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isDropdownRequired1} onClick={handleDropdownRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Dropdown
                                            selection
                                            options={options1}
                                            value={selectedDropdownValue1}
                                            onChange={handleDropdownChange1}
                                            placeholder='' style={{
                                                textAlign: 'center',
                                            }}
                                        />
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Dropdown2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isDropdownRequired2} onClick={handleDropdownRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Dropdown
                                            selection
                                            options={options2}
                                            value={selectedDropdownValue2}
                                            onChange={handleDropdownChange2}
                                            placeholder='' style={{
                                                textAlign: 'center',
                                            }}
                                        />
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'URL Checkbox0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)} >
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isURLCheckboxRequired} onClick={handleURLCheckboxRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'URL Checkbox1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isURLCheckboxRequired1} onClick={handleURLCheckboxRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'URL Checkbox2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isURLCheckboxRequired2} onClick={handleURLCheckboxRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            className="custom-question-text-field"
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else {

        }
    };

    const handleTextBlockRequiredChange0 = () => {
        setIsTextBlockRequired(!isTextBlockRequired);
    }
    const handleEmailRequiredChange = () => {
        setIsEmailRequired(!isEmailRequired);
    }
    const handlePhoneNumberRequiredChange = () => {
        setIsPhoneNumberRequired(!isPhoneNumberRequired);
    }
    const handleNumberRequiredChange = () => {
        setIsNumberRequired(!isNumberRequired);
    }
    const handleCheckBoxRequiredChange0 = () => {
        setIsCheckBoxRequired(!isCheckBoxRequired);
    }
    const handleDropdownRequiredChange0 = () => {
        setIsDropdownRequired(!isDropdownRequired);
    }
    const handleURLCheckboxRequiredChange0 = () => {
        setIsURLCheckboxRequired(!isURLCheckboxRequired);
    }

    const handleTextBlockRequiredChange1 = () => {
        setIsTextBlockRequired1(!isTextBlockRequired1);
    }

    const handleTextBlockRequiredChange2 = () => {
        setIsTextBlockRequired2(!isTextBlockRequired2);
    }

    const handleCheckBoxRequiredChange1 = () => {
        setIsCheckBoxRequired1(!isCheckBoxRequired1);
    }

    const handleCheckBoxRequiredChange2 = () => {
        setIsCheckBoxRequired2(!isCheckBoxRequired2);
    }

    const handleDropdownRequiredChange1 = () => {
        setIsDropdownRequired1(!isDropdownRequired1);
    }
    const handleDropdownRequiredChange2 = () => {
        setIsDropdownRequired2(!isDropdownRequired2);
    }
    const handleURLCheckboxRequiredChange1 = () => {
        setIsURLCheckboxRequired1(!isURLCheckboxRequired1);
    }
    const handleURLCheckboxRequiredChange2 = () => {
        setIsURLCheckboxRequired2(!isURLCheckboxRequired2);
    }

    const handleDropdownChange0 = (e, { value }) => {
        setSelectedDropdownValue0(value);
    }
    const handleDropdownChange1 = (e, { value }) => {
        setSelectedDropdownValue1(value);
    }
    const handleDropdownChange2 = (e, { value }) => {
        setSelectedDropdownValue2(value);
    }

    const handleFontDropdownChange = (e, { value }) => {
        setWordWallFont(value);
    };

    const handleShapeChange = (e, { value }) => {
        setSelectedShapeValue(value);
    };

    const handleAnimationChange = (e, { value }) => {
        setSelectedAnimationValue(value);
    };

    const handleSizeChange = (e, { value }) => {
        setSelectedSizeValue(value);
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


    const handleWordWallQuestionChange = (e, { value }) => {
        setWordWallQuestion(value)
    }

    const handleWordWallCustomURLChange = (e, { value }) => {
        setWordWallCustomURL(value)
    }

    const options0 = [
        { key: 'Answer', text: 'Answer', value: 'Answer' },
    ]

    const options1 = [
        { key: 'Answer', text: 'Answer', value: 'Answer' },
    ]
    const options2 = [
        { key: 'Answer', text: 'Answer', value: 'Answer' },
    ]
    const fontOptions = [
        { key: 'Arial', text: 'Arial', value: 'Arial' },
        { key: 'Serif', text: 'Serif', value: 'Serif'},
        { key: 'Sans-serif', text: 'Sans-serif', value: 'Sans-serif'},

    ]

    const shapeOptions = [
        { key: 'Triangle', text: 'Triangle', value: ' Triangle' },
        { key: 'Circle', text: 'Circle', value: ' Circle' },

    ]

    const animationOptions = [
        { key: 'Slide', text: 'Slide', value: 'Slide' },
        { key: 'Fading', text: 'Fading', value: 'Fading' },

    ]

    const sizeOptions = [
        { key: '16:9', text: '16:9', value: '16:9' }
    ]


    useEffect(() => {

        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });

                    await fetchEventDashboard();
                } else {
                    navigate("/");
                }
            } catch (error) {
                console.error(error);
                navigate("/");
            }
        };

        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 1000 * 30); // poll every 10s
        // Return the interval clearing function from the useEffect itself
        return () => clearInterval(interval);
    }, []);

    const fetchEventDashboard = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/wordWallDashboard/event-dashboard", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            console.log(res);
            setStartDate(res.data.startDate);
            setEndDate(res.data.endDate);
            setEndUsers(res.data.endUsers);
            setWordWallQuestion(res.data.wordWallQuestion);
            setWordWallCustomBackground(res.data.wordWallCustomBackground);
            setWordWallFont(res.data.wordWallFont);
            setWordWallLogo(res.data.wordWallLogo);
            setWordWallCustomURL(res.data.wordWallCustomURL);
            setBackgroundColour(res.data.wordWallBackgroundColour);
            setTextColour(res.data.wordWallTextColour);
            setSelectedShapeValue(res.data.wordWallShape);
            setSelectedAnimationValue(res.data.wordWallAnimation);
            setSelectedSizeValue(res.data.wordWallSize);
        } catch (error) {
            console.error(error);
        }
    }

    const jsonToCSV = (jsonData) => {
        const headers = ['name', 'email', 'phoneNumber'];
        const rows = jsonData.map((row) =>
            headers.map((fieldName) => JSON.stringify(row[fieldName], null, 2))
        );
        let csvContent = headers.join(',') + '\n' + rows.join('\n');
        return csvContent;
    }

    const downloadEndUsersData = async () => {
        // Fetch endUsers data from your backend
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get("https://wordwallrebuild.herokuapp.com/wordWallDashboard/event-dashboard-endusers", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });

            const endUsersData = response.data;
            // Convert endUsers data to CSV format
            const csvContent = jsonToCSV(endUsersData);
            // Create a download link and trigger the download
            const downloadLink = document.createElement('a');
            const fileBlob = new Blob([csvContent], { type: 'text/csv' });
            const fileURL = URL.createObjectURL(fileBlob);

            downloadLink.href = fileURL;
            downloadLink.download = location.state.eventName + '_' + startDate + '_' + endDate + '.csv';
            downloadLink.click();

            // Clean up the URL object
            URL.revokeObjectURL(fileURL);
        }
        catch (error) {
            console.error(error);
        }
    }

    const updatingDashboard = async () => {
        try {
            const token = localStorage.getItem("token");

            const headers = {
                'Custom-Header': 'CustomHeaderValue',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            };

            const data = {
                eventName: location.state.eventName, // replace with the name you want to search for
                wordWallQuestion: wordWallQuestion,
                wordWallFont: wordWallFont,
                wordWallCustomBackground: wordWallCustomBackground,
                wordWallLogo: wordWallLogo,
                wordWallCustomURL: wordWallCustomURL,
                wordWallBackgroundColour: backgroundColour,
                wordWallTextColour: textColour,
                wordWallShape: selectedShapeValue,
                wordWallAnimation: selectedAnimationValue,
                wordWallSize:selectedSizeValue
            };

            const response = await axios.put('https://wordwallrebuild.herokuapp.com/wordWallDashboard/updateDashboard', data, { headers: headers });

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        updatingDashboard();
    }, [wordWallQuestion, wordWallFont,wordWallCustomBackground, wordWallLogo, 
        wordWallCustomURL, backgroundColour , textColour, selectedShapeValue, selectedAnimationValue,selectedSizeValue]);


    return (
        <div>
            <WordWallTopBar />
            <Grid style={{ height: "80vh", marginTop: "10vh" }}>
                <Grid.Column width={3}>
                </Grid.Column>
                <Grid.Column width={2} style={{ textAlign: "center" }}>
                    <Segment className="custom-wordwall-dashboard-left-segment" style={{ height: "70vh", paddingLeft: "0px", paddingRight: "0px" }} >
                        <Grid.Row>
                            Nav Bar
                        </Grid.Row>
                        <Divider className="custom-wordwall-left-divider" />
                        <Button onClick={() => handleTabClick('Dashboard')} size="medium" fluid
                            className={activeTab === "Dashboard" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}
                        >
                            Dashboard
                        </Button>
                        <Button onClick={() => handleTabClick('Event Features')} size="medium" fluid
                            className={activeTab === "Event Features" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}
                        >
                            Event Features
                        </Button>
                        <Button onClick={() => handleTabClick('App Settings')} size="medium" fluid
                            className={activeTab === "App Settings" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}
                        >
                            App Settings
                        </Button>
                        <Button onClick={() => handleTabClick('Projection Settings')} size="medium" fluid
                            className={activeTab === "Projection Settings" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}
                        >
                            Projection Settings
                        </Button>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={8} style={{ textAlign: "center" }}>
                    <Segment className="custom-wordwall-dashboard-right-segment" style={{ height: "70vh", overflow: 'hidden', maxHeight: '70vh' }}>

                        {activeTab === 'Dashboard' && (
                            <div>
                                <Grid.Row>
                                    Event Dashboard
                                </Grid.Row>
                                <Divider className="custom-wordwall-right-divider" />
                                <Grid>
                                    <Grid.Row columns={3} divided={false}>
                                        <Grid.Column style={{ paddingLeft: "5vw" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid.Row>
                                                    Event Name:
                                                </Grid.Row>
                                                <Grid.Row>
                                                    {location.state.eventName}
                                                </Grid.Row>
                                            </Segment>

                                        </Grid.Column>
                                        <Grid.Column >
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid.Row>
                                                    Start Date:
                                                </Grid.Row>
                                                <Grid.Row>
                                                    {startDate}
                                                </Grid.Row>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingRight: "5vw" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid.Row>
                                                    End Date:
                                                </Grid.Row>
                                                <Grid.Row>
                                                    {endDate}
                                                </Grid.Row>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row columns={2} divided={false}>
                                        <Grid.Column style={{ paddingLeft: "5vw" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid.Row>
                                                    Unique Entries
                                                </Grid.Row>
                                                <Grid.Row>
                                                    {endUsers.length}
                                                </Grid.Row>
                                                <Grid.Row textAlign="center">
                                                    <Button size="large" onClick={downloadEndUsersData} className="custom-wordwall-inside-segment-button">Download</Button>
                                                </Grid.Row>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingRight: "5vw" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid.Row>
                                                    Edit Moderations
                                                    <Grid.Row>
                                                        <p style={{ opacity: "0" }}>
                                                            sss
                                                        </p>
                                                    </Grid.Row>
                                                    <Grid.Row textAlign="center">
                                                        <Button size="large" className="custom-wordwall-inside-segment-button">Edit Filters</Button>
                                                    </Grid.Row>
                                                </Grid.Row>
                                            </Segment>

                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        )}

                        {activeTab === 'Event Features' && (
                            <div>
                                <Grid.Row>
                                    Data Capture
                                </Grid.Row>
                                <Divider className="custom-wordwall-right-divider" />

                                <Grid>
                                    <Grid.Row style={{ paddingBottom: "0px" }}>
                                        <p style={{ marginLeft: "56px" }}>
                                            Add Cell
                                        </p>
                                    </Grid.Row>
                                    <Grid.Row columns={2} divided={false}>
                                        <Grid.Column width={6} style={{ paddingLeft: "3vw" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid>
                                                    <Grid.Row columns={3} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                                        <Grid.Column width={3} verticalAlign="middle">
                                                            <Icon name='text width' />
                                                        </Grid.Column>
                                                        <Grid.Column width={8} style={{ textAlign: "left" }} verticalAlign="middle">
                                                            Text Block
                                                        </Grid.Column>
                                                        <Grid.Column width={5} verticalAlign="middle">
                                                            <Button tiny icon onClick={addTextBlock}>
                                                                <Icon name='plus' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Divider className="custom-wordwall-nomargin-divider" />
                                                    <Grid.Row columns={3} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                                        <Grid.Column width={3} verticalAlign="middle">
                                                            <Icon name='mail' />
                                                        </Grid.Column>
                                                        <Grid.Column width={8} style={{ textAlign: "left" }} verticalAlign="middle">
                                                            Email
                                                        </Grid.Column>
                                                        <Grid.Column width={5} verticalAlign="middle">
                                                            <Button tiny icon onClick={addEmail}>
                                                                <Icon name='plus' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Divider className="custom-wordwall-nomargin-divider" />
                                                    <Grid.Row columns={3} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                                        <Grid.Column width={3} verticalAlign="middle">
                                                            <Icon name='mobile alternate' />
                                                        </Grid.Column>
                                                        <Grid.Column width={8} style={{ textAlign: "left" }} verticalAlign="middle">
                                                            Phone Number
                                                        </Grid.Column>
                                                        <Grid.Column width={5} verticalAlign="middle">
                                                            <Button tiny icon onClick={addPhoneNumber}>
                                                                <Icon name='plus' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Divider className="custom-wordwall-nomargin-divider" />
                                                    <Grid.Row columns={3} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                                        <Grid.Column width={3} verticalAlign="middle">
                                                            <Icon name='phone' />
                                                        </Grid.Column>
                                                        <Grid.Column width={8} style={{ textAlign: "left" }} verticalAlign="middle">
                                                            Number
                                                        </Grid.Column>
                                                        <Grid.Column width={5} verticalAlign="middle">
                                                            <Button tiny icon onClick={addNumber}>
                                                                <Icon name='plus' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Divider className="custom-wordwall-nomargin-divider" />
                                                    <Grid.Row columns={3} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                                        <Grid.Column width={3} verticalAlign="middle">
                                                            <Icon name='check square outline' />
                                                        </Grid.Column>
                                                        <Grid.Column width={8} style={{ textAlign: "left" }} verticalAlign="middle">
                                                            Check Box
                                                        </Grid.Column>
                                                        <Grid.Column width={5} verticalAlign="middle">
                                                            <Button tiny icon onClick={addCheckBox}>
                                                                <Icon name='plus' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Divider className="custom-wordwall-nomargin-divider" />
                                                    <Grid.Row columns={3} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                                        <Grid.Column width={3} verticalAlign="middle">
                                                            <Icon name='dropdown' />
                                                        </Grid.Column>
                                                        <Grid.Column width={8} style={{ textAlign: "left" }} verticalAlign="middle">
                                                            Dropdown
                                                        </Grid.Column>
                                                        <Grid.Column width={5} verticalAlign="middle">
                                                            <Button tiny icon onClick={addDropdown}>
                                                                <Icon name='plus' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Divider className="custom-wordwall-nomargin-divider" />
                                                    <Grid.Row columns={3} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                                                        <Grid.Column width={3} verticalAlign="middle">
                                                            <Icon name='linkify' />
                                                        </Grid.Column>
                                                        <Grid.Column width={8} style={{ textAlign: "left" }} verticalAlign="middle">
                                                            URL Checkbox
                                                        </Grid.Column>
                                                        <Grid.Column width={5} verticalAlign="middle">
                                                            <Button tiny icon onClick={addURLCheckbox}>
                                                                <Icon name='plus' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column width={10} style={{ paddingRight: "3vw", overflow: 'auto', scrollbarWidth: 'thin', maxHeight: '60vh' }}>
                                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                                <Droppable droppableId="draggableItems">
                                                    {(provided) => (
                                                        <ul className="draggableItems" {...provided.droppableProps} ref={provided.innerRef}>
                                                            {draggableItems.map((item, index) => {
                                                                return (
                                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                        {(provided) => (
                                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                {renderItem(item)}

                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                );
                                                            })}
                                                            {provided.placeholder}
                                                        </ul>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        )}

                        {activeTab === 'App Settings' && (
                            <div>
                                <Grid.Row>
                                    App Settings
                                </Grid.Row>
                                <Divider className="custom-wordwall-right-divider" />
                                <Grid verticalAlign="middle" centered>
                                    <Grid.Row style={{ overflow: 'auto', scrollbarWidth: 'thin', maxHeight: '60vh' }}>

                                        <Grid.Column width={14} style={{ marginBottom: "10px" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column style={{ textAlign: "left" }}>
                                                            Word Wall Question
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Input
                                                                className="custom-question-text-field"
                                                                size='big'
                                                                fluid
                                                                value={wordWallQuestion}
                                                                onChange={handleWordWallQuestionChange}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column width={14} style={{ marginBottom: "10px" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column style={{ textAlign: "left" }}>
                                                            Custom Background
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid.Column width={3} >
                                                            <Button tiny icon>
                                                                <Icon name="file image outline" />
                                                                Choose File
                                                            </Button>
                                                        </Grid.Column>
                                                        <Grid.Column width={13} style={{ textAlign: "left" }}>
                                                            {wordWallCustomBackground}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column width={14} style={{ marginBottom: "10px" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column style={{ textAlign: "left" }}>
                                                            Font
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row >
                                                        <Grid.Column width={6} >
                                                            <Dropdown
                                                                selection
                                                                options={fontOptions}
                                                                value={wordWallFont}
                                                                onChange={handleFontDropdownChange}
                                                                placeholder='Select Font' style={{
                                                                    textAlign: 'center',
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={3}>
                                                            <Button tiny icon>
                                                                <Icon name="plus" />
                                                                Add Font
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column width={14} style={{ marginBottom: "10px" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column style={{ textAlign: "left" }}>
                                                            Logo
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid.Column width={3} >
                                                            <Button tiny icon>
                                                                <Icon name="file image outline" />
                                                                Choose File
                                                            </Button>
                                                        </Grid.Column>
                                                        <Grid.Column width={13}>
                                                            {wordWallLogo}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column width={14} style={{ marginBottom: "10px" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column style={{ textAlign: "left" }}>
                                                            Custom URL
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid.Column width={8} style={{ textAlign: "left" }} >
                                                            www.politeplayground.com/wordcloud/
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Input
                                                                className="custom-question-text-field"
                                                                size='small'
                                                                fluid
                                                                value={wordWallCustomURL}
                                                                onChange={handleWordWallCustomURLChange}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>

                                </Grid>
                            </div>
                        )}

                        {activeTab === 'Projection Settings' && (
                            <div>
                                <Grid.Row>
                                    Projection Settings
                                </Grid.Row>
                                <Divider className="custom-wordwall-right-divider" />
                                <Grid style={{ textAlign: "left" }}>
                                    <Grid.Row columns={2} divided={false}>
                                        <Grid.Column style={{ paddingLeft: "5vw" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid.Row>
                                                    Background Colour
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid style={{ textAlign: "left" }}>
                                                        <Grid.Row columns={3} divided={false}>
                                                            <Grid.Column>
                                                                <Input
                                                                    value={backgroundColour}
                                                                    onChange={handleBackgroundColourChangeFromText}
                                                                    className="custom-question-text-field"
                                                                    size='mini'
                                                                    fluid
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <div className="myswatch" onClick={handlePickerOnClick} style={{ background: backgroundColour }}>
                                                                </div>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>

                                                    {displayBackgroundColourPicker ?
                                                        <div className="popover">
                                                            <div className="cover" onClick={handleBackgroundColourClose} />
                                                            <ChromePicker
                                                                color={backgroundColour}
                                                                onChange={handleBackgroundColourChange}
                                                                presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                format='hex' />
                                                        </div> : null}

                                                </Grid.Row>
                                            </Segment>

                                        </Grid.Column>
                                        <Grid.Column style={{ paddingRight: "5vw" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid.Row>
                                                    Text Colour
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid style={{ textAlign: "left" }}>
                                                        <Grid.Row columns={3} divided={false}>
                                                            <Grid.Column>
                                                                <Input
                                                                    value={textColour}
                                                                    onChange={handleTextColourChangeFromText}
                                                                    className="custom-question-text-field"
                                                                    size='mini'
                                                                    fluid
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <div className="myswatch" onClick={handleTextColourPickerOnClick} style={{ background: textColour }}>
                                                                </div>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                    {displayTextColourPicker ?
                                                        <div className="popover">
                                                            <div className="cover" onClick={handleTextColourClose} />
                                                            <ChromePicker
                                                                color={textColour}
                                                                onChange={handleTextColourChange}
                                                                presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                format='hex' />
                                                        </div> : null}

                                                </Grid.Row>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row >
                                        <Grid.Column style={{ paddingLeft: "5vw", paddingRight: "5vw", textAlign: "left" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid.Row textAlign="">
                                                    Word Wall Shape
                                                </Grid.Row>
                                                <Grid>
                                                    <Grid.Row columns={2}>
                                                        <Grid.Column width={6}>
                                                            <Dropdown
                                                                selection
                                                                options={shapeOptions}
                                                                value={selectedShapeValue}
                                                                onChange={handleShapeChange}
                                                                placeholder='Select Shape' style={{
                                                                    textAlign: 'center',
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                            <Button tiny icon>
                                                                <Icon name='plus' />
                                                                Upload SVG
                                                            </Button>

                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row >
                                        <Grid.Column style={{ paddingLeft: "5vw", paddingRight: "5vw", textAlign: "left" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid.Row>
                                                    Word Wall Animation
                                                </Grid.Row>
                                                <Grid.Row >
                                                    <Grid.Column width={10} >
                                                        <Dropdown
                                                            selection
                                                            options={animationOptions}
                                                            value={selectedAnimationValue}
                                                            onChange={handleAnimationChange}
                                                            placeholder='Select Animation' style={{
                                                                textAlign: 'center',
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>

                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row >
                                        <Grid.Column style={{ paddingLeft: "5vw", paddingRight: "5vw", textAlign: "left" }}>
                                            <Segment className="custom-wordwall-inside-segment">
                                                <Grid.Row>
                                                    Word Wall Size
                                                </Grid.Row>
                                                <Grid.Row >
                                                    <Grid.Column width={10} >
                                                        <Dropdown
                                                            selection
                                                            options={sizeOptions}
                                                            value={selectedSizeValue}
                                                            onChange={handleSizeChange}
                                                            placeholder='Select Size' style={{
                                                                textAlign: 'center',
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>

                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        )}
                    </Segment>
                </Grid.Column>
                <Grid.Column width={3}>
                </Grid.Column>
            </Grid>

            <Grid centered columns={1} textAlign="center" >
                <Button size="large" className="custom-wordwall-dashboard-save-button" >
                    Save
                </Button>
            </Grid>

            <Footer />
        </div>
    )
}

export default WordWallDashboardPage;