import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Container, Grid, Button, Image, Segment, Input, Dropdown, Header, Checkbox } from "semantic-ui-react";
import Swal from 'sweetalert2';
import "./LiveLoginPage1.css";
import backgroundImage from "../../img/wordcloudImages/quest_background.jpg";
import logoImage from "../../img/wordcloudImages/quest_logo.png";

function getQueryParamValue(paramName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
}

const LiveLoginPage1 = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [logoID, setLogoID] = useState("");
    const [curEndUsers, setCurEndUsers] = useState([]);
    const [newUser, setNewUser] = useState({});
    const [dataCaptureList, setDataCaptureList] = useState([]);
    const [inputs, setInputs] = useState(dataCaptureList);
    const [fontColor, setFontColor] = useState("");
    const [buttonFillColor, setButtonFillColor] = useState("");
    const [buttonStrokeColor, setButtonStrokeColor] = useState("");
    const [buttonTextColor, setButtonTextColor] = useState("");
    const [buttonShape, setButtonShape] = useState("");
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const [isTextBlockRequired, setIsTextBlockRequired] = useState(false);
    const [isTextBlockRequired1, setIsTextBlockRequired1] = useState(false);
    const [isTextBlockRequired2, setIsTextBlockRequired2] = useState(false);
    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const [isPhoneNumberRequired, setIsPhoneNumberRequired] = useState(false);
    const [isNumberRequired, setIsNumberRequired] = useState(false);
    const [isCheckBoxRequired, setIsCheckBoxRequired] = useState(false);
    const [isCheckBoxRequired1, setIsCheckBoxRequired1] = useState(false);
    const [isCheckBoxRequired2, setIsCheckBoxRequired2] = useState(false);
    const [isDropdownRequired, setIsDropdownRequired] = useState(false);
    const [isDropdownRequired1, setIsDropdownRequired1] = useState(false);
    const [isDropdownRequired2, setIsDropdownRequired2] = useState(false);
    const [isURLCheckboxRequired, setIsURLCheckboxRequired] = useState(false);
    const [isURLCheckboxRequired1, setIsURLCheckboxRequired1] = useState(false);
    const [isURLCheckboxRequired2, setIsURLCheckboxRequired2] = useState(false);

    const eventName = getQueryParamValue("eventName");
    const [device, setDevice] = useState("desktop");  // default to desktop
    const logoWidth = device === "phone" ? "30vw" : device === "tablet" ? "25vw" : "16vw";
    const inputWidth = device === "phone" ? "55vw" : device === "tablet" ? "50vw" : "40vw";
    const labelWidthResponsive = device === "phone" ? "49vw" : device === "tablet" ? "45vw" : "37vw";
    const fontSizeResponsive = device === "phone" ? "18px" : device === "tablet" ? "26px" : "28px";
    const headerFontSizeResponsive = device === "phone" ? "20px" : device === "tablet" ? "30px" : "35px";

    useEffect(() => {
        const setResponsiveness = () => {
            if (window.innerWidth < 768) {
                setDevice("phone");
            } else if (window.innerWidth < 992) {
                setDevice("tablet");
            } else {
                setDevice("desktop");
            }
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        // Check if all required data has been fetched
        if (
            fontColor !== "" &&
            buttonFillColor !== "" &&
            buttonStrokeColor !== "" &&
            buttonTextColor !== "" &&
            buttonShape !== ""
        ) {
            setIsLoading(false);
        }
    }, [fontColor, buttonFillColor, buttonStrokeColor, buttonTextColor, buttonShape]);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                "https://wordwallrebuild.herokuapp.com/wordCloudDashboard/event-dashboard",
                {
                    params: {
                        eventName: eventName ? eventName : location.state.eventName, // replace with the name you want to search for
                    },
                }
            );
            setLogoID(response.data.selectedLogoID);
            setFontColor(response.data.wordCloudFontColor);
            setButtonFillColor(response.data.wordCloudButtonFillColor);
            setButtonStrokeColor(response.data.wordCloudButtonStrokeColor);
            setButtonTextColor(response.data.wordCloudButtonTextColor);
            setButtonShape(response.data.wordCloudButtonShapeName);
            setDropdownOptions([response.data.dropdownOptions0, response.data.dropdownOptions1, response.data.dropdownOptions2]);
            setIsTextBlockRequired(response.data.isTextBlockRequired);
            setIsTextBlockRequired1(response.data.isTextBlockRequired1);
            setIsTextBlockRequired2(response.data.isTextBlockRequired2);
            setIsEmailRequired(response.data.isEmailRequired);
            setIsPhoneNumberRequired(response.data.isPhoneNumberRequired);
            setIsNumberRequired(response.data.isNumberRequired);
            setIsCheckBoxRequired(response.data.isCheckBoxRequired);
            setIsCheckBoxRequired1(response.data.isCheckBoxRequired1);
            setIsCheckBoxRequired2(response.data.isCheckBoxRequired2);
            setIsDropdownRequired(response.data.isDropdownRequired);
            setIsDropdownRequired1(response.data.isDropdownRequired1);
            setIsDropdownRequired2(response.data.isDropdownRequired2);
            setIsURLCheckboxRequired(response.data.isURLCheckboxRequired);
            setIsURLCheckboxRequired1(response.data.isURLCheckboxRequired1);
            setIsURLCheckboxRequired2(response.data.isURLCheckboxRequired2);

            setDataCaptureList(response.data.dataCaptureList);
            setCurEndUsers(response.data.endUsers);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        // For each item in dataCaptureList, add the input is required or not to each item in dataCaptureList according to item's id,
        // regardless if the item has the field of isRequired or not
        const updatedDataCaptureList = dataCaptureList.map((item) => {
            if (item.id === "Text Block0") {
                return {
                    ...item,
                    isRequired: isTextBlockRequired,
                };
            } else if (item.id === "Text Block1") {
                return {
                    ...item,
                    isRequired: isTextBlockRequired1,
                };
            } else if (item.id === "Text Block2") {
                return {
                    ...item,
                    isRequired: isTextBlockRequired2,
                };
            } else if (item.id === "Email0") {
                return {
                    ...item,
                    isRequired: isEmailRequired,
                };
            } else if (item.id === "Phone Number0") {
                return {
                    ...item,
                    isRequired: isPhoneNumberRequired,
                };
            } else if (item.id === "Number0") {
                return {
                    ...item,
                    isRequired: isNumberRequired,
                };
            } else if (item.id === "Check Box0") {
                return {
                    ...item,
                    isRequired: isCheckBoxRequired,
                };
            } else if (item.id === "Check Box1") {
                return {
                    ...item,
                    isRequired: isCheckBoxRequired1,
                };
            } else if (item.id === "Check Box2") {
                return {
                    ...item,
                    isRequired: isCheckBoxRequired2,
                };
            } else if (item.id === "Dropdown0") {
                return {
                    ...item,
                    isRequired: isDropdownRequired,
                };
            } else if (item.id === "Dropdown1") {
                return {
                    ...item,
                    isRequired: isDropdownRequired1,
                };
            } else if (item.id === "Dropdown2") {
                return {
                    ...item,
                    isRequired: isDropdownRequired2,
                };
            } else if (item.id === "URL Checkbox0") {
                return {
                    ...item,
                    isRequired: isURLCheckboxRequired,
                };
            } else if (item.id === "URL Checkbox1") {
                return {
                    ...item,
                    isRequired: isURLCheckboxRequired1,
                };
            } else if (item.id === "URL Checkbox2") {
                return {
                    ...item,
                    isRequired: isURLCheckboxRequired2,
                };
            }
            return item; // return the item as is if no condition matches
        });

        setInputs(updatedDataCaptureList);
    }, [dataCaptureList]);


    const handleDataCaptureInputChange = (id, title, event) => {
        setInputs((inputs) =>
            inputs.map((input) =>
                input.id === id ? { ...input, value: event.target.value } : input
            )
        );
        setNewUser((prevNewUser) => ({
            ...prevNewUser,
            [title]: event.target.value,
        }));
    };

    const handleSubmit = async () => {
        const isAllRequiredFieldsFilled = !inputs.some((input) => {
            return input.isRequired && (input.value === undefined || input.value.trim() === "");
        });

        if (!isAllRequiredFieldsFilled) {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please enter required fields marked with "*" symbol',
            });
        } else {
            // Inject new user into endUsers array
            // const updatedEndUsers = [...curEndUsers, newUser];
            // setCurEndUsers(updatedEndUsers);

            const res = await axios.put(
                "https://wordwallrebuild.herokuapp.com/wordCloudDashboard/updateEndUsers",
                {
                    params: {
                        eventName: eventName ? eventName : location.state.eventName,
                        newUser: newUser,
                    },
                }
            );
            console.log(res);
            // Show success message using SweetAlert2
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Data submitted successfully',
                showConfirmButton: false,
                timer: 1500,
            });
            // Then navigate to the next page
            navigate("/WordCloud/LiveUserInputPage1", {
                state: { eventName: eventName ? eventName : location.state.eventName },
            });
        }
    };

    const renderButtonShape = (shape) => {
        if (shape === "Further Rounded Rectangle") {
            return '30px';
        } else if (shape === "Rounded Rectangle") {
            return "10px";
        } else if (shape === "Rectangle") {
            return "0px";
        } else {
            return "0";
        }
    };

    const handleDropdownChange = (title, value) => {
        setNewUser((prevNewUser) => ({
            ...prevNewUser,
            [title]: value,
        }));
    };

    const renderDynamicDropdown = (input) => {
        const dropdownID = input.id;
        const fieldTitle = input.title;
        const dropdownIndex = parseInt(dropdownID.replace("Dropdown", ""));
        const options = dropdownOptions[dropdownIndex] || [];

        return options.length > 0 ? (
            <Dropdown
                style={{ width: inputWidth, padding: 0 }}
                id={dropdownID}
                selection
                options={options}
                onChange={(_, { value }) => handleDropdownChange(fieldTitle, value)}
                placeholder="Select an option"
            />
        ) : null;
    };

    return (
        <>
            <style>
                {`
                
                html, body, #root {
                    height: 100%;
                    margin: 0;
                    padding: 0;
                }
                .bg-container {
                    min-height: 100%;
                    display: flex;
                    flex-direction: column;
                    background-color: #06C2C1;
                    background-repeat: repeat-y;
                    background-size: cover;
                    justify-content: center;
                }
                `}
            </style>

            <div className="bg-container">
                <Container fluid>
                    {isLoading ? (
                        <div>

                        </div>
                    ) : (
                        <>
                            <Grid centered verticalAlign="middle" style={{ margin: 0 }}>
                                <Grid.Row>
                                    <Grid.Column textAlign="center" style={{ display: "flex", alignItems: "center" }}>
                                        <Header style={{ fontSize: headerFontSizeResponsive, color: fontColor, marginTop: '20vh', fontFamily: 'CEW_Font_Heavy' }}>
                                            Enter Your Details Below
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column textAlign="center" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Segment basic style={{ display: "contents" }}>
                                            {inputs.map((input) => (
                                                <Grid key={input.id}>
                                                    <Grid.Row style={{ padding: 0, marginTop: '2vh !important' }}  >
                                                        <Grid.Column style={{ textAlign: "left", display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                            <Segment style={{ paddingBottom: '0px !important', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'none', border: 'none', boxShadow: 'none' }}>

                                                                <label style={{ fontWeight: '700', width: labelWidthResponsive, color: fontColor, fontSize: fontSizeResponsive, fontFamily: "CEW_Font_Heavy" }}>
                                                                    {input.title}
                                                                    {input.isRequired && <span style={{
                                                                        color: fontColor, fontSize: fontSizeResponsive, fontFamily: 'CEW_Font_Heavy',
                                                                    }}>*</span>}
                                                                </label>
                                                            </Segment>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{ padding: 0, marginBottom: '2vh !important' }}>
                                                        <Grid.Column>
                                                            {input.id.includes("Dropdown") ? (
                                                                renderDynamicDropdown(input)
                                                            ) : (
                                                                <Input
                                                                    className="live-login-input"
                                                                    style={{
                                                                        marginTop: "1vh",
                                                                        marginBottom: "1vh",
                                                                        width: inputWidth,
                                                                        fontSize: `calc(${fontSizeResponsive} - 2px)`,
                                                                    }}
                                                                    type="text"
                                                                    name={input.title}
                                                                    value={input.value}
                                                                    placeholder={input.title}
                                                                    onChange={(e) => handleDataCaptureInputChange(input.id, input.title, e)}
                                                                />
                                                            )}
                                                        </Grid.Column>
                                                    </Grid.Row>

                                                </Grid>
                                            ))}

                                            {/* <Checkbox style={{ margin: '2vh' }} label={<label
                                        style={{ font: 'CEW_Font', color: fontColor, fontSize: termsFontSizeResponsive, marginTop: '2vh' }}>
                                        I agree to the
                                        <a href="https://www.google.com" style={{ font: 'CEW_Font', color: fontColor, fontSize: termsFontSizeResponsive, textDecoration: 'underline', marginLeft: '0.5vw' }}>
                                            terms and conditions
                                        </a>
                                    </label>} /> */}
                                            <Button
                                                style={{
                                                    fontFamily: 'CEW_Font_Heavy',
                                                    fontSize: '24px',
                                                    marginTop: "7vh",
                                                    background: buttonFillColor,
                                                    border: `2px solid ${buttonStrokeColor}`,
                                                    color: buttonTextColor,
                                                    borderRadius: renderButtonShape(buttonShape),
                                                }}
                                                primary
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </Button>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Grid verticalAlign='bottom' centered>
                                <Grid.Row >
                                    <Grid.Column style={{ display: 'flex', alignItems: 'center' }}>
                                        <Image src={logoImage} alt="" style={{ marginBottom: "2vh", width: logoWidth, marginTop: '10vh' }} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </>
                    )}
                </Container>

            </div>
        </>
    );
};

export default LiveLoginPage1;