import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Container, Grid, Button, Image, Segment } from "semantic-ui-react";
import backgroundImage from "../../img/wordcloudImages/quest_welcome_background.jpg";
import logoImage from "../../img/wordcloudImages/quest_logo.png";

function getQueryParamValue(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}

const LiveIntroPage3 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [logoID, setLogoID] = useState("");
  const eventName = getQueryParamValue("eventName");
  const [device, setDevice] = useState("desktop"); // default to desktop
  const [backgroundImageID, setBackgroundImageID] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://wordwallrebuild.herokuapp.com/wordCloudDashboard/event-dashboard",
        {
          params: {
            eventName: eventName ? eventName : location.state.eventName,
          },
        }
      );
      setLogoID(response.data.selectedLogoID);
      setBackgroundImageID(response.data.selectedCustomBackgroundID);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const setResponsiveness = () => {
      if (window.innerWidth < 768) {
        setDevice("phone");
      } else if (window.innerWidth < 992) {
        setDevice("tablet");
      } else {
        setDevice("desktop");
      }
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <>
      <style>
        {`
          @media only screen and (max-width:767px){
            .ui.container{
                margin-left:0!important;
                margin-right:0!important;
            }
          }
        `}
      </style>
      {device === "desktop" && (
        <Container
          fluid
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "100% auto",
            backgroundPosition: "center",
          }}
        >
          <Grid centered verticalAlign="middle" style={{ height: "100vh", margin: 0 }}>
            <Grid.Row>
              <Grid.Column textAlign="center" style={{ display: 'flex', alignItems: 'center' }}>
                <Segment basic style={{ display: 'contents' }}>
                  <Image style={{ width: '50vw' }} src={logoImage} alt="" />
                  <Button
                    size="massive"
                    primary
                    onClick={() =>
                      navigate("/WordCloud/LiveUserInputPage3", {
                        state: { eventName: eventName ? eventName : location.state.eventName },
                      })
                    }
                    style={{
                      marginTop: '-5vh',
                      background: "rgba(0, 0, 0, 0)",
                      fontFamily: 'quest_font',
                      fontSize: '55px',
                    }}
                  >
                    Tap to Start
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )}
      {device === "phone" && (
        <Container
          fluid
        >
          <Grid
            centered verticalAlign="middle" style={{
              height: '10vh',
              background: '#BF6440'
            }}>
          </Grid>
          <Grid centered verticalAlign="middle" style={{
            height: "90vh",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "400%",
            backgroundPosition: "50% 30%",
            marginLeft: "0px !important",
            marginRight: "0px !important",
          }}>
            <Grid.Row>
              <Grid.Column textAlign="center" style={{ display: 'flex', alignItems: 'center' }}>
                <Segment basic style={{ display: 'contents',marginBottom:'30vh' }}>
                  <Image style={{ width: '40vw',paddingBottom:'15vh' }} src={logoImage} alt="" />

                </Segment>
                <Segment basic style={{ display: 'contents' }}>
                  <Button
                    fluid
                    primary
                    onClick={() =>
                      navigate("/WordCloud/LiveUserInputPage3", {
                        state: { eventName: eventName ? eventName : location.state.eventName },
                      })
                    }
                    style={{
                      marginTop: '0vh',
                      background: "rgba(0, 0, 0, 0)",
                      fontFamily: 'quest_font',
                      fontSize: '37px',
                      paddingBottom:'35vh'
                    }}
                  >
                    Tap to Start.
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )}
      {device === "tablet" && (
        <Container
          fluid
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "100% auto",
            backgroundPosition: "center",
            marginLeft: "0px !important",
            marginRight: "0px !important",
          }}
        >
          <Grid centered verticalAlign="middle" style={{ height: "100vh", margin: 0 }}>
            <Grid.Row>
              <Grid.Column textAlign="center" style={{ display: 'flex', alignItems: 'center' }}>
                <Segment basic style={{ display: 'contents' }}>
                  <Image style={{ width: '50vw' }} src={logoImage} alt="" />
                  <Button
                    size="large"
                    primary
                    onClick={() =>
                      navigate("/WordCloud/LiveUserInputPage3", {
                        state: { eventName: eventName ? eventName : location.state.eventName },
                      })
                    }
                    style={{
                      fontFamily: 'quest_font',
                      marginTop: '0vh',
                      background: "rgba(0, 0, 0, 0)",
                      fontSize: '40px',
                    }}
                  >
                    Tap to Start
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default LiveIntroPage3;
