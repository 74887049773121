import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, Await } from "react-router-dom";
import "./MosaicDashboardPage.css";
import { Container, Grid, Form, Button, Header, Segment, Divider, Input, Icon, Dropdown, Menu, Checkbox, Image, Modal } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChromePicker } from 'react-color';
import Swal from "sweetalert2";
import Slider from '@mui/material/Slider';
import printerIcon from './img/Printer Settings Icon.png';



//each tile size 300*240 px if large mosaic size

const WordCloudDashboardPage = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [mosaicSizeOptions, setMosaicSizeOptions] = useState([
        // {
        //     key: 'large 32x24 - 768 tiles',1
        //     value: 'large 32x24 - 768 tiles',
        //     text: 'large 32x24 - 768 tiles',
        // },
        {
            key: '24x18 - 432 tiles',
            value: '24x18 - 432 tiles',
            text: '24x18 - 432 tiles',
        },
        // {
        //     key: 'small 16x12 - 192 tiles',
        //     value: 'small 16x12 - 192 tiles',
        //     text: 'small 16x12 - 192 tiles',
        // }
        {
            key: 'None',
            value: 'None',
            text: 'None',

        },
        {
            key: '16x9 - 144 tiles',
            value: '16x9 - 144 tiles',
            text: '16x9 - 144 tiles',
        },
        {
            key: '32x18 - 576 tiles',
            value: '32x18 - 576 tiles',
            text: '32x18 - 576 tiles',
        }

    ]);

    const [uploaderButtonOptions, setUploaderButtonOptions] = useState([
        {
            key: 'Disabled',
            value: 'Disabled',
            text: 'Disabled'
        },
        {
            key: 'Enabled',
            value: 'Enabled',
            text: 'Enabled'
        }
    ])


    const [postModerationOptions, setPostModerationOptions] = useState([
        {
            key: 'Manual',
            value: 'Manual',
            text: 'Manual'
        },
        {
            key: 'Automatic',
            value: 'Automatic',
            text: 'Automatic'
        }
    ])

    const [printerOptions, setPrinterOptions] = useState([
        // {
        //     key: '72627578',
        //     value: '72627578',
        //     text: 'Brother_MFC_L3750CDW_series'
        // },
        // {
        //     key: 'random key',
        //     value: 'random value',
        //     text: 'undetermined printer'
        // }
    ])

    const [showAllEntriesChecked, setShowAllEntriesChecked] = useState(false)
    const [showAllPrintedChecked, setShowAllPrintedChecked] = useState(false)

    const [possiblePositionsArray, setPossiblePositionsArray] = useState([])

    const [newOptionText0, setNewOptionText0] = useState('');
    const [newOptionText1, setNewOptionText1] = useState('');
    const [newOptionText2, setNewOptionText2] = useState('');

    const [uploaderButton, setUploaderButton] = useState('')
    const [postModerationButton, setPostModerationButton] = useState('')


    const [imageInput, setImageInput] = useState([]);
    const [printedImageInput, setPrintedImageInput] = useState([]);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [timezone, setTimezone] = useState();
    const [eventLocation, setEventLocation] = useState();
    const [endUsers, setEndUsers] = useState([]);

    const [activeTab, setActiveTab] = useState('Dashboard');

    //Assume text block has maximum of 3, email 1, phone number 1, number 1, checkbox 3, dropdown 3, urlcheckbox 3
    const [isTextBlockRequired, setIsTextBlockRequired] = useState(false);
    const [isEmailRequired, setIsEmailRequired] = useState(false);
    const [isPhoneNumberRequired, setIsPhoneNumberRequired] = useState(false);
    const [isNumberRequired, setIsNumberRequired] = useState(false);
    const [isCheckBoxRequired, setIsCheckBoxRequired] = useState(false);
    const [isDropdownRequired, setIsDropdownRequired] = useState(false);
    const [isURLCheckboxRequired, setIsURLCheckboxRequired] = useState(false);

    const [isTextBlockRequired1, setIsTextBlockRequired1] = useState(false);
    const [isTextBlockRequired2, setIsTextBlockRequired2] = useState(false);

    const [isCheckBoxRequired1, setIsCheckBoxRequired1] = useState(false);
    const [isCheckBoxRequired2, setIsCheckBoxRequired2] = useState(false);

    const [isDropdownRequired1, setIsDropdownRequired1] = useState(false);
    const [isDropdownRequired2, setIsDropdownRequired2] = useState(false);

    const [isURLCheckboxRequired1, setIsURLCheckboxRequired1] = useState(false);
    const [isURLCheckboxRequired2, setIsURLCheckboxRequired2] = useState(false);

    const [textBlockTitle, setTextBlockTitle] = useState('');
    const [emailTitle, setEmailTitle] = useState('');
    const [phoneNumberTitle, setPhoneNumberTitle] = useState('');
    const [numberTitle, setNumberTitle] = useState('');
    const [checkBoxTitle, setCheckBoxTitle] = useState('');
    const [dropdownTitle, setDropdownTitle] = useState('');
    const [urlCheckboxTitle, setUrlCheckboxTitle] = useState('');

    const [textBlockTitle1, setTextBlockTitle1] = useState('');
    const [textBlockTitle2, setTextBlockTitle2] = useState('');

    const [checkBoxTitle1, setCheckBoxTitle1] = useState('');
    const [checkBoxTitle2, setCheckBoxTitle2] = useState('');

    const [dropdownTitle1, setDropdownTitle1] = useState('');
    const [dropdownTitle2, setDropdownTitle2] = useState('');

    const [urlCheckboxTitle1, setUrlCheckboxTitle1] = useState('');
    const [urlCheckboxTitle2, setUrlCheckboxTitle2] = useState('');


    const [textBlockCount, setTextBlockCount] = useState(0);
    const [emailCount, setEmailCount] = useState(0);
    const [phoneNumberCount, setPhoneNumberCount] = useState(0);
    const [numberCount, setNumberCount] = useState(0);
    const [checkBoxCount, setCheckBoxCount] = useState(0);
    const [dropdownCount, setDropdownCount] = useState(0);
    const [urlCheckboxCount, setUrlCheckboxCount] = useState(0);

    const [selectedDropdownValue0, setSelectedDropdownValue0] = useState();
    const [selectedDropdownValue1, setSelectedDropdownValue1] = useState();
    const [selectedDropdownValue2, setSelectedDropdownValue2] = useState();


    const [draggableElementsArray, setDraggableElementArray] = useState([
        // {
        //     id: 'Text Block0',
        //     title: 'Question Text',
        // },
    ])

    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    function overlayImageSliderText() {
        return `${mosaicOverlayImageOpacity}%`
    }

    function backgroundImageSliderText() {
        return `${mosaicBackgroundImageOpacity}%`
    }

    const handleShowAllEntries = (e, { checked }) => {
        setShowAllEntriesChecked(checked);
    };

    const handleShowAllPrinted = (e, { checked }) => {
        setShowAllPrintedChecked(checked);
    };


    const [draggableItems, updateDraggableItems] = useState(draggableElementsArray);

    const [selectAll, setSelectAll] = useState(false);
    const [jobId, setJobId] = useState(0);

    const [mosaicOverlayImageID, setMosaicOverlayImageID] = useState('')
    const [mosaicOverlayImageOpacity, setMosaicOverlayImageOpacity] = useState(0)
    const [mosaicBackgroundImageID, setMosaicBackgroundImageID] = useState('')
    const [mosaicBackgroundImageOpacity, setMosaicBackgroundImageOpacity] = useState(0)

    const [mosaicSize, setMosaicSize] = useState(mosaicSizeOptions)
    const [postsTotalAmount, setPostsTotalAmount] = useState(0);
    const [printingFinishedAmount, setPrintingFinishedAmount] = useState(0)
    const [printingFailedAmount, setPrintingFailedAmount] = useState(0)
    const [printingQueuedAmount, setPrintingQueuedAmount] = useState(0)
    const [moderationApprovedAmount, setModerationApprovedAmount] = useState(0)
    const [moderationRejectedAmount, setModerationRejectedAmount] = useState(0)
    const [moderationPendingAmount, setModerationPendingAmount] = useState(0)
    const [moderationOverflowAmount, setModerationOverflowAmount] = useState(0)

    const [mosaicLogoID, setMosaicLogoID] = useState('');
    const [mosaicLogoName, setMosaicLogoName] = useState('');

    const [mosaicButtonFillColor, setMosaicButtonFillColor] = useState('');

    const [loginTextColor, setLoginTextColor] = useState('')
    const [loginButtonColor, setLoginButtonColor] = useState('')

    const [printerSelected, setPrinterSelected] = useState('')

    const [overlayImageName, setOverLayImageName] = useState('')
    const [backgroundImageName, setBackgroundImageName] = useState('')

    const [displayButtonFillColorPicker, setDisplayButtonFillColorPicker] = useState(false);
    const [displayLoginTextColorPicker, setDisplayLoginTextColorPicker] = useState(false);
    const [displayLoginButtonColorPicker, setDisplayLoginButtonColorPicker] = useState(false)

    const handlePrinterChange = (event, data) => {
        console.log(data); // Logging the data object to see its structure
        setPrinterSelected(data.value); // Updating the state with the selected value
    }

    const handleReprint = async (imageID, eventName) => {
        try {
            const response = await axios.post(`https://wordwallrebuild.herokuapp.com/mosaicDashboard/printJobs/reprint/${imageID}`, null, {
                params: { eventName }
            });
            console.log('Reprint response:', response.data);
            // Handle the response as needed
        } catch (error) {
            console.error('Error in reprinting:', error);
            // Handle the error as needed
        }
    };

    const handleButtonFillColorOnClick = () => { setDisplayButtonFillColorPicker(!displayButtonFillColorPicker) }
    const handleLoginTextColorOnClick = () => { setDisplayLoginTextColorPicker(!displayLoginTextColorPicker) }
    const handleLoginButtonColorOnClick = () => { setDisplayLoginButtonColorPicker(!displayLoginButtonColorPicker) }

    const handleOptionTextChange0 = (event) => {
        setNewOptionText0(event.target.value);
    }
    const handleOptionTextChange1 = (event) => {
        setNewOptionText1(event.target.value);
    }
    const handleOptionTextChange2 = (event) => {
        setNewOptionText2(event.target.value);
    }

    const handleAddDropdownOptions0 = () => {
        if (newOptionText0.trim() !== '') {
            const option = {
                key: newOptionText0,
                text: newOptionText0,
                value: newOptionText0,
            };
            setOptions0([...options0, option]);
            setNewOptionText0('');
        }
    }

    const handleAddDropdownOptions1 = () => {
        if (newOptionText1.trim() !== '') {
            const option = {
                key: newOptionText1,
                text: newOptionText1,
                value: newOptionText1,
            };
            setOptions1([...options1, option]);
            setNewOptionText1('');
        }
    }

    const handleAddDropdownOptions2 = () => {
        if (newOptionText2.trim() !== '') {
            const option = {
                key: newOptionText2,
                text: newOptionText2,
                value: newOptionText2,
            };
            setOptions2([...options2, option]);
            setNewOptionText2('');
        }
    }


    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };


    const handleOverlayImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            // setMosaicOverlayImageID(event.target.files[0]);
            const fullBase64String = await fileToBase64(event.target.files[0]);
            // console.log(fullBase64String.length)

            const base64String = fullBase64String.split(',')[1];

            const formData = new FormData();
            formData.append('file', event.target.files[0]);

            const params = {
                eventName: location.state.eventName, // Replace with your desired query parameter name and value
            };

            // Second Axios call to send Base64 encoded image data to MongoDB
            const imagePayload = {
                base64Image: base64String,
                eventName: location.state.eventName,
            };

            await axios.post('/mosaicDashboard/UpdateOverlayImageData', imagePayload)
                .then((response) => {
                    console.log('Image data saved to MongoDB:', response.data);
                })
                .catch((error) => {
                    console.error(error);
                    console.log('Error saving image data to MongoDB');
                });

            await axios
                .post('/api/googleUpload/mosaicUpload/addOverlayImage', formData, {
                    headers: { 'Content-Type': 'multipart/form-data', },
                    params: params,
                })
                .then((response) => {
                    setOverLayImageName(response.data.name)
                    console.log('File uploaded successfully:', response.data);

                    //set overlayimage id
                    setMosaicOverlayImageID(response.data.id)
                })
                .catch((error) => {
                    console.error(error);
                    console.log('Error uploading file');
                });
        }
    }

    const handleMosaicLogoImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const fullBase64String = await fileToBase64(event.target.files[0]);
            const base64String = fullBase64String.split(',')[1];
            const formData = new FormData();
            formData.append('file', event.target.files[0]);

            const params = {
                eventName: location.state.eventName, // Replace with your desired query parameter name and value
            };

            axios
                .post('/api/googleUpload/mosaicUpload/addLogoImage', formData, {
                    headers: { 'Content-Type': 'multipart/form-data', },
                    params: params,
                })
                .then((response) => {
                    setMosaicLogoName(response.data.name)
                    console.log('File uploaded successfully:', response.data);

                    //set backgroundimage id
                    setMosaicLogoID(response.data.id)
                })
                .catch((error) => {
                    console.error(error);
                    console.log('Error uploading file');
                });

            //second  axios call to send base64 encoded image data to mongodb
            const imagePayload = {
                base64Image: base64String,
                eventName: location.state.eventName,
            };
            axios.put('/mosaicDashboard/UpdateLogoImageData', imagePayload)
                .then((response) => {
                    console.log('Image data saved to MongoDB:', response.data);
                })
                .catch((error) => {
                    console.error(error);
                    console.log('Error saving image data to MongoDB');
                });
        }
    }

    const handleBackgroundImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            // setMosaicBackgroundImageID(event.target.files[0]);
            const fullBase64String = await fileToBase64(event.target.files[0]);
            const base64String = fullBase64String.split(',')[1];
            const formData = new FormData();

            formData.append('file', event.target.files[0]);

            const params = {
                eventName: location.state.eventName, // Replace with your desired query parameter name and value
            };

            axios
                .post('/api/googleUpload/mosaicUpload/addBackgroundImage', formData, {
                    headers: { 'Content-Type': 'multipart/form-data', },
                    params: params,
                })
                .then((response) => {
                    setBackgroundImageName(response.data.name)
                    console.log('File uploaded successfully:', response.data);

                    //set backgroundimage id
                    setMosaicBackgroundImageID(response.data.id)
                })
                .catch((error) => {
                    console.error(error);
                    console.log('Error uploading file');
                });

            const imagePayload = {
                base64Image: base64String,
                eventName: location.state.eventName,
            };

            axios.put('/mosaicDashboard/UpdateBackgroundImageData', imagePayload)
                .then((response) => {
                    console.log('Image data saved to MongoDB:', response.data);
                })
                .catch((error) => {
                    console.error(error);
                    console.log('Error saving image data to MongoDB');
                });
        }
    }



    useEffect(() => {
        updateDraggableItems(draggableElementsArray);
    }, [draggableElementsArray]);

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(draggableItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updateDraggableItems(items);
    }

    function deleteItem(id) {
        const newElementsArray = draggableElementsArray.filter(item => item.id !== id);
        setDraggableElementArray(newElementsArray);
        if (id == 'Text Block0' || id == 'Text Block1' || id == 'Text Block2') {
            if (textBlockCount > 0) {
                setTextBlockCount(textBlockCount - 1);
                if (id == 'Text Block0') {
                    setTextBlockTitle('');
                    setIsTextBlockRequired(false);
                } else if (id == 'Text Block1') {
                    setTextBlockTitle1('');
                    setIsTextBlockRequired1(false);
                } else if (id == 'Text Block2') {
                    setTextBlockTitle2('');
                    setIsTextBlockRequired2(false);
                }
            }
        } else if (id == 'Email0') {
            if (emailCount > 0) {
                setEmailCount(emailCount - 1);
                setEmailTitle('');
                setIsEmailRequired(false)
            }
        } else if (id == 'Phone Number0') {
            if (phoneNumberCount > 0) {
                setPhoneNumberCount(phoneNumberCount - 1);
                setPhoneNumberTitle('');
                setIsPhoneNumberRequired(false)
            }
        } else if (id == 'Number0') {
            if (numberCount > 0) {
                setNumberCount(numberCount - 1);
                setNumberTitle('');
                setIsNumberRequired(false)
            }
        } else if (id == 'Check Box0' || id == 'Check Box1' || id == 'Check Box2') {
            if (checkBoxCount > 0) {
                setCheckBoxCount(checkBoxCount - 1);
                if (id == 'Check Box0') {
                    setCheckBoxTitle('');
                    setIsCheckBoxRequired(false);
                }
                else if (id == 'Check Box1') {
                    setCheckBoxTitle1('');
                    setIsCheckBoxRequired1(false)
                }
                else if (id == 'Check Box2') {
                    setCheckBoxTitle2('');
                    setIsCheckBoxRequired2(false)
                }
            }
        } else if (id == 'Dropdown0' || id == 'Dropdown1' || id == 'Dropdown2') {
            if (dropdownCount > 0) {
                setDropdownCount(dropdownCount - 1);
                if (id == 'Dropdown0') {
                    setDropdownTitle('');
                    setIsDropdownRequired(false)
                    setOptions0([])
                }
                else if (id == 'Dropdown1') {
                    setDropdownTitle1('');
                    setIsDropdownRequired1(false)
                    setOptions1([])

                }
                else if (id == 'Dropdown2') {
                    setDropdownTitle2('');
                    setIsDropdownRequired2(false)
                    setOptions2([])

                }
            }
        } else if (id == 'URL Checkbox0' || id == 'URL Checkbox1' || id == 'URL Checkbox2') {
            if (urlCheckboxCount > 0) {
                setUrlCheckboxCount(urlCheckboxCount - 1);
                if (id == 'URL Checkbox0') {
                    setUrlCheckboxTitle('');
                    setIsURLCheckboxRequired(false)
                }
                else if (id == 'URL Checkbox1') {
                    setUrlCheckboxTitle1('');
                    setIsURLCheckboxRequired1(false)
                }
                else if (id == 'URL Checkbox2') {
                    setUrlCheckboxTitle2('');
                    setIsURLCheckboxRequired2(false)
                }
            }
        }
    }

    const addTextBlock = () => {
        if (textBlockCount < 3) {
            const idExists = draggableElementsArray.some(element => element.id === 'Text Block' + textBlockCount);
            if (!idExists) {
                setDraggableElementArray([...draggableElementsArray, {
                    id: 'Text Block' + textBlockCount,
                    title: 'Question Text',
                    value: ''
                }]);
                setTextBlockTitle('Question Text')
                setTextBlockCount(textBlockCount + 1);
            } else {
                let arr = [0, 1, 2]
                arr = arr.filter(element => element !== textBlockCount);
                const idExists1 = draggableElementsArray.some(element => element.id === 'Text Block' + arr[0]);
                if (!idExists1) {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Text Block' + arr[0],
                        title: 'Question Text',
                        value: ''
                    }]);
                    setTextBlockTitle1('Question Text')
                    setTextBlockCount(textBlockCount + 1);
                } else {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Text Block' + arr[1],
                        title: 'Question Text',
                        value: ''
                    }]);
                    setTextBlockTitle2('Question Text')
                    setTextBlockCount(textBlockCount + 1);
                }
            }
        }
    }

    const addEmail = () => {
        if (emailCount < 1) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Email' + emailCount,
                title: 'Email',
                value: ''
            }]);
            setEmailTitle('Email')
            setEmailCount(emailCount + 1);
        }
    }

    const addPhoneNumber = () => {
        if (phoneNumberCount < 1) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Phone Number' + phoneNumberCount,
                title: 'Phone Number',
                value: ''
            }]);
            setPhoneNumberTitle('Phone Number')
            setPhoneNumberCount(phoneNumberCount + 1);
        }
    }

    const addNumber = () => {
        if (numberCount < 1) {
            setDraggableElementArray([...draggableElementsArray, {
                id: 'Number' + numberCount,
                title: 'Number',
                value: ''
            }]);
            setNumberTitle('Number')
            setNumberCount(numberCount + 1);
        }
    }

    const addCheckBox = () => {
        if (checkBoxCount < 3) {
            const idExists = draggableElementsArray.some(element => element.id === 'Check Box' + checkBoxCount);
            if (!idExists) {
                setDraggableElementArray([...draggableElementsArray, {
                    id: 'Check Box' + checkBoxCount,
                    title: 'Check Box',
                    value: ''
                }]);
                setCheckBoxTitle('Check Box')
                setCheckBoxCount(checkBoxCount + 1);
            } else {
                let arr = [0, 1, 2]
                arr = arr.filter(element => element !== textBlockCount);
                const idExists1 = draggableElementsArray.some(element => element.id === 'Check Box' + arr[0]);
                if (!idExists1) {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Check Box' + arr[0],
                        title: 'Check Box',
                        value: ''
                    }]);
                    setCheckBoxTitle1('Check Box')
                    setCheckBoxCount(checkBoxCount + 1);
                } else {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Check Box' + arr[1],
                        title: 'Check Box',
                        value: ''
                    }]);
                    setCheckBoxTitle2('Check Box')
                    setCheckBoxCount(checkBoxCount + 1);
                }
            }
        }
    }

    const addDropdown = () => {
        if (dropdownCount < 3) {
            const idExists = draggableElementsArray.some(element => element.id === 'Dropdown' + dropdownCount);
            if (!idExists) {
                setDraggableElementArray([...draggableElementsArray, {
                    id: 'Dropdown' + dropdownCount,
                    title: 'Dropdown',
                    value: ''
                }]);
                setDropdownTitle('Dropdown')
                setDropdownCount(dropdownCount + 1);
            } else {
                let arr = [0, 1, 2]
                arr = arr.filter(element => element !== dropdownCount);
                const idExists1 = draggableElementsArray.some(element => element.id === 'Dropdown' + arr[0]);
                if (!idExists1) {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Dropdown' + arr[0],
                        title: 'Dropdown',
                        value: ''
                    }]);
                    setDropdownTitle1('Dropdown')
                    setDropdownCount(dropdownCount + 1);
                } else {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'Dropdown' + arr[1],
                        title: 'Dropdown',
                        value: ''
                    }]);
                    setDropdownTitle2('Dropdown')
                    setDropdownCount(dropdownCount + 1);
                }
            }
        }
    }

    const addURLCheckbox = () => {
        if (urlCheckboxCount < 3) {
            const idExists = draggableElementsArray.some(element => element.id === 'URL Checkbox' + urlCheckboxCount);
            if (!idExists) {
                setDraggableElementArray([...draggableElementsArray, {
                    id: 'URL Checkbox' + urlCheckboxCount,
                    title: 'URL Checkbox',
                    value: ''
                }]);
                setUrlCheckboxTitle('URL Checkbox')
                setUrlCheckboxCount(urlCheckboxCount + 1);
            } else {
                let arr = [0, 1, 2]
                arr = arr.filter(element => element !== urlCheckboxCount);
                const idExists1 = draggableElementsArray.some(element => element.id === 'URL Checkbox' + arr[0]);
                if (!idExists1) {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'URL Checkbox' + arr[0],
                        title: 'URL Checkbox',
                        value: ''
                    }]);
                    setUrlCheckboxTitle1('URL Checkbox')
                    setUrlCheckboxCount(urlCheckboxCount + 1);
                } else {
                    setDraggableElementArray([...draggableElementsArray, {
                        id: 'URL Checkbox' + arr[1],
                        title: 'URL Checkbox',
                        value: ''
                    }]);
                    setUrlCheckboxTitle2('URL Checkbox')
                    setUrlCheckboxCount(urlCheckboxCount + 1);
                }
            }
        }
    }

    const renderItem = (item) => {
        if (item.id === 'Text Block0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={textBlockTitle != '' ? textBlockTitle : item.title}
                                            onChange={(event) => setTextBlockTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isTextBlockRequired} onClick={handleTextBlockRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                            style={{ backgroundColor: "#F2F2F7 !important" }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id == 'Text Block1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={textBlockTitle1 != '' ? textBlockTitle1 : item.title}
                                            onChange={(event) => setTextBlockTitle1(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isTextBlockRequired1} onClick={handleTextBlockRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id == 'Text Block2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={textBlockTitle2 != '' ? textBlockTitle2 : item.title}
                                            onChange={(event) => setTextBlockTitle2(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isTextBlockRequired2} onClick={handleTextBlockRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Email0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={emailTitle != '' ? emailTitle : item.title}
                                            onChange={(event) => setEmailTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isEmailRequired} onClick={handleEmailRequiredChange} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Phone Number0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={phoneNumberTitle != '' ? phoneNumberTitle : item.title}
                                            onChange={(event) => setPhoneNumberTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isPhoneNumberRequired} onClick={handlePhoneNumberRequiredChange} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Number0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={numberTitle != '' ? numberTitle : item.title}
                                            onChange={(event) => setNumberTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isNumberRequired} onClick={handleNumberRequiredChange} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Check Box0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={checkBoxTitle != '' ? checkBoxTitle : item.title}
                                            onChange={(event) => setCheckBoxTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isCheckBoxRequired} onClick={handleCheckBoxRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Check Box1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={checkBoxTitle1 != '' ? checkBoxTitle1 : item.title}
                                            onChange={(event) => setCheckBoxTitle1(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isCheckBoxRequired1} onClick={handleCheckBoxRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Check Box2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={checkBoxTitle2 != '' ? checkBoxTitle2 : item.title}
                                            onChange={(event) => setCheckBoxTitle2(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isCheckBoxRequired2} onClick={handleCheckBoxRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Dropdown0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={dropdownTitle != '' ? dropdownTitle : item.title}
                                            onChange={(event) => setDropdownTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isDropdownRequired} onClick={handleDropdownRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Dropdown
                                            selection
                                            fluid
                                            options={options0}
                                            value={selectedDropdownValue0}
                                            onChange={handleDropdownChange0}
                                            placeholder='' style={{
                                                textAlign: 'center',
                                                backgroundColor: '#f2f2f7'
                                            }}
                                        />
                                        <Grid style={{ marginTop: "1vh" }}>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Input fluid type="text" value={newOptionText0} onChange={handleOptionTextChange0} placeholder="Add Option Here" />
                                                </Grid.Column>
                                                <Grid.Column style={{ textAlign: "left" }}>
                                                    <Button as="label" onClick={handleAddDropdownOptions0} type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                        Add Option
                                                        <Icon name="plus" />
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Dropdown1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={dropdownTitle1 != '' ? dropdownTitle1 : item.title}
                                            onChange={(event) => setDropdownTitle1(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isDropdownRequired1} onClick={handleDropdownRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Dropdown
                                            selection
                                            fluid
                                            options={options1}
                                            value={selectedDropdownValue1}
                                            onChange={handleDropdownChange1}
                                            placeholder='' style={{
                                                textAlign: 'center',
                                                backgroundColor: '#f2f2f7'
                                            }}
                                        />
                                        <Grid style={{ marginTop: "1vh" }}>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Input fluid type="text" value={newOptionText1} onChange={handleOptionTextChange1} placeholder="Add Option Here" />
                                                </Grid.Column>
                                                <Grid.Column style={{ textAlign: "left" }}>
                                                    <Button as="label" onClick={handleAddDropdownOptions1} type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                        Add Option
                                                        <Icon name="plus" />
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'Dropdown2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={dropdownTitle2 != '' ? dropdownTitle2 : item.title}
                                            onChange={(event) => setDropdownTitle2(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isDropdownRequired2} onClick={handleDropdownRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Dropdown
                                            selection
                                            fluid
                                            options={options2}
                                            value={selectedDropdownValue2}
                                            onChange={handleDropdownChange2}
                                            placeholder='' style={{
                                                textAlign: 'center',
                                                backgroundColor: '#f2f2f7'
                                            }}
                                        />
                                        <Grid style={{ marginTop: "1vh" }}>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Input fluid type="text" value={newOptionText2} onChange={handleOptionTextChange2} placeholder="Add Option Here" />
                                                </Grid.Column>
                                                <Grid.Column style={{ textAlign: "left" }}>
                                                    <Button as="label" onClick={handleAddDropdownOptions2} type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                        Add Option
                                                        <Icon name="plus" />
                                                    </Button>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'URL Checkbox0') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)} >
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={urlCheckboxTitle != '' ? urlCheckboxTitle : item.title}
                                            onChange={(event) => setUrlCheckboxTitle(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isURLCheckboxRequired} onClick={handleURLCheckboxRequiredChange0} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'URL Checkbox1') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={urlCheckboxTitle1 != '' ? urlCheckboxTitle1 : item.title}
                                            onChange={(event) => setUrlCheckboxTitle1(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isURLCheckboxRequired1} onClick={handleURLCheckboxRequiredChange1} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else if (item.id === 'URL Checkbox2') {
            return (
                <Grid>
                    <Grid.Column width={2} style={{ paddingRight: "0px" }}>
                        <Grid.Row>
                            <Button tiny icon onClick={() => deleteItem(item.id)}>
                                <Icon name='trash alternate' />
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            ..
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Segment className="custom-wordcloud-inside-segment">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column floated='left' width={8} textAlign="left" >
                                        <Input value={urlCheckboxTitle2 != '' ? urlCheckboxTitle2 : item.title}
                                            onChange={(event) => setUrlCheckboxTitle2(event.target.value)} />
                                    </Grid.Column>
                                    <Grid.Column floated='right' width={8} textAlign="right">
                                        <Checkbox label="Required" checked={isURLCheckboxRequired2} onClick={handleURLCheckboxRequiredChange2} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input
                                            size='massive'
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
        else {

        }
    };


    const handleTextBlockRequiredChange0 = () => {
        setIsTextBlockRequired(!isTextBlockRequired);
    }
    const handleEmailRequiredChange = () => {
        setIsEmailRequired(!isEmailRequired);
    }
    const handlePhoneNumberRequiredChange = () => {
        setIsPhoneNumberRequired(!isPhoneNumberRequired);
    }
    const handleNumberRequiredChange = () => {
        setIsNumberRequired(!isNumberRequired);
    }
    const handleCheckBoxRequiredChange0 = () => {
        setIsCheckBoxRequired(!isCheckBoxRequired);
    }
    const handleDropdownRequiredChange0 = () => {
        setIsDropdownRequired(!isDropdownRequired);
    }
    const handleURLCheckboxRequiredChange0 = () => {
        setIsURLCheckboxRequired(!isURLCheckboxRequired);
    }

    const handleTextBlockRequiredChange1 = () => {
        setIsTextBlockRequired1(!isTextBlockRequired1);
    }

    const handleTextBlockRequiredChange2 = () => {
        setIsTextBlockRequired2(!isTextBlockRequired2);
    }

    const handleCheckBoxRequiredChange1 = () => {
        setIsCheckBoxRequired1(!isCheckBoxRequired1);
    }

    const handleCheckBoxRequiredChange2 = () => {
        setIsCheckBoxRequired2(!isCheckBoxRequired2);
    }

    const handleDropdownRequiredChange1 = () => {
        setIsDropdownRequired1(!isDropdownRequired1);
    }
    const handleDropdownRequiredChange2 = () => {
        setIsDropdownRequired2(!isDropdownRequired2);
    }
    const handleURLCheckboxRequiredChange1 = () => {
        setIsURLCheckboxRequired1(!isURLCheckboxRequired1);
    }
    const handleURLCheckboxRequiredChange2 = () => {
        setIsURLCheckboxRequired2(!isURLCheckboxRequired2);
    }

    const handleDropdownChange0 = (e, { value }) => {
        setSelectedDropdownValue0(value);
    }
    const handleDropdownChange1 = (e, { value }) => {
        setSelectedDropdownValue1(value);
    }
    const handleDropdownChange2 = (e, { value }) => {
        setSelectedDropdownValue2(value);
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const [options0, setOptions0] = useState([])
    const [options1, setOptions1] = useState([])
    const [options2, setOptions2] = useState([])


    const handleMosaicSizeChange = (e, { value }) => {
        console.log(value)
        // Update possiblePositionsArray according to mosaic size
        if (value == '32x24 - 768 tiles') {
            const maxX = 24;
            const maxY = 32;
            let newPossiblePositionsArray = [];
            for (let x = 0; x < maxX; x++) {
                for (let y = 0; y < maxY; y++) {
                    const position = { x, y };
                    newPossiblePositionsArray.push({ imageData: '', imagePosition: position, printingDevice: '', printingTime: '', status: '' });
                }
            }

            setPossiblePositionsArray(newPossiblePositionsArray);
        }
        else if (value == '24x18 - 432 tiles') {
            const maxX = 18;
            const maxY = 24;
            let newPossiblePositionsArray = [];
            for (let x = 0; x < maxX; x++) {
                for (let y = 0; y < maxY; y++) {
                    const position = { x, y };
                    newPossiblePositionsArray.push({ imageData: '', imagePosition: position, printingDevice: '', printingTime: '', status: '' });
                }
            }
            setPossiblePositionsArray(newPossiblePositionsArray);

        }
        else if (value == '16x9 - 144 tiles') {
            const maxX = 9;
            const maxY = 16;
            let newPossiblePositionsArray = [];
            for (let x = 0; x < maxX; x++) {
                for (let y = 0; y < maxY; y++) {
                    const position = { x, y };
                    newPossiblePositionsArray.push({ imageData: '', imagePosition: position, printingDevice: '', printingTime: '', status: '' });
                }
            }
            setPossiblePositionsArray(newPossiblePositionsArray);

        }
        else if (value == '32x18 - 576 tiles') {
            const maxX = 18;
            const maxY = 32;
            let newPossiblePositionsArray = [];
            for (let x = 0; x < maxX; x++) {
                for (let y = 0; y < maxY; y++) {
                    const position = { x, y };
                    newPossiblePositionsArray.push({ imageData: '', imagePosition: position, printingDevice: '', printingTime: '', status: '' });
                }
            }
            setPossiblePositionsArray(newPossiblePositionsArray);

        }
        setMosaicSize(value);

    };


    const handleButtonFillColorChangeFromText = (e, { value }) => { setMosaicButtonFillColor(value) }
    const handleLoginTextColorChangeFromText = (e, { value }) => { setLoginTextColor(value) }
    const handleLoginButtonColorChangeFromText = (e, { value }) => { setLoginButtonColor(value) }
    const handleButtonFillColorChange = (newColour) => { setMosaicButtonFillColor(newColour.hex) }
    const handleLoginTextColorChange = (newColour) => { setLoginTextColor(newColour.hex) }
    const handleLoginButtonColorChange = (newColour) => { setLoginButtonColor(newColour.hex) }
    const handleButtonFillColorClose = () => { setDisplayButtonFillColorPicker(false) }
    const handleLoginTextColorClose = () => { setDisplayLoginTextColorPicker(false) }
    const handleLoginButtonColorClose = () => { setDisplayLoginButtonColorPicker(false) }
    const handleUploaderButtonChange = (e, { value }) => { setUploaderButton(value) }
    const handlePostModerationButtonChange = (e, { value }) => { setPostModerationButton(value) }

    useEffect(() => {
        const fetchAllEntries = async () => {
            if (showAllEntriesChecked) {
                try {
                    const token = localStorage.getItem("token");
                    if (token) {
                        await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                        await fetchAllImages();

                    }
                    else {
                        navigate('/')
                    }

                } catch (error) {
                    console.error(error);
                    navigate('/')
                };
            }

        }
        fetchAllEntries();

    }, [showAllEntriesChecked]);

    useEffect(() => {
        const fetchAllPrinted = async () => {
            if (showAllPrintedChecked) {
                try {
                    const token = localStorage.getItem("token");
                    if (token) {
                        await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                        await fetchAllPrintedImages();
                    }
                    else {
                        navigate('/')
                    }
                } catch (error) {
                    console.error(error);
                    navigate('/')
                };
            }
        }
        fetchAllPrinted();

    }, [showAllPrintedChecked]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                    //load custom background list
                    // await fetchCustomBackgroundList()

                    //load printer lists 
                    await fetchPrinterLists();
                    //load main info from mongodb
                    await fetchEventDashboard();
                    await fetchRecent8Images();
                    await fetchRecent8PrintedImages();
                    // await fetchAllImages();

                    await fetchPostsAmount()
                    await fetchFinishedAmount()
                    await fetchFailedAmount()
                    await fetchQueuedAmount()

                    await fetchApprovedAmount()
                    await fetchPendingAmount()
                    await fetchRejectedAmount()
                } else {
                    navigate("/");
                }
            } catch (error) {
                console.error(error);
                navigate("/");
            }
        };
        fetchData();
    }, []);

    const [selectedRecords, setSelectedRecords] = useState([]);
    const handleEntriesCheckbox = (label) => {
        if (selectedRecords.includes(label)) {
            setSelectedRecords((prevSelectedRecords) =>
                prevSelectedRecords.filter((record) => record !== label)
            );
        } else {
            setSelectedRecords((prevSelectedRecords) => [...prevSelectedRecords, label]);
        }
    };

    const handleDeleteSelected = async () => {
        // Confirm before proceeding with the delete
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = localStorage.getItem("token");
                // Track success/failure counts
                let successCount = 0;
                let failureCount = 0;

                for (const imageId of selectedRecords) {
                    const image = imageInput.find(img => img._id === imageId);

                    try {
                        const response = await axios.delete("https://wordwallrebuild.herokuapp.com/mosaicDashboard/deleteImage", {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            params: {
                                imageId: imageId,
                                eventName: location.state.eventName,
                                imagePositionX: image.imagePosition.x,
                                imagePositionY: image.imagePosition.y,
                            }
                        });
                        console.log(`Image with ID ${imageId} deleted:`, response.data);
                        successCount++;
                    } catch (error) {
                        console.error(`Error deleting image with ID ${imageId}:`, error.response?.data || error.message);
                        failureCount++;
                    }
                }

                // Update the UI after all deletions have been attempted
                setImageInput((prevImageInput) => prevImageInput.filter((image) => !selectedRecords.includes(image._id)));
                setSelectedRecords([]);

                // Show result summary
                if (successCount > 0 && failureCount === 0) {
                    Swal.fire(
                        'Deleted!',
                        `${successCount} image(s) have been deleted.`,
                        'success'
                    );
                } else if (failureCount > 0) {
                    Swal.fire(
                        'Partial Success',
                        `${successCount} image(s) were deleted but ${failureCount} failed.`,
                        'warning'
                    );
                }
            }
        });
    };



    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRecords([]);
        } else {
            // Extracting only the imageIDs and setting them
            const allImageIDs = imageInput.map(image => image.imageData);
            setSelectedRecords(allImageIDs);
        }
        setSelectAll(!selectAll);
    };

    const showImages = () => {
        let rows = [];
        let currentRow = [];

        for (let i = 0; i < imageInput.length; i++) {
            const image = imageInput[i];
            const isSelected = selectedRecords.includes(image._id);

            currentRow.push(
                <Grid.Column key={i} style={{ textAlign: 'left', overflowWrap: 'anywhere' }}>
                    <img src={`data:image/jpeg;base64,${image.imageData}`} alt="Description"
                        onClick={() => handleEntriesCheckbox(image._id)}
                        style={{
                            border: isSelected ? '5px solid blue' : '5px solid transparent',
                            display: 'inline-block'
                        }} />
                </Grid.Column>
            );

            if (currentRow.length === 4 || i === imageInput.length - 1) {
                rows.push(
                    <>
                        <Grid.Row equal columns={4}>
                            {currentRow}
                        </Grid.Row>
                        {i !== imageInput.length - 1 && <Divider />}
                    </>
                );
                currentRow = [];
            }
        }
        return rows;
    }

    function convertUtcToLocal(utcDateString) {
        // Create a new Date object using the UTC time
        const date = new Date(utcDateString);

        // Convert it to the local time string
        return date.toLocaleString();
    }

    const showPrintedImages = () => {
        let rows = []

        for (let i = 0; i < printedImageInput.length; i++) {
            const image = printedImageInput[i];
            if (image.printed != 'Finished' && image.printed != 'Pending' && image.printed != 'Failed' && image.printed != 'Cancelled') {
                // meaning that printed status being null or empty 
                // then render nothing
                rows.push(<>
                </>)
            } else {
                rows.push(
                    <>
                        <Grid.Row columns={3}>
                            <Grid.Column style={{ textAlign: 'center' }} width={10} >
                                <Segment style={{ padding: 0 }}>
                                    <Grid columns={2} style={{ height: '25vh', margin: 0 }}>
                                        <Grid.Column width={4} style={{ paddingTop: 0, paddingBottom: 0, display: 'inline-grid', alignContent: 'center' }}>
                                            <Grid.Row>
                                                <Button fluid style={{
                                                    background: image.printed == 'Finished' ? '#0BAF68' :
                                                        image.printed == 'Pending' ? '#0A47C1' :
                                                            image.printed == 'Failed' ? '#F24462' :
                                                                image.printed == 'Cancelled' ? '#000033' : '#8b8989', color: '#fff'
                                                }}>
                                                    {
                                                        image.printed
                                                            ? image.printed == 'Finished'
                                                                ? 'Complete'
                                                                : image.printed == 'Pending'
                                                                    ? 'Pending'
                                                                    : image.printed == 'Failed'
                                                                        ? 'Failed'
                                                                        : image.printed == 'Cancelled'
                                                                            ? 'Cancelled'
                                                                            : 'Processing'
                                                            : 'Processing'
                                                    }
                                                </Button>
                                            </Grid.Row>
                                            <Grid.Row style={{ opacity: 0 }}>
                                                invisible
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Button size='big' fluid onClick={() => handleReprint(image._id, location.state.eventName)}
                                                    style={{ background: '#F0AEBA', color: '#fff', borderRadius: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    Reprint
                                                    <Image src={printerIcon} alt="Printer Icon" size='tiny' style={{ marginRight: '8px', height: '2em' }} />
                                                </Button>
                                            </Grid.Row>
                                        </Grid.Column>
                                        <Grid.Column width={12} style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'left', display: 'inline-grid', alignContent: 'center' }}>
                                            <Grid.Row style={{ padding: 0, fontWeight: '700', fontSize: '1.5em !important' }}>
                                                :Printing Device
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
                                                <span style={{ color: 'grey' }}>{image.computer ? image.computer : 'abc'}</span>
                                                <span style={{}}> -Computer Name </span>
                                                <Icon name="tv" style={{ marginRight: '8px' }}>
                                                </Icon>
                                            </Grid.Row>

                                            <Grid.Row>
                                                <span style={{ color: 'grey' }}>{image.computerID ? image.computerID : '12123'}</span>
                                                <span style={{ marginLeft: '8px' }}>-Computer ID</span>
                                            </Grid.Row>



                                            <Grid.Row style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'left' }} >

                                                <span style={{ color: 'grey' }}>{image.printingDevice ? image.printingDevice : 'abc'}</span>
                                                <span style={{}}>-Printer Name </span>

                                                <Image src={printerIcon} style={{ width: '1.2em', height: '1.2em', marginRight: '8px' }} />
                                            </Grid.Row>

                                            <Grid.Row>
                                                <span style={{ color: 'grey' }}>{image.printingDeviceID ? image.printingDeviceID : '123'}</span>
                                                <span style={{ marginLeft: '8px' }}>-Printer ID</span>
                                            </Grid.Row>

                                            <Grid.Row style={{ opacity: 0 }}>
                                                invisible
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, fontWeight: '700', fontSize: '1.5em !important' }}>

                                                :Printing Time
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, color: 'grey' }}>
                                                {image.printingTime ? convertUtcToLocal(image.printingTime)
                                                    : '12/12/12 12:12:12'}
                                            </Grid.Row>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column style={{ textAlign: 'center' }} width={3} >
                                <Segment style={{ padding: 0 }}>
                                    <Grid.Row style={{ height: '25vh', display: 'inline-grid', alignContent: 'center', justifyContent: 'center', fontSize: "2em", fontWeight: '700' }}>
                                        {image.imagePosition.x + 1}/{image.imagePosition.y + 1}
                                    </Grid.Row>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column key={i} style={{ textAlign: 'left', overflowWrap: 'anywhere' }} width={3}>
                                <img src={`data:image/jpeg;base64,${image.generatedImageData}`} alt="Description"
                                    style={{
                                        // border: isSelected ? '5px solid blue' : '5px solid transparent',
                                        display: 'inline-block',
                                        height: '25vh'
                                    }} />
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )
            }
        }
        return rows;
    }

    // const fetchPrinterLists = async () => {
    //     try {
    //         const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/api/printnode/printers");

    //         // Transform the response data to match the desired format for printerOptions
    //         const transformedPrinters = res.data.map(printer => ({
    //             key: printer.id.toString(),   // Convert ID to string for the key
    //             value: printer.id.toString(), // Convert ID to string for the value
    //             text: printer.name           // Use the printer name as the text
    //         }));

    //         setPrinterOptions(transformedPrinters);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    const fetchPrinterLists = async () => {
        try {
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/api/printnode/printers");
    
            // Transform the response data to match the desired format for printerOptions
            const transformedPrinters = res.data.map(printer => ({
                key: printer.id.toString(),   // Convert ID to string for the key
                value: printer.id.toString(), // Convert ID to string for the value
                text: `${printer.name} (ID: ${printer.id},State: ${printer.state}, Computer: ${printer.computer.name})` // Include printer ID, computer name, and state
            }));
    
            setPrinterOptions(transformedPrinters);
        } catch (error) {
            console.error(error);
        }
    }
    


    const fetchRecent8PrintedImages = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/recent8PrintedImages", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPrintedImageInput(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchRecent8Images = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/recent8Images", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setImageInput(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }


    const fetchAllImages = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/allImages", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setImageInput(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchAllPrintedImages = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/allPrintedImages", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPrintedImageInput(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }


    const fetchPostsAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countPosts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPostsTotalAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchFinishedAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countFinishedPrints", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPrintingFinishedAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchFailedAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countFailedPrints", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPrintingFailedAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchQueuedAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countQueuedPrints", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPrintingQueuedAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchEventDashboard = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/event-dashboard", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });

            setStartDate(res.data.startDate);
            setEndDate(res.data.endDate);
            setTimezone(res.data.timezone)
            setEventLocation(res.data.eventLocation)
            setEndUsers(res.data.endUsers);

            // setWordCloudCustomBackgroundName(res.data.wordCloudCustomBackground);

            //load draggable elements array
            setDraggableElementArray(res.data.dataCaptureList);

            setTextBlockCount(res.data.textBlockCount);
            setEmailCount(res.data.emailCount);
            setPhoneNumberCount(res.data.phoneNumberCount);
            setNumberCount(res.data.numberCount);
            setCheckBoxCount(res.data.checkBoxCount);
            setDropdownCount(res.data.dropdownCount);
            setUrlCheckboxCount(res.data.urlCheckboxCount);

            setIsTextBlockRequired(res.data.isTextBlockRequired);
            setIsEmailRequired(res.data.isEmailRequired);
            setIsPhoneNumberRequired(res.data.isPhoneNumberRequired);
            setIsNumberRequired(res.data.isNumberRequired);
            setIsCheckBoxRequired(res.data.isCheckBoxRequired);
            setIsDropdownRequired(res.data.isDropdownRequired)
            setIsURLCheckboxRequired(res.data.isURLCheckboxRequired)
            setIsTextBlockRequired1(res.data.isTextBlockRequired1)
            setIsTextBlockRequired2(res.data.isTextBlockRequired2)
            setIsCheckBoxRequired1(res.data.isCheckBoxRequired1)
            setIsCheckBoxRequired2(res.data.isCheckBoxRequired2)

            setIsDropdownRequired1(res.data.isDropdownRequired1)
            setIsDropdownRequired2(res.data.isDropdownRequired2)
            setIsURLCheckboxRequired1(res.data.isURLCheckboxRequired1)
            setIsURLCheckboxRequired2(res.data.isURLCheckboxRequired2)

            setTextBlockTitle(res.data.textBlockTitle)
            setTextBlockTitle1(res.data.textBlockTitle1)
            setTextBlockTitle2(res.data.textBlockTitle2)
            setEmailTitle(res.data.emailTitle)
            setPhoneNumberTitle(res.data.phoneNumberTitle)
            setNumberTitle(res.data.numberTitle)
            setCheckBoxTitle(res.data.checkBoxTitle)
            setCheckBoxTitle1(res.data.checkBoxTitle1)
            setCheckBoxTitle2(res.data.checkBoxTitle2)
            setDropdownTitle(res.data.dropdownTitle)
            setDropdownTitle1(res.data.dropdownTitle1)
            setDropdownTitle2(res.data.dropdownTitle2)
            setUrlCheckboxTitle(res.data.urlCheckboxTitle)
            setUrlCheckboxTitle1(res.data.urlCheckboxTitle1)
            setUrlCheckboxTitle2(res.data.urlCheckboxTitle2)

            setOptions0(res.data.dropdownOptions0)
            setOptions1(res.data.dropdownOptions1)
            setOptions2(res.data.dropdownOptions2)

            // setImageInput(res.data.imageInput)
            setJobId(res.data.jobId)
            setMosaicOverlayImageID(res.data.mosaicOverlayImageID);
            setMosaicOverlayImageOpacity(res.data.mosaicOverlayImageOpacity)
            setOverLayImageName(res.data.mosaicOverlayImageName)
            setMosaicBackgroundImageID(res.data.mosaicBackgroundImageID)
            setMosaicBackgroundImageOpacity(res.data.mosaicBackgroundImageOpacity)
            setBackgroundImageName(res.data.mosaicBackgroundImageName)

            setLoginTextColor(res.data.loginTextColor)
            setLoginButtonColor(res.data.loginButtonColor)

            setUploaderButton(res.data.uploaderButton)
            setPostModerationButton(res.data.postModerationButton)

            setMosaicSize(res.data.mosaicSize)
            // setPostsTotalAmount(res.data.postsTotalAmount)
            // setPrintingFinishedAmount(res.data.printingFinishedAmount)
            // setPrintingFailedAmount(res.data.printingFailedAmount)
            // setPrintingQueuedAmount(res.data.printingQueuedAmount)
            setModerationApprovedAmount(res.data.moderationApprovedAmount);
            setModerationRejectedAmount(res.data.moderationRejectedAmount);
            setModerationPendingAmount(res.data.moderationPendingAmount);
            setModerationOverflowAmount(res.data.moderationOverflowAmount)

            setPrinterSelected(res.data.printerSelected)

            setMosaicLogoID(res.data.mosaicLogoID)
            setMosaicLogoName(res.data.mosaicLogoName)

            setMosaicButtonFillColor(res.data.mosaicButtonFillColor)

            setPossiblePositionsArray(res.data.possiblePositionsArray)

        } catch (error) {
            console.error(error);
        }
    }


    const fetchApprovedAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countApprovedPosts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setModerationApprovedAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchRejectedAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countRejectedPosts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setModerationRejectedAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchPendingAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countPendingPosts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setModerationPendingAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    function jsonToCSV(jsonArray) {
        // Create a set to store unique keys
        const keysSet = new Set();

        // Fill the set with keys from all objects
        jsonArray.forEach(obj => Object.keys(obj).forEach(key => keysSet.add(key)));

        // Convert the set to an array to get the CSV header
        const header = Array.from(keysSet);

        // Create a map for faster access to key indexes
        const keyIndex = new Map(header.map((key, index) => [key, index]));

        // Convert the JSON array to a 2D array
        const csvArray = jsonArray.map(obj => {
            // Initialize an array filled with empty strings
            const row = Array(header.length).fill('');

            // Fill the array with values from the object
            Object.keys(obj).forEach(key => {
                const index = keyIndex.get(key);
                row[index] = JSON.stringify(obj[key]);
            });

            return row;
        });

        // Add the header to the beginning of the 2D array
        csvArray.unshift(header);

        // Convert the 2D array to a CSV string
        const csvString = csvArray.map(row => row.join(',')).join('\r\n');

        return csvString;
    }

    const downloadEndUsersData = async () => {
        // Fetch endUsers data from your backend
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/event-dashboard-endusers", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });

            const endUsersData = response.data;
            // Convert endUsers data to CSV format
            const csvContent = jsonToCSV(endUsersData);
            // Create a download link and trigger the download
            const downloadLink = document.createElement('a');
            const fileBlob = new Blob([csvContent], { type: 'text/csv' });
            const fileURL = URL.createObjectURL(fileBlob);

            downloadLink.href = fileURL;
            downloadLink.download = location.state.eventName + '_' + startDate + '_' + endDate + '.csv';
            downloadLink.click();

            // Clean up the URL object
            URL.revokeObjectURL(fileURL);
        }
        catch (error) {
            console.error(error);
        }
    }

    const handleSaveButton = () => {
        updatingDashboard();
    }

    const updatingDashboard = async () => {
        try {
            const token = localStorage.getItem("token");

            const headers = {
                'Custom-Header': 'CustomHeaderValue',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            };

            const data = {
                eventName: location.state.eventName, // replace with the name you want to search for

                dataCaptureList: draggableItems,
                textBlockCount: textBlockCount,
                emailCount: emailCount,
                phoneNumberCount: phoneNumberCount,
                numberCount: numberCount,
                checkBoxCount: checkBoxCount,
                dropdownCount: dropdownCount,
                urlCheckboxCount: urlCheckboxCount,
                isTextBlockRequired: isTextBlockRequired,
                isEmailRequired: isEmailRequired,
                isPhoneNumberRequired: isPhoneNumberRequired,
                isNumberRequired: isNumberRequired,
                isCheckBoxRequired: isCheckBoxRequired,
                isDropdownRequired: isDropdownRequired,
                isURLCheckboxRequired: isCheckBoxRequired,

                isTextBlockRequired1: isTextBlockRequired1,
                isTextBlockRequired2: isTextBlockRequired2,

                isCheckBoxRequired1: isCheckBoxRequired1,
                isCheckBoxRequired2: isCheckBoxRequired2,

                isDropdownRequired1: isDropdownRequired1,
                isDropdownRequired2: isDropdownRequired2,

                isURLCheckboxRequired1: isURLCheckboxRequired1,
                isURLCheckboxRequired2: isURLCheckboxRequired2,

                textBlockTitle: textBlockTitle,
                textBlockTitle1: textBlockTitle1,
                textBlockTitle2: textBlockTitle2,
                emailTitle: emailTitle,
                phoneNumberTitle: phoneNumberTitle,
                numberTitle: numberTitle,
                checkBoxTitle: checkBoxTitle,
                checkBoxTitle1: checkBoxTitle1,
                checkBoxTitle2: checkBoxTitle2,
                dropdownTitle: dropdownTitle,
                dropdownTitle1: dropdownTitle1,
                dropdownTitle2: dropdownTitle2,
                urlCheckboxTitle: urlCheckboxTitle,
                urlCheckboxTitle1: urlCheckboxTitle1,
                urlCheckboxTitle2: urlCheckboxTitle2,

                dropdownOptions0: options0,
                dropdownOptions1: options1,
                dropdownOptions2: options2,

                // imageInput: imageInput,

                mosaicOverlayImageID: mosaicOverlayImageID,
                mosaicOverlayImageOpacity: mosaicOverlayImageOpacity,
                mosaicOverlayImageName: overlayImageName,
                mosaicBackgroundImageID: mosaicBackgroundImageID,
                mosaicBackgroundImageOpacity: mosaicBackgroundImageOpacity,
                mosaicBackgroundImageName: backgroundImageName,
                // mosaicOverlayImageData: mosaicOverlayImageData,
                // mosaicBackgroundImageData:mosaicBackgroundImageData,
                mosaicSize: mosaicSize,

                mosaicLogoID: mosaicLogoID,
                mosaicLogoName: mosaicLogoName,
                mosaicButtonFillColor: mosaicButtonFillColor,

                loginTextColor: loginTextColor,
                loginButtonColor: loginButtonColor,

                uploaderButton: uploaderButton,
                postModerationButton: postModerationButton,

                // postsTotalAmount: postsTotalAmount,
                // printingFinishedAmount: printingFinishedAmount,
                // printingFailedAmount: printingFailedAmount,
                // printingQueuedAmount: printingQueuedAmount,
                // moderationApprovedAmount: moderationApprovedAmount,
                // moderationRejectedAmount: moderationRejectedAmount,
                // moderationPendingAmount: moderationPendingAmount,
                // moderationOverflowAmount: moderationOverflowAmount,

                printerSelected: printerSelected,

                possiblePositionsArray: possiblePositionsArray,

            };
            const response = await axios.put('https://wordwallrebuild.herokuapp.com/mosaicDashboard/updateDashboard', data, { headers: headers });


            //show success message sweet alert
            // res.json({message:"success update"});
            if (response.status == 200) {

                Swal.fire('Success', 'Update successful', 'success');
            } else {
                Swal.fire('Error', 'Something went wrong while saving, please try again after re-login', 'error');
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleEventDateChange = () => {
        Swal.fire({
            title: 'Redirect to Event Update page',
            text: 'Please cancel and Click Save before redirecting to another Page, otherwise progress might be lost. If you have already saved progress, click Continue',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                // Redirect the user to the Login page
                navigate("/MosaicUpdateEventPage", { state: { eventName: location.state.eventName } });
            }
        });
    }

    const handlePreviewButton = () => {
        Swal.fire({
            title: 'Redirect to Live Link',
            text: 'Please cancel and Click Save before redirecting Event Page, otherwise progress might be lost. If you have already saved progress, click Continue',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
        }).then((result) => {

            Swal.fire({
                title: 'Screen Size is Crucial!',
                text: 'Note that screen size is crucial for previewing, a mosaic with 24 * 18 will NOT layout correctly on a screen of eg: 1600*900 or 1440*900, please change browser size to 800 * 600 for better view if mosaic size is 24*18',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    // Redirect the user to the Login page
                    // navigate("/Mosaic/LiveMosaicLoginPage" + jobId, { state: { eventName: location.state.eventName } });
                    navigate("/Mosaic/LiveDigitalMosaic", { state: { eventName: location.state.eventName } });
    
                    // window.location.href = 'https://wordwallrebuild.herokuapp.com';
                }
            });

        });
    };

    const handleMosaicOverlayImageOpacityChange = (event, newValue) => {
        setMosaicOverlayImageOpacity(newValue)
    }

    const handleMosaicBackgroundImageOpacityChange = (event, newValue) => {
        setMosaicBackgroundImageOpacity(newValue)
    }

    return (
        <div >
            <Grid style={{}}>
                <Grid.Column width={4} style={{ textAlign: "center", paddingRight: 0, paddingBottom: 0 }}>
                    <Grid.Row style={{ marginBottom: "10vh", marginTop: "5vh", fontSize: "30px" }}>
                        Polite Playground.
                    </Grid.Row>

                    <Button onClick={() => handleTabClick('Dashboard')} size="massive" fluid icon labelPosition="left"
                        className={activeTab === "Dashboard" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}
                    >
                        <Icon name="dashboard" />
                        Dashboard
                    </Button>
                    <Button onClick={() => handleTabClick('Data Capture')} size="massive" fluid icon labelPosition="left"
                        className={activeTab === "Data Capture" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}

                    >
                        <Icon name="database" />
                        Data Capture
                    </Button>
                    <Button onClick={() => handleTabClick('Mosaic Settings')} size="massive" fluid icon labelPosition="left"
                        className={activeTab === "Mosaic Settings" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}

                    >
                        <Icon name="tablet alternate" />
                        Mosaic Settings
                    </Button>
                    <Button onClick={() => handleTabClick('Printer Settings')} size="massive" fluid icon labelPosition="left"
                        className={activeTab === "Printer Settings" ? "custom-nav-bar-button active" : "custom-nav-bar-button"}

                    >
                        <Icon name="tv" />
                        Printer Settings
                    </Button>
                </Grid.Column>
                <Grid.Column
                    width={12}
                    style={{
                        textAlign: "center",
                        paddingLeft: 0,
                        paddingBottom: 0,
                        backgroundColor: "#EAEAF1",
                    }}
                >
                    {activeTab === 'Dashboard' && (
                        <div >
                            <Grid.Row style={{ textAlign: "right", marginRight: "3vw", marginTop: "1vh" }}>
                                <Dropdown
                                    text='Menu'
                                    icon="bars"
                                    floating
                                    labeled
                                    inverted
                                    button
                                    className="icon"
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item text="Mosaic Events" onClick={() => {
                                            navigate("/MosaicEventListPage", { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Products" onClick={() => {
                                            navigate('/ProductsPage', { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Log Out" onClick={() => { navigate('/') }} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Grid.Row>
                            <Grid.Row style={{ marginLeft: "4vw", textAlign: "left", marginBottom: "2vh", fontSize: "28px", fontWeight: "bold" }} >
                                Dashboard
                            </Grid.Row>

                            <Grid style={{ overflow: 'auto' }}>
                                <Grid.Row  >
                                    <Grid.Column width={5} style={{ paddingLeft: "3vw" }}>
                                        <Segment className="custom-wordcloud-inside-segment" style={{ height: "35vh", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Grid.Row style={{ textAlign: "center", fontSize: "20px", marginTop: "2vh", fontWeight: "bold" }}>
                                                            Unique Entries
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column style={{ alignSelf: "center", textAlign: "center" }}>
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column width={6}>
                                                                </Grid.Column>
                                                                <Grid.Column width={4} style={{ textAlign: "center", backgroundColor: "#EAEAF1", fontSize: "28px" }}>
                                                                    <Grid.Row style={{ textAlign: "center", fontSize: "28px", display: "flex", justifyContent: "center", alignItems: "center", height: "5vh" }}>
                                                                        {endUsers.length}
                                                                    </Grid.Row>
                                                                </Grid.Column>
                                                                <Grid.Column width={6}>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Grid.Row style={{ textAlign: "center" }}>
                                                            <Button size="large" onClick={downloadEndUsersData} className="custom-wordcloud-inside-segment-button" style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>Download</Button>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column width={11} style={{ paddingRight: "3vw" }}>
                                        <Segment className="custom-wordcloud-inside-segment" style={{ height: "35vh" }}>
                                            <Grid>
                                                <Grid.Row columns={3} divided={false} style={{ paddingTop: 0, marginTop: "1vh" }}>
                                                    <Grid.Column width={8}>
                                                        <Grid.Row style={{ textAlign: "left", paddingBottom: 0, fontSize: "20px", fontWeight: "bold", marginLeft: "3vw" }}>
                                                            Event Name:
                                                        </Grid.Row>
                                                        <Grid.Row style={{ marginLeft: "2vw" }}>
                                                            <Grid.Column>
                                                                <Segment className="custom-wordcloud-inside-segment" style={{ paddingTop: "7px", paddingBottom: "7px", textAlign: "left", background: "#F2F2F7", marginTop: "14px" }}>
                                                                    <Grid.Row>
                                                                        {location.state.eventName}
                                                                    </Grid.Row>
                                                                </Segment>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                    <Grid.Column width={4}>
                                                        <Grid.Row style={{ textAlign: "left", paddingBottom: 0, fontSize: "20px", fontWeight: "bold", marginLeft: "1vw" }}>
                                                            Location:
                                                        </Grid.Row>
                                                        <Grid.Row style={{}}>
                                                            <Grid.Column>
                                                                <Segment className="custom-wordcloud-inside-segment" style={{ paddingTop: "7px", paddingBottom: "7px", textAlign: "left", background: "#F2F2F7", marginTop: "14px" }}>
                                                                    <Grid.Row>
                                                                        {eventLocation}
                                                                    </Grid.Row>
                                                                </Segment>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                    <Grid.Column width={4}>
                                                        <Grid.Row style={{ textAlign: "left", paddingBottom: 0, fontSize: "20px", fontWeight: "bold", marginLeft: "1vw" }}>
                                                            Timezone:
                                                        </Grid.Row>
                                                        <Grid.Row style={{}}>
                                                            <Grid.Column>
                                                                <Segment className="custom-wordcloud-inside-segment" style={{ paddingTop: "7px", paddingBottom: "7px", textAlign: "left", background: "#F2F2F7", marginTop: "14px" }}>
                                                                    <Grid.Row>
                                                                        {timezone}
                                                                    </Grid.Row>
                                                                </Segment>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row columns={2} divided={false} style={{ paddingTop: 0, marginLeft: "2vw" }}>
                                                    <Grid.Column >
                                                        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0, textAlign: "left", marginLeft: "1vw", fontSize: "20px", fontWeight: "bold" }}>
                                                            Start Date:
                                                        </Grid.Row>
                                                        <Segment className="custom-wordcloud-inside-segment" style={{ paddingTop: "7px", paddingBottom: "7px", textAlign: "left", background: "#F2F2F7" }}>
                                                            <Grid.Row>
                                                                {startDate}
                                                            </Grid.Row>
                                                        </Segment>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0, textAlign: "left", marginLeft: "1vw", fontSize: "20px", fontWeight: "bold" }}>
                                                            End Date:
                                                        </Grid.Row>
                                                        <Segment className="custom-wordcloud-inside-segment" style={{ paddingTop: "7px", paddingBottom: "7px", textAlign: "left", background: "#F2F2F7" }}>
                                                            <Grid.Row>
                                                                {endDate}
                                                            </Grid.Row>
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row style={{ paddingTop: 0 }}>
                                                    <Grid.Column>
                                                        <Grid.Row style={{ textAlign: "center" }}>
                                                            <Button size="large" onClick={handleEventDateChange} className="custom-wordcloud-inside-segment-button" style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>Edit</Button>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row style={{ paddingTop: 0 }}>
                                    <Grid.Column style={{ paddingLeft: "3vw", paddingRight: "3vw" }}>

                                        <Segment className="custom-wordcloud-inside-segment">
                                            <Grid.Row style={{ textAlign: 'left', marginTop: '1vh', marginBottom: '1vh', fontSize: "24px", fontWeight: "bold" }}>
                                                Moderation
                                            </Grid.Row>
                                            <Grid>

                                                <Grid.Column >
                                                    <Grid.Row>
                                                        <Grid>
                                                            <Grid.Row columns={3} relaxed>
                                                                <Grid.Column width={8} textAlign="left" style={{ paddingLeft: "28px", display: 'flex', justifyContent: 'left', alignItems: 'center', fontSize: "16px", fontWeight: "bold" }} >
                                                                    Entries

                                                                </Grid.Column>
                                                                {/* <Grid.Column width={2} textAlign="center" >
                                                                    <Checkbox
                                                                        label="Show All"
                                                                        onChange={handleShowAllEntries}
                                                                        checked={showAllEntriesChecked}
                                                                    />
                                                                </Grid.Column> */}
                                                                <Grid.Column width={3} textAlign="center" >
                                                                    <Button className="custom-wordcloud-button-general" onClick={handleSelectAll} >
                                                                        Select All Entries
                                                                    </Button>
                                                                </Grid.Column>
                                                                <Grid.Column width={3}>
                                                                    <Button textAlign="center" className="custom-wordcloud-button-general" onClick={handleDeleteSelected} >
                                                                        Delete Selected
                                                                    </Button>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Row>

                                                    <Segment className='custom-wordcloud-inside-segment-segment' style={{ background: "#F2F2F7" }}>
                                                        <Grid>

                                                            <Grid.Column style={{ overflow: 'auto', maxHeight: '80vh', direction: 'rtl', scrollbarWidth: 'thin', scrollbarColor: '#695fca #dbdaee' }}>
                                                                <div style={{ direction: 'ltr', width: '100%', textAlign: 'left' }}>
                                                                    <Grid>
                                                                        {showImages()}
                                                                    </Grid>
                                                                </div>
                                                            </Grid.Column>
                                                        </Grid>
                                                    </Segment>

                                                </Grid.Column>
                                            </Grid>
                                        </Segment>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    )}

                    {activeTab === 'Data Capture' && (
                        <div>
                            <Grid.Row style={{ textAlign: "right", marginRight: "3vw", marginTop: "1vh" }}>
                                <Dropdown
                                    text='Menu'
                                    icon="bars"
                                    floating
                                    labeled
                                    inverted
                                    button
                                    className="icon"
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item text="Mosaic Events" onClick={() => {
                                            navigate("/MosaicEventListPage", { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Products" onClick={() => {
                                            navigate('/ProductsPage', { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Log Out" onClick={() => { navigate('/') }} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Grid.Row>
                            <Grid.Row style={{ marginLeft: "4vw", textAlign: "left", marginBottom: "2vh", fontSize: "28px", fontWeight: "bold" }} >
                                Data Capture
                            </Grid.Row>

                            {/* <Divider className="custom-wordcloud-right-divider" /> */}

                            <Grid>

                                <Grid.Row columns={2} divided={false}>
                                    <Grid.Column width={6} style={{ paddingLeft: "3vw", marginTop: "14px" }} >
                                        <Segment className="custom-wordcloud-inside-segment" style={{ position: 'sticky', top: 0 }}>
                                            <Grid>
                                                <Grid.Row style={{ marginLeft: "28px", marginTop: "14px", fontSize: "20px", fontWeight: "bold" }} >
                                                    Add Cell
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='text width' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Text Block
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addTextBlock} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='mail' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Email
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addEmail} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='mobile alternate' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Phone Number
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addPhoneNumber} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='phone' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Number
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle">
                                                        <Button tiny icon onClick={addNumber} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='check square outline' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Check Box
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addCheckBox} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='dropdown' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        Dropdown
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addDropdown} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={3} className="addcell-segment-style" style={{
                                                    paddingBottom: "4px", paddingTop: "4px",
                                                    marginBottom: "0px", marginTop: "14px", marginLeft: "14px", marginRight: "14px", marginBottom: "14px", borderRadius: "28px"
                                                }}>
                                                    <Grid.Column width={3} verticalAlign="middle" style={{ color: "rgb(255,255,255)" }}>
                                                        <Icon name='linkify' />
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={{ textAlign: "left", color: "rgb(255,255,255)" }} verticalAlign="middle">
                                                        URL Checkbox
                                                    </Grid.Column>
                                                    <Grid.Column width={5} verticalAlign="middle" >
                                                        <Button tiny icon onClick={addURLCheckbox} style={{ color: "#fff", background: "rgba(255,255,255,0)" }}>
                                                            <Icon name='plus' />
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column width={10} style={{ paddingRight: "2vw", overflow: 'auto' }}>
                                        <DragDropContext onDragEnd={handleOnDragEnd}>
                                            <Droppable droppableId="draggableItems">
                                                {(provided) => (
                                                    <ul className="draggableItems" {...provided.droppableProps} ref={provided.innerRef}>
                                                        {draggableItems.map((item, index) => {
                                                            return (
                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                    {(provided) => (
                                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                            {renderItem(item)}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </ul>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    )}

                    {activeTab === 'Mosaic Settings' && (
                        <div>
                            <Grid.Row style={{ textAlign: "right", marginRight: "3vw", marginTop: "1vh" }}>
                                <Dropdown
                                    text='Menu'
                                    icon="bars"
                                    floating
                                    labeled
                                    inverted
                                    button
                                    className="icon"
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item text="Mosaic Events" onClick={() => {
                                            navigate("/MosaicEventListPage", { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Products" onClick={() => {
                                            navigate('/ProductsPage', { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                        }} />
                                        <Dropdown.Item text="Log Out" onClick={() => { navigate('/') }} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Grid.Row>
                            <Grid.Row style={{ marginLeft: "4vw", textAlign: "left", marginBottom: "2vh", fontSize: "28px", fontWeight: "bold" }} >
                                Mosaic Settings
                            </Grid.Row>

                            <Grid verticalAlign="middle" centered>
                                <Grid.Row style={{ overflow: 'auto' }}>

                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Segment className="custom-wordcloud-inside-segment">
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                        Mosaic Size
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row >
                                                    <Grid.Column >
                                                        <Dropdown
                                                            selection
                                                            options={mosaicSizeOptions}
                                                            value={mosaicSize}
                                                            onChange={handleMosaicSizeChange}
                                                            placeholder='Select Mosaic Size' style={{
                                                                textAlign: 'center',
                                                                backgroundColor: '#f2f2f7'
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                                    Overlay Image
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                                <Grid.Column width={6} >
                                                                    <Button as="label" htmlFor="file-overlay-image" type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                                        <Icon name="file image outline" />
                                                                        Choose File
                                                                    </Button>
                                                                    <input
                                                                        type="file"
                                                                        id="file-overlay-image"
                                                                        hidden
                                                                        onChange={handleOverlayImageChange}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={10} style={{ textAlign: "left" }}>
                                                                    {overlayImageName == '' ? "No File Chosen" : overlayImageName}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                                    Background Image
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                                <Grid.Column width={6} >
                                                                    <Button as="label" htmlFor="file-background-image" type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                                        <Icon name="file image outline" />
                                                                        Choose File
                                                                    </Button>
                                                                    <input
                                                                        type="file"
                                                                        id="file-background-image"
                                                                        hidden
                                                                        onChange={handleBackgroundImageChange}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={10} style={{ textAlign: "left" }}>
                                                                    {backgroundImageName == '' ? "No File Chosen" : backgroundImageName}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>

                                            </Grid.Row>
                                        </Grid>

                                    </Grid.Column>

                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                                    Overlay Image Opacity
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                                <Slider
                                                                    value={mosaicOverlayImageOpacity}
                                                                    onChange={handleMosaicOverlayImageOpacityChange}
                                                                    step={1}
                                                                    valueLabelDisplay="auto"
                                                                    getAriaValueText={overlayImageSliderText}
                                                                    marks={marks}
                                                                    style={{ marginLeft: '2vw', marginRight: '2vw', color: '#D5D5D5' }}
                                                                />
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                                    Background Image Opacity
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                                <Slider
                                                                    value={mosaicBackgroundImageOpacity}
                                                                    onChange={handleMosaicBackgroundImageOpacityChange}
                                                                    step={1}
                                                                    valueLabelDisplay="auto"
                                                                    getAriaValueText={backgroundImageSliderText}
                                                                    marks={marks}
                                                                    style={{ marginLeft: '2vw', marginRight: '2vw', color: '#D5D5D5' }}

                                                                />
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>

                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid.Row style={{ fontSize: "20px", fontWeight: "bold", marginBottom: '2vh' }}>
                                                            Upload Button Fill Color
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid style={{ textAlign: "left" }}>
                                                                <Grid.Row columns={3} divided={false}>
                                                                    <Grid.Column>
                                                                        <Input
                                                                            value={mosaicButtonFillColor}
                                                                            onChange={handleButtonFillColorChangeFromText}
                                                                            size='big'
                                                                            fluid
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <div className="myswatch" onClick={handleButtonFillColorOnClick} style={{ background: mosaicButtonFillColor }}>
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>

                                                            {displayButtonFillColorPicker ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={handleButtonFillColorClose} />
                                                                    <ChromePicker
                                                                        color={mosaicButtonFillColor}
                                                                        onChange={handleButtonFillColorChange}
                                                                        presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                        format='hex' />
                                                                </div> : null}

                                                        </Grid.Row>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                                    Login Logo Image
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                                <Grid.Column width={6} >
                                                                    <Button as="label" htmlFor="file-login-logo-image" type="button" tiny icon style={{ color: "#fff", background: "#695fca", borderRadius: "20px" }}>
                                                                        <Icon name="file image outline" />
                                                                        Choose File
                                                                    </Button>
                                                                    <input
                                                                        type="file"
                                                                        id="file-login-logo-image"
                                                                        hidden
                                                                        onChange={handleMosaicLogoImageChange}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={10} style={{ textAlign: "left" }}>
                                                                    {mosaicLogoName == '' ? "No File Chosen" : mosaicLogoName}
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column>


                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>

                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Grid>
                                            <Grid.Row columns={2}>

                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid.Row style={{ fontSize: "20px", fontWeight: "bold", marginBottom: '2vh' }}>
                                                            Login Text Color
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid style={{ textAlign: "left" }}>
                                                                <Grid.Row columns={3} divided={false}>
                                                                    <Grid.Column>
                                                                        <Input
                                                                            value={loginTextColor}
                                                                            onChange={handleLoginTextColorChangeFromText}
                                                                            size='big'
                                                                            fluid
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <div className="myswatch" onClick={handleLoginTextColorOnClick} style={{ background: loginTextColor }}>
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>

                                                            {displayLoginTextColorPicker ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={handleLoginTextColorClose} />
                                                                    <ChromePicker
                                                                        color={loginTextColor}
                                                                        onChange={handleLoginTextColorChange}
                                                                        presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                        format='hex' />
                                                                </div> : null}

                                                        </Grid.Row>
                                                    </Segment>
                                                </Grid.Column>

                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid.Row style={{ fontSize: "20px", fontWeight: "bold", marginBottom: '2vh' }}>
                                                            Login Button Color
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid style={{ textAlign: "left" }}>
                                                                <Grid.Row columns={3} divided={false}>
                                                                    <Grid.Column>
                                                                        <Input
                                                                            value={loginButtonColor}
                                                                            onChange={handleLoginButtonColorChangeFromText}
                                                                            size='big'
                                                                            fluid
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <div className="myswatch" onClick={handleLoginButtonColorOnClick} style={{ background: loginButtonColor }}>
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>

                                                            {displayLoginButtonColorPicker ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={handleLoginButtonColorClose} />
                                                                    <ChromePicker
                                                                        color={loginButtonColor}
                                                                        onChange={handleLoginButtonColorChange}
                                                                        presetColors={['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff']}
                                                                        format='hex' />
                                                                </div> : null}
                                                        </Grid.Row>
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                    <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                        <Grid>
                                            <Grid.Row columns={2}>

                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                                    Uploader Button
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row >
                                                                <Grid.Column >
                                                                    <Dropdown
                                                                        selection
                                                                        options={uploaderButtonOptions}
                                                                        value={uploaderButton}
                                                                        onChange={handleUploaderButtonChange}
                                                                        placeholder='Select' style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#f2f2f7'
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column >

                                                <Grid.Column width={8}>
                                                    <Segment className="custom-wordcloud-inside-segment">
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                                    Post Moderation
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row >
                                                                <Grid.Column >
                                                                    <Dropdown
                                                                        selection
                                                                        options={postModerationOptions}
                                                                        value={postModerationButton}
                                                                        onChange={handlePostModerationButtonChange}
                                                                        placeholder='Select' style={{
                                                                            textAlign: 'center',
                                                                            backgroundColor: '#f2f2f7'
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>
                                                </Grid.Column >
                                            </Grid.Row >
                                        </Grid>
                                    </Grid.Column>

                                    <Grid.Column width={15} style={{ height: '20vh' }}>
                                        <Segment className="custom-wordcloud-inside-segment" style={{ opacity: 0 }}>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                        Invisible
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row style={{ display: "flex", alignItems: "center" }}>
                                                    <Grid.Column width={8} style={{ textAlign: "left" }} >
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>
                        </div>
                    )
                    }

                    {
                        activeTab === 'Printer Settings' && (
                            <div>
                                <Grid.Row style={{ textAlign: "right", marginRight: "3vw", marginTop: "1vh" }}>
                                    <Dropdown
                                        text='Menu'
                                        icon="bars"
                                        floating
                                        labeled
                                        inverted
                                        button
                                        className="icon"
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Item text="Mosaic Events" onClick={() => {
                                                navigate("/MosaicEventListPage", { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                            }} />
                                            <Dropdown.Item text="Products" onClick={() => {
                                                navigate('/ProductsPage', { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                            }} />
                                            <Dropdown.Item text="Log Out" onClick={() => { navigate('/') }} />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Grid.Row>
                                <Grid.Row style={{ marginLeft: "4vw", textAlign: "left", marginBottom: "2vh", fontSize: "28px", fontWeight: "bold" }} >
                                    Printer Settings
                                </Grid.Row>
                                <Grid verticalAlign="middle" centered>
                                    <Grid.Row style={{ overflow: 'auto' }}>

                                        <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                            <Grid>
                                                <Grid.Row columns={2}>
                                                    <Grid.Column width={5} style={{}}>
                                                        <Segment className="custom-wordcloud-inside-segment" style={{ height: "20vh", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <Grid.Row style={{ textAlign: "center", fontSize: "20px", marginTop: "2vh", fontWeight: "bold" }}>
                                                                            Posts
                                                                        </Grid.Row>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column style={{ alignSelf: "center", textAlign: "center" }}>
                                                                        <Grid>
                                                                            <Grid.Row>
                                                                                <Grid.Column width={6}>
                                                                                </Grid.Column>
                                                                                <Grid.Column width={4} style={{ textAlign: "center", backgroundColor: "#EAEAF1", fontSize: "28px" }}>
                                                                                    <Grid.Row style={{ textAlign: "center", fontSize: "28px", display: "flex", justifyContent: "center", alignItems: "center", height: "5vh" }}>

                                                                                        {postsTotalAmount}
                                                                                    </Grid.Row>
                                                                                </Grid.Column>
                                                                                <Grid.Column width={6}>
                                                                                </Grid.Column>
                                                                            </Grid.Row>
                                                                        </Grid>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <Grid.Row style={{ textAlign: "center", fontSize: "20px" }}>
                                                                            Imported
                                                                        </Grid.Row>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Segment>
                                                    </Grid.Column>

                                                    <Grid.Column width={11}>
                                                        <Segment className="custom-wordcloud-inside-segment" style={{ height: "20vh", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                                                            <Grid>
                                                                <Grid.Row>
                                                                    <Grid.Column style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>
                                                                        Printed
                                                                    </Grid.Column>
                                                                </Grid.Row>


                                                                <Grid.Row>
                                                                    <Grid.Column width={5} style={{ alignSelf: "center", textAlign: "center" }}>
                                                                        <Grid.Row style={{ textAlign: "center" }}>
                                                                            <Grid>
                                                                                <Grid.Row>
                                                                                    <Grid.Column width={6}>
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={4} style={{
                                                                                        textAlign: "center", backgroundColor: "#EAEAF1", fontSize: "28px",
                                                                                        height: "5vh", display: "flex", justifyContent: "center", alignItems: "center"
                                                                                    }}>
                                                                                        {printingFinishedAmount}
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={6}>
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Grid.Row>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={6} style={{ alignSelf: "center", textAlign: "center" }}>
                                                                        <Grid.Row style={{ textAlign: "center" }}>
                                                                            <Grid>
                                                                                <Grid.Row>
                                                                                    <Grid.Column width={6}>
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={4} style={{
                                                                                        textAlign: "center", backgroundColor: "#EAEAF1", fontSize: "28px",
                                                                                        height: "5vh", display: "flex", justifyContent: "center", alignItems: "center"
                                                                                    }}>
                                                                                        {printingFailedAmount}
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={6}>
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Grid.Row>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={5} style={{ alignSelf: "center", textAlign: "center" }}>
                                                                        <Grid.Row style={{ textAlign: "center" }}>
                                                                            <Grid>
                                                                                <Grid.Row>
                                                                                    <Grid.Column width={6}>
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={4} style={{
                                                                                        textAlign: "center", backgroundColor: "#EAEAF1", fontSize: "28px",
                                                                                        height: "5vh", display: "flex", justifyContent: "center", alignItems: "center"
                                                                                    }}>
                                                                                        {printingQueuedAmount}
                                                                                    </Grid.Column>
                                                                                    <Grid.Column width={6}>
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Grid.Row>
                                                                    </Grid.Column>

                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column width={5} style={{ textAlign: "right" }}>
                                                                        <Grid.Row style={{ textAlign: "center", fontSize: "20px" }}>
                                                                            Finished
                                                                        </Grid.Row>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={6} style={{ textAlign: "center" }}>
                                                                        <Grid.Row style={{ textAlign: "center", fontSize: "20px" }}>
                                                                            Failed
                                                                        </Grid.Row>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={5} style={{ textAlign: "left" }}>
                                                                        <Grid.Row style={{ textAlign: "center", fontSize: "20px" }}>
                                                                            Queued
                                                                        </Grid.Row>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Segment>
                                                    </Grid.Column>

                                                </Grid.Row>
                                            </Grid>

                                        </Grid.Column>
                                        <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                            <Segment className="custom-wordcloud-inside-segment" style={{ height: "20vh", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>
                                                            Moderation
                                                        </Grid.Column>
                                                    </Grid.Row>


                                                    <Grid.Row>
                                                        <Grid.Column width={4} style={{ alignSelf: "center", textAlign: "center" }}>
                                                            <Grid.Row style={{ textAlign: "center" }}>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={6}>
                                                                        </Grid.Column>
                                                                        <Grid.Column width={4} style={{
                                                                            textAlign: "center", backgroundColor: "#EAEAF1", fontSize: "28px",
                                                                            height: "5vh", display: "flex", justifyContent: "center", alignItems: "center"
                                                                        }}>
                                                                            {moderationApprovedAmount}
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Grid.Row>
                                                        </Grid.Column>
                                                        <Grid.Column width={4} style={{ alignSelf: "center", textAlign: "center" }}>
                                                            <Grid.Row style={{ textAlign: "center" }}>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={6}>
                                                                        </Grid.Column>
                                                                        <Grid.Column width={4} style={{
                                                                            textAlign: "center", backgroundColor: "#EAEAF1", fontSize: "28px",
                                                                            height: "5vh", display: "flex", justifyContent: "center", alignItems: "center"
                                                                        }}>
                                                                            {moderationRejectedAmount}
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Grid.Row>
                                                        </Grid.Column>
                                                        <Grid.Column width={4} style={{ alignSelf: "center", textAlign: "center" }}>
                                                            <Grid.Row style={{ textAlign: "center" }}>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={6}>
                                                                        </Grid.Column>
                                                                        <Grid.Column width={4} style={{
                                                                            textAlign: "center", backgroundColor: "#EAEAF1", fontSize: "28px",
                                                                            height: "5vh", display: "flex", justifyContent: "center", alignItems: "center"
                                                                        }}>
                                                                            {moderationPendingAmount}
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Grid.Row>
                                                        </Grid.Column>
                                                        <Grid.Column width={4} style={{ alignSelf: "center", textAlign: "center" }}>
                                                            <Grid.Row style={{ textAlign: "center" }}>
                                                                <Grid>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={6}>
                                                                        </Grid.Column>
                                                                        <Grid.Column width={4} style={{
                                                                            textAlign: "center", backgroundColor: "#EAEAF1", fontSize: "28px",
                                                                            height: "5vh", display: "flex", justifyContent: "center", alignItems: "center"
                                                                        }}>
                                                                            {moderationOverflowAmount}
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Grid.Row>
                                                        </Grid.Column>

                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={4} style={{ textAlign: "center" }}>
                                                            <Grid.Row style={{ textAlign: "center", fontSize: "20px" }}>
                                                                Approved
                                                            </Grid.Row>
                                                        </Grid.Column>
                                                        <Grid.Column width={4} style={{ textAlign: "center" }}>
                                                            <Grid.Row style={{ textAlign: "center", fontSize: "20px" }}>
                                                                Rejected
                                                            </Grid.Row>
                                                        </Grid.Column>
                                                        <Grid.Column width={4} style={{ textAlign: "center" }}>
                                                            <Grid.Row style={{ textAlign: "center", fontSize: "20px" }}>
                                                                Pending
                                                            </Grid.Row>
                                                        </Grid.Column>
                                                        <Grid.Column width={4} style={{ textAlign: "center" }}>
                                                            <Grid.Row style={{ textAlign: "center", fontSize: "20px" }}>
                                                                Overflow
                                                            </Grid.Row>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                            <Segment className="custom-wordcloud-inside-segment">
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                            Printer


                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column >
                                                            <Dropdown
                                                                fluid
                                                                selection
                                                                options={printerOptions}
                                                                value={printerSelected}
                                                                onChange={handlePrinterChange}
                                                                placeholder='Select Printer'
                                                                style={{
                                                                    textAlign: 'left',
                                                                    backgroundColor: '#f2f2f7'
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>


                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column width={15} style={{ marginBottom: "10px" }}>
                                            <Segment className="custom-wordcloud-inside-segment">
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={13}
                                                            style={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>
                                                            Moderation
                                                        </Grid.Column>
                                                        {/* <Grid.Column width={3}>
                                                            <Checkbox
                                                                label="Show All"
                                                                onChange={handleShowAllPrinted}
                                                                checked={showAllPrintedChecked}
                                                            />
                                                        </Grid.Column> */}
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Segment className='custom-wordcloud-inside-segment-segment' style={{ background: "#F2F2F7" }}>
                                                                <Grid>
                                                                    <Grid.Column style={{ scrollbarColor: '#695fca #dbdaee', overflow: 'auto', scrollbarWidth: 'thin', maxHeight: '100vh', direction: 'rtl' }}>
                                                                        <Grid>
                                                                            {showPrintedImages()}
                                                                        </Grid>
                                                                    </Grid.Column>

                                                                </Grid>

                                                            </Segment>
                                                        </Grid.Column>
                                                    </Grid.Row>

                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                            </div>
                        )
                    }
                    <Grid centered columns={3} textAlign="center" style={{ padding: 0, margin: 0 }} >
                        <Grid.Column textAlign="bottom-left" style={{ paddingBottom: 0 }}>
                            {/* <Button size="large" className="custom-wordcloud-dashboard-save-button" onClick={handleAnimatedPreview} style={{ marginLeft:"2em",color: "#fff", background: "#695fca", borderRadius: "20px !important" }} >

                                Animated Preview
                            </Button> */}
                        </Grid.Column>
                        <Grid.Column textAlign="bottom" style={{ paddingBottom: 0 }}>
                            <Button size="large" className="custom-wordcloud-dashboard-save-button" onClick={handleSaveButton} style={{ color: "#fff", background: "#695fca", marginBottom: "50vh", borderRadius: "20px !important" }}>
                                Save
                            </Button>
                        </Grid.Column>

                        <Grid.Column textAlign="bottom-right" style={{ paddingBottom: 0, paddingRight: "2vw" }}>
                            <Button size="large" className="custom-wordcloud-dashboard-save-button" onClick={handlePreviewButton} style={{ color: "#fff", background: "#695fca", borderRadius: "20px !important" }} >
                                {/* <Icon name='desktop' /> */}
                                Preview
                            </Button>
                        </Grid.Column>

                    </Grid>

                </Grid.Column >

            </Grid >
        </div >
    )
}

export default WordCloudDashboardPage;