import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import WordWallTopBar from "./components/WordWallTopBar";
import "./WordWallEventListPage.css";
import { Container, Grid, Form, Button, Header } from "semantic-ui-react";

//for word cloud
const WordWallEventListPage = () => {
    const [liveEventList, setLiveEventList] = useState([]);
    const [pastEventList, setPastEventList] = useState([]);
    // const [eventName, setEventName] = useState(null);
    const [live, setLive] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // registerMeIn();
        // getLiveEvents();
        // getPastEvents();
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                    await getLiveEvents(token);
                    await getPastEvents(token);
                } else {
                    navigate("/");
                }
            } catch (error) {
                console.error(error);
                navigate("/");
            }
        };
    
        fetchData();
    
        const interval = setInterval(() => {
            fetchData();
        }, 1000 * 10); // poll every 10s
    
        // Return the interval clearing function from the useEffect itself
        return () => clearInterval(interval);
    }, []);

    const getLiveEvents = async () => {
        console.log("getting live events")
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/wordWallEventList/liveEvents", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setLiveEventList(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getPastEvents = async () => {
        console.log("getting past events")
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/wordWallEventList/pastEvents", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPastEventList(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleLiveEventClick = (event) => {
        navigate("/WordWallDashboardPage", { state: { eventName: event.eventName } });
    };

    const handlePastEventClick = (event) => {
        navigate("/WordWallDashboardPage", { state: { eventName: event.eventName } });
    };

    const handleAddNewEvent = (event) =>{
        navigate("/WordWallAddNewEventPage");
    }

    return (
        <div>
            <WordWallTopBar />

            <Grid centered columns={3} verticalAlign="top" style={{ height: "10vh" }}>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <h1 style={{ textAlign: "center", marginTop: "10vh" }}>Events</h1>
                </Grid.Column>
                <Grid.Column>
                    <Button className="custom-add-new-event-button" onClick={handleAddNewEvent}>+  New Event</Button>
                </Grid.Column>

            </Grid>


            <Grid verticalAlign='top' columns={3} centered style={{ marginTop: "15vh" ,paddingBottom:"50px"}}  >
                <Grid.Row>
                    <Grid.Column width={4}>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="left">
                        <Grid.Row>
                            <label style={{marginLeft:"14px"}}>
                                Live Events:
                            </label>
                        </Grid.Row>
                        {liveEventList.map((event, index) => (
                            <Grid.Row key={index} style={{ marginTop: "10px" }}>
                                <Button
                                    fluid
                                    className="custom-event-item-button"
                                    onClick={()=>handleLiveEventClick(event)}
                                >
                                    {event.eventName}
                                </Button>
                            </Grid.Row>
                        ))}
                    </Grid.Column>
                    <Grid.Column width={4}>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ marginTop: "3vh" }}>
                    <Grid.Column width={4}>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="left">
                        <Grid.Row>
                            <label style={{marginLeft:"14px"}}>
                                Past Events:
                            </label>
                        </Grid.Row>
                        {pastEventList.map((event, index) => (
                            <Grid.Row key={index} style={{ marginTop: "10px" }}>
                                <Button
                                    fluid
                                    className="custom-event-item-button"
                                    onClick={()=>handlePastEventClick(event)}
                                >
                                    {event.eventName}
                                </Button>
                            </Grid.Row>
                        ))}
                    </Grid.Column>
                    <Grid.Column width={4}>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Footer />
        </div>
    )
}

export default WordWallEventListPage;