// frontend code
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate,useLocation } from "react-router-dom";



const DashBoard = () => {
  const [eventName, setEventName] = useState(null);
  const [toggleA, setToggleA] = useState(false);
  const [toggleB, setToggleB] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);
  const [guests, setGuests] = useState([]);



  useEffect(() => {
    // registerMeIn();
    getUpdates();

    const mytoken = localStorage.getItem("token")
    if (mytoken) {
      // /api/auth/verify
      axios
        .post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken })
        .then((response) => {
          // console.log(response)
          // authenticated = true;
          const interval = setInterval(() => {
            getUpdates();
          }, 1000 * 10); // poll every minute

          return () => clearInterval(interval);
        })
        .catch((error) => {
          console.error(error);
          // authenticated = false;

          navigate("/");
        })
    } else {
      // s
      navigate("/");
    }

  }, []);


  const fetchImages = async () => {
    try {
      const response = await axios.get("/api/googleListFiles");
      setImages(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const registerMeIn = async () => {
    const roomName = "dashboard";
    const curUser = location.state.name;
    try {
      // Assuming the /api/registerMeIn endpoint returns the new user's information
      const response = await axios.post("/api/registerMeIn",{ roomName ,curUser});
      
    } catch (error) {
      console.error(error);
    }
  };

  const getUpdates = async () => {
    console.log("updating")
    fetchImages();

    try {
      const token = localStorage.getItem("token");
      const res = await axios.get("https://wordwallrebuild.herokuapp.com/dashboard/events", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { eventName, toggleA, toggleB } = res.data;
      setEventName(eventName);
      setToggleA(toggleA);
      setToggleB(toggleB);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleA = async (toggle) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        "https://wordwallrebuild.herokuapp.com/dashboard/events/toggleA",
        { toggle: !toggleA },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setToggleA(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const handleToggleB = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        "https://wordwallrebuild.herokuapp.com/dashboard/events/toggleB",
        { toggle: !toggleB },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setToggleB(response.data);
    } catch (error) {
      console.error(error);
    }
  };



  const handleFileChoose = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post('/api/googleUpload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
      console.log('Error uploading file');
    }
  };

  const handleBackgroundSelect = async (imageId) => {
    try {
      await axios.put("https://wordwallrebuild.herokuapp.com/dashboard/events/selectBackground", { imageId },
        
      );
      console.log("Selected image ID saved to MongoDB");
    } catch (error) {
      console.error(error);
      console.log("Error saving selected image ID to MongoDB");
    }
  };

  return (
    <div>
      <h1>{eventName}</h1>

      <h1>Toggle A: {toggleA ? "ON" : "OFF"}</h1>
      <button onClick={handleToggleA}>Toggle</button>

      <h1>Toggle B: {toggleB ? "ON" : "OFF"}</h1>
      <button onClick={handleToggleB}>Toggle</button>

      <form onSubmit={handleFileUpload}>
        <input type="file" name="file" onChange={handleFileChoose} />
        <button type="submit">Upload</button>
      </form>

      <div>
        {images.map((image) => (
          <div key={image.id} onClick={() => handleBackgroundSelect(image.id)} >
            <img src={image.thumbnailLink} alt={image.name} />
            <p>{image.name}</p>
          </div>
        ))}
      </div>

    </div>

  );
}

export default DashBoard;
