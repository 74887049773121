import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import "./MosaicEventListPage.css";
import { Container, Grid, Form, Button, Header, Dropdown, Segment, Search } from "semantic-ui-react";

//for Mosaic
const MosaicEventListPage = () => {
    const [liveEventList, setLiveEventList] = useState([]);
    const [upcomingEventList, setUpcomingEventList] = useState([]);
    const [pastEventList, setPastEventList] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const [upcomingSearchQuery, setUpcomingSearchQuery] = useState("")
    const [upcomingSearchResults, setUpcomingSearchResults] = useState([])

    const [pastSearchQuery, setPastSearchQuery] = useState("")
    const [pastSearchResults, setPastSearchResults] = useState([])

    const [liveExpanded, setLiveExpanded] = useState(true);
    const [upcomingExpanded, setUpcomingExpanded] = useState(true);
    const [pastExpanded, setPastExpanded] = useState(false);



    const handleSearch = (e, { value }) => {
        setSearchQuery(value);

        const filteredEvents = liveEventList.filter(event =>
            event.eventName.toLowerCase().includes(value.toLowerCase())
        );

        setSearchResults(filteredEvents);
    };

    const handleUpcomingSearch = (e, { value }) => {
        setUpcomingSearchQuery(value);
        const filteredEvents = upcomingEventList.filter(event =>
            event.eventName.toLowerCase().includes(value.toLowerCase())
        );
        setUpcomingSearchResults(filteredEvents);
    }

    const handlePastSearch = (e, { value }) => {
        setPastSearchQuery(value);
        const filteredEvents = pastEventList.filter(event =>
            event.eventName.toLowerCase().includes(value.toLowerCase())
        );
        setPastSearchResults(filteredEvents);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                    await getLiveEvents(token);
                    await getUpcomingEvents(token);
                    await getPastEvents(token);
                } else {
                    navigate("/");
                }
            } catch (error) {
                console.error(error);
                navigate("/");
            }
        };

        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 1000 * 10); // poll every 10s

        // Return the interval clearing function from the useEffect itself
        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        const fetchUpdateLiveEvents = async () => {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                    await updateLiveEvents();
                }
                else {
                    navigate("/");
                }
            } catch (error) {
                console.log(error)
            }
        }

        const updateInterval = setInterval(() => {
            fetchUpdateLiveEvents()
        }, 60000); // 60 seconds

        return () => clearInterval(updateInterval);
    }, []); // Empty dependency array to run only on mount and unmount

    useEffect(() => {
        const fetchUpdatePastEvents = async () => {

            try {
                const token = localStorage.getItem("token");
                if (token) {
                    await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                    await updatePastEvents();
                }
                else {
                    navigate("/");
                }
            } catch (error) {
                console.log(error)
            }
        }

        const updateInterval = setInterval(() => {
            fetchUpdatePastEvents();
        }, 60000); // 60 seconds

        return () => clearInterval(updateInterval);
    }, []); // Empty dependency array to run only on mount and unmount

    useEffect(() => {
        const fetchUpdateUpcomingEvents = async () => {

            try {
                const token = localStorage.getItem("token");
                if (token) {
                    await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                    await updateUpcomingEvents();
                }
                else {
                    navigate("/");
                }
            } catch (error) {
                console.log(error)
            }
        }

        const updateInterval = setInterval(() => {
            fetchUpdateUpcomingEvents();
        }, 60000); // 60 seconds

        return () => clearInterval(updateInterval);
    }, []); // Empty dependency array to run only on mount and unmount


    const updateLiveEvents = async () => {
        try {
            const res = await axios.put("https://wordwallrebuild.herokuapp.com/mosaicEventList/updateLiveEvents", {
            });
            // console.log(res)
        }
        catch (error) {
            console.error(error);
        }
    }

    const updateUpcomingEvents = async () => {
        try {
            const res = await axios.put("https://wordwallrebuild.herokuapp.com/mosaicEventList/updateUpcomingEvents", {
            });
            // console.log(res)
        }
        catch (error) {
            console.error(error);
        }
    }

    const updatePastEvents = async () => {
        try {
            const res = await axios.put("https://wordwallrebuild.herokuapp.com/mosaicEventList/updatePastEvents", {
            });
            // console.log(res)
        }
        catch (error) {
            console.error(error);
        }
    }

    const getLiveEvents = async () => {
        console.log("getting live events")
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicEventList/liveEventsSummary", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setLiveEventList(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getUpcomingEvents = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicEventList/upcomingEventsSummary", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUpcomingEventList(res.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getPastEvents = async () => {
        console.log("getting past events")
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicEventList/pastEventsSummary", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPastEventList(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleLiveEventClick = (event) => {
        navigate("/MosaicDashboardPage", { state: { eventName: event.eventName } });
    };

    const handleUpcomingEventClick = (event) => {
        navigate("/MosaicDashboardPage", { state: { eventName: event.eventName } });
    }

    const handlePastEventClick = (event) => {
        navigate("/MosaicDashboardPage", { state: { eventName: event.eventName } });
    };

    const handleAddNewEvent = (event) => {
        navigate("/MosaicAddNewEventPage");
    }

    const resultRenderer = ({ eventName, startDate, timezone, eventLocation }) => {
        return (
            <Button
                fluid
                className="custom-event-item-button-no-border"
                onClick={() =>
                    handleLiveEventClick({
                        eventName,
                        startDate,
                        timezone,
                        eventLocation
                    })
                }
            >
                <div style={{ fontSize: "20px", color: '#ffffff' }}>{eventName}</div>
                <div style={{ opacity: 0 }}>s</div>
                <div style={{ color: '#ffffff' }}>{startDate}</div>
                <div style={{ color: '#ffffff' }}>{timezone + " " + eventLocation}</div>
            </Button>
        );
    };

    const upcomingResultRenderer = ({ eventName, startDate, timezone, eventLocation }) => {
        return (
            <Button
                fluid
                className="custom-event-item-button-no-border"
                onClick={() =>
                    handleUpcomingEventClick({
                        eventName,
                        startDate,
                        timezone,
                        eventLocation
                    })
                }
            >
                <div style={{ fontSize: "20px", color: '#ffffff' }}>{eventName}</div>
                <div style={{ opacity: 0 }}>s</div>
                <div style={{ color: '#ffffff' }}>{startDate}</div>
                <div style={{ color: '#ffffff' }}>{timezone + " " + eventLocation}</div>
            </Button>
        );
    }

    const pastResultRenderer = ({ eventName, startDate, timezone, eventLocation }) => {
        return (
            <Button
                fluid
                className="custom-event-item-button-no-border"
                onClick={() =>
                    handlePastEventClick({
                        eventName,
                        startDate,
                        timezone,
                        eventLocation
                    })
                }
            >
                <div style={{ fontSize: "20px", color: '#ffffff' }}>{eventName}</div>
                <div style={{ opacity: 0 }}>s</div>
                <div style={{ color: '#ffffff' }}>{startDate}</div>
                <div style={{ color: '#ffffff' }}>{timezone + " " + eventLocation}</div>
            </Button>
        );
    }

    const handleLiveExpand = () => {
        setLiveExpanded(!liveExpanded);
    };
    const handleUpcomingExpand = () => {
        setUpcomingExpanded(!upcomingExpanded);
    };

    const handlePastExpand = () => {
        setPastExpanded(!pastExpanded);
    };

    return (
        <div style={{ backgroundColor: "#EAEAF1", minHeight: "100vh" }}>

            <Grid centered columns={3} verticalAlign="top" style={{ height: "10vh" }}>
                <Grid.Column>
                    <Grid.Row style={{ marginTop: "7vh", marginLeft: "12vw", fontSize: "30px" }}>
                        Polite Playground
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <h1 style={{ textAlign: "center", marginTop: "10vh", fontSize: "40px" }}>Mosaic Events</h1>
                </Grid.Column>
                <Grid.Column>
                    <Grid.Row style={{ textAlign: "right", marginRight: "7vw", marginTop: "5vh" }}>
                        <Dropdown
                            text='Menu'
                            icon="bars"
                            floating
                            labeled
                            inverted
                            button
                            className="icon"
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item text="Mosaic Events" onClick={() => {
                                    navigate("/MosaicEventListPage", { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                }} />
                                <Dropdown.Item text="Products" onClick={() => {
                                    navigate('/ProductsPage', { state: { token: localStorage.getItem("token"), name: localStorage.getItem("name") } })
                                }} />
                                <Dropdown.Item text="Log Out" onClick={() => { navigate('/') }} />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Grid.Row>
                </Grid.Column>
            </Grid>

            <Grid verticalAlign='top' columns={3} centered style={{ marginTop: "15vh" }}  >
                <Grid.Row>
                    <Grid.Column width={2}>
                    </Grid.Column>
                    <Grid.Column width={12} >
                        <Segment style={{ borderRadius: "20px" }}>
                            <Grid style={{ marginTop: "1vh" }}>
                                <Grid.Row columns={3} divided={false}>
                                    <Grid.Column style={{ marginLeft: "14px", textAlign: "left", fontSize: "22px" }}>
                                        Live Events:
                                    </Grid.Column>
                                    <Grid.Column style={{ textAlign: "center", paddingLeft: 0 }}>
                                        <Search
                                            fluid
                                            style={{ background: "#F2F2F7 !important" }}
                                            placeholder="Search Event Name"
                                            value={searchQuery}
                                            onSearchChange={handleSearch}
                                            results={searchResults}
                                            resultRenderer={resultRenderer}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5} style={{ textAlign: "right" }}>
                                        <Button circular icon={liveExpanded ? 'chevron up' : 'chevron down'} onClick={handleLiveExpand}>
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {liveExpanded && (
                                <Grid style={{ marginTop: 0 }}>
                                    <Grid.Row columns={3} divided={false}>
                                        {liveEventList.map((event, index) => (
                                            <Grid.Column key={index} style={{ marginTop: "2vh" }}>
                                                <Button
                                                    fluid
                                                    className="custom-event-item-button-no-border"
                                                    onClick={() => handleLiveEventClick(event)}
                                                >
                                                    <div style={{ fontSize: "20px" }}>{event.eventName}</div>
                                                    <div style={{ opacity: 0 }}>s</div>
                                                    <div>{event.startDate}</div>
                                                    <div>{event.timezone + " " + event.eventLocation}</div>
                                                </Button>
                                            </Grid.Column>
                                        ))}
                                    </Grid.Row>
                                </Grid>
                            )}
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={2}>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ marginTop: "3vh" }}>
                    <Grid.Column width={2}>
                    </Grid.Column>
                    <Grid.Column width={12} >
                        <Segment style={{ borderRadius: "20px" }}>
                            <Grid style={{ marginTop: "1vh" }}>
                                <Grid.Row columns={3} divided={false}>
                                    <Grid.Column style={{ marginLeft: "14px", textAlign: "left", fontSize: "22px" }}>
                                        Upcoming Events:
                                    </Grid.Column>
                                    <Grid.Column style={{ textAlign: "center", paddingLeft: 0 }}>
                                        <Search
                                            fluid
                                            style={{ background: "#F2F2F7 !important" }}
                                            placeholder="Search Event Name"
                                            value={upcomingSearchQuery}
                                            onSearchChange={handleUpcomingSearch}
                                            results={upcomingSearchResults}
                                            resultRenderer={upcomingResultRenderer}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5} style={{ textAlign: "right" }}>
                                        <Button circular icon={upcomingExpanded ? 'chevron up' : 'chevron down'} onClick={handleUpcomingExpand}>
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {upcomingExpanded && (
                                <Grid style={{ marginTop: 0 }}>
                                    <Grid.Row columns={3} divided={false}>
                                        {upcomingEventList.map((event, index) => (
                                            <Grid.Column key={index} style={{ marginTop: "2vh" }} >
                                                <Button
                                                    fluid
                                                    className="custom-event-item-button-no-border"
                                                    onClick={() => handleUpcomingEventClick(event)}
                                                >
                                                    <div style={{ fontSize: "20px" }}>{event.eventName}</div>
                                                    <div style={{ opacity: 0 }}>s</div>
                                                    <div>{event.startDate}</div>
                                                    <div>{event.timezone + " " + event.eventLocation}</div>
                                                </Button>
                                            </Grid.Column>
                                        ))}
                                    </Grid.Row>
                                </Grid>
                            )}
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={2}>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ marginTop: "3vh" }}>
                    <Grid.Column width={2}>
                    </Grid.Column>
                    <Grid.Column width={12} >
                        <Segment style={{ borderRadius: "20px" }}>
                            <Grid style={{ marginTop: "1vh" }}>
                                <Grid.Row columns={3} divided={false}>
                                    <Grid.Column style={{ marginLeft: "14px", textAlign: "left", fontSize: "22px" }}>
                                        Past Events:
                                    </Grid.Column>
                                    <Grid.Column style={{ textAlign: "center", paddingLeft: 0 }}>
                                        <Search
                                            fluid
                                            style={{ background: "#F2F2F7 !important" }}
                                            placeholder="Search Event Name"
                                            value={pastSearchQuery}
                                            onSearchChange={handlePastSearch}
                                            results={pastSearchResults}
                                            resultRenderer={pastResultRenderer}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5} style={{ textAlign: "right" }}>
                                        <Button circular icon={pastExpanded ? 'chevron up' : 'chevron down'} onClick={handlePastExpand}>
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {pastExpanded && (
                                <Grid style={{ marginTop: 0 }}>
                                    <Grid.Row columns={3} divided={false}>
                                        {pastEventList.map((event, index) => (
                                            <Grid.Column key={index} style={{ marginTop: "2vh" }} >
                                                <Button
                                                    fluid
                                                    className="custom-event-item-button-no-border"
                                                    onClick={() => handlePastEventClick(event)}
                                                >
                                                    <div style={{ fontSize: "20px" }}>{event.eventName}</div>
                                                    <div style={{ opacity: 0 }}>s</div>
                                                    <div>{event.startDate}</div>
                                                    <div>{event.timezone + " " + event.eventLocation}</div>
                                                </Button>
                                            </Grid.Column>
                                        ))}
                                    </Grid.Row>
                                </Grid>
                            )}
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={2}>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid centered columns={3} verticalAlign="middle" style={{ textAlign: "center" }}>
                <Grid.Row columns={3} divided={false}>
                    <Grid.Column >
                    </Grid.Column>
                    <Grid.Column style={{ textAlign: "center" }}>
                        <Button size='massive' className="custom-add-new-event-button-no-border" onClick={handleAddNewEvent}>+  New Event</Button>
                    </Grid.Column>
                    <Grid.Column >
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default MosaicEventListPage;