import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation, Await } from "react-router-dom";
import "./MosaicSimplifiedDashboard.css";
import { Grid, Button, Segment, Divider, Icon, Dropdown, Checkbox, Image, Label, Input } from "semantic-ui-react";
import Swal from "sweetalert2";
import printerIcon from './img/Printer Settings Icon.png';

const MosaicSimplifiedDashboard = () => {
    const isIpadRef = useRef(false);
    // Function to detect if the device is an iPad
    const checkIfIpad = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /iPad|Macintosh/.test(userAgent) && 'ontouchend' in document;
    };

    // useEffect hook to set the isIpadRef current value when the component mounts
    useEffect(() => {
        isIpadRef.current = checkIfIpad();
        // You can log the value or perform other actions here as needed
        console.log("Is iPad:", isIpadRef.current);
    }, []);

    const [postModerationOptions, setPostModerationOptions] = useState([
        {
            key: 'Manual',
            value: 'Manual',
            text: 'Manual'
        },
        {
            key: 'Automatic',
            value: 'Automatic',
            text: 'Automatic'
        }
    ])

    const navigate = useNavigate();
    const location = useLocation();
    const [possiblePositionsArray, setPossiblePositionsArray] = useState([])
    const [imageInput, setImageInput] = useState([]);
    const [printedImageInput, setPrintedImageInput] = useState([]);
    const [searchPostResult, setSearchPostResult] = useState([])
    const [searchResult, setSearchResult] = useState([])
    const [activeTab, setActiveTab] = useState('Imported Posts');
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [postModerationButton, setPostModerationButton] = useState('')
    const [activeButton, setActiveButton] = useState('allPosts');
    const [activeButton1, setActiveButton1] = useState('allPrints')
    const [printerStatus, setPrinterStatus] = useState('')

    const [live, setLive] = useState(false)

    const handleActiveButtonClick = (buttonName) => {
        setActiveButton(buttonName); // Set the clicked button as the active one
    };

    const handleActiveButtonClick1 = (buttonName) => {
        setActiveButton1(buttonName); // Set the clicked button as the active one
    };

    const handleImageClick = (id) => {
        setSelectedImageId(prevId => prevId === id ? null : id); // Toggle selection
    };
    const handleOverlayClose = (e) => {
        e.stopPropagation(); // Prevent triggering image click
        setSelectedImageId(null); // Deselect the image and hide the overlay
    };

    // const [postsTotalAmount, setPostsTotalAmount] = useState(0);
    const [printingTotalAmount, setPrintingTotalAmount] = useState(0)
    const [printingFinishedAmount, setPrintingFinishedAmount] = useState(0)
    const [printingFailedAmount, setPrintingFailedAmount] = useState(0)
    const [printingQueuedAmount, setPrintingQueuedAmount] = useState(0)
    const [moderationApprovedAmount, setModerationApprovedAmount] = useState(0)
    const [moderationRejectedAmount, setModerationRejectedAmount] = useState(0)
    const [moderationPendingAmount, setModerationPendingAmount] = useState(0)
    const [moderationTotalAmount, setModerationTotalAmount] = useState(0)
    // const [moderationOverflowAmount, setModerationOverflowAmount] = useState(0)

    const loadMoreRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const offsetRef = useRef(0);

    const [hasMore, setHasMore] = useState(false);

    const loadMoreRef1 = useRef(null);
    const [isLoading1, setIsLoading1] = useState(false);
    const offsetRef1 = useRef(0);

    const [hasMore1, setHasMore1] = useState(false);

    const [rowNumber, setRowNumber] = useState('')
    const [colNumber, setColNumber] = useState('')

    const [postRowNumber,setPostRowNumber] = useState('')
    const [postColNumber, setPostColNumber] = useState('')

    const handleIdleChange = () => {
        setLive(!live)
    }

    const handlePostModerationButtonChange = (e, { value }) => { setPostModerationButton(value) }

    const handleReprint = async (imageID, eventName) => {
        try {
            const response = await axios.post(`https://wordwallrebuild.herokuapp.com/mosaicDashboard/printJobs/reprint/${imageID}`, null, {
                params: { eventName }
            });
            console.log('Reprint response:', response.data);
            // Handle the response as needed
        } catch (error) {
            console.error('Error in reprinting:', error);
            // Handle the error as needed
        }
    };

    const handleRowNumberChange = (e) => {
        setRowNumber(e.target.value);
    }

    const handleColNumberChange = (e) => {
        setColNumber(e.target.value);
    }

    const handlePostRowNumberChange = (e) =>{
        setPostRowNumber(e.target.value)
    }

    const handlePostColNumberChange = (e) =>{
        setPostColNumber(e.target.value)
    }
    


    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });
                    //load custom background list
                    // await fetchCustomBackgroundList()

                    //load main info from mongodb
                    await fetchEventDashboard();
                    if (activeButton == 'allPosts') {
                        // await fetchRecent8Images()
                    } else if (activeButton == 'approvedPosts') {
                        // await fetchRecent8ConditionalImages('Approved')
                    } else if (activeButton == 'rejectedPosts') {
                        // await fetchRecent8ConditionalImages('Rejected')
                    } else if (activeButton == 'pendingPosts') {
                        // await fetchRecent8ConditionalImages('Pending')
                    }

                    if (activeButton1 == 'allPrints') {
                        // await fetchRecent8PrintedImages()
                    } else if (activeButton1 == 'queuedPrints') {
                        // await fetchRecent8ConditionalPrints('Pending')
                    } else if (activeButton1 == 'finishedPrints') {
                        // await fetchRecent8ConditionalPrints('Finished')
                    } else if (activeButton1 == 'failedPrints') {
                        // await fetchRecent8ConditionalPrints('Failed')
                    }

                    //print amount
                    await fetchPrintingAmount()
                    await fetchFinishedAmount()
                    await fetchFailedAmount()
                    await fetchQueuedAmount()

                    //posts
                    await fetchPostsAmount()
                    await fetchApprovedAmount()
                    await fetchRejectedAmount()
                    await fetchPendingAmount()

                    await fetchPrinterStatus()
                }
                else {
                    navigate("/");
                }
            } catch (error) {
                console.error(error);
                navigate("/");
            }
        };
        fetchData();
    }, []);

    const fetchPrinterStatus = async () => {
        try {
            // Assuming you have defined the base URL of your backend service somewhere
            const baseURL = 'https://wordwallrebuild.herokuapp.com/mosaicDashboard';
            const response = await axios.get(`${baseURL}/getSpecificPrinterInfo?eventName=${encodeURIComponent(location.state.eventName)}`);
            setPrinterStatus(response.data)
        } catch (error) {
            console.error('Error fetching printer status:', error);
        }
    };

    const fetchEventDashboard = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/event-dashboard", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPossiblePositionsArray(res.data.possiblePositionsArray)
            setPostModerationButton(res.data.postModerationButton)
        } catch (error) {
            console.error(error);
        }
    }

    const fetchFirstRoundPrintedImages = async (printed = '') => {
        let url = `https://wordwallrebuild.herokuapp.com/mosaicDashboard/allPrintedImages?eventName=${encodeURIComponent(location.state.eventName)}&offset1=${offsetRef1.current}&limit1=10`;
        if (printed) {
            url += `&printed=${printed}`;
        }
        try {
            const response = await axios.get(url);
            if (response.data.length < 10) {
                setHasMore1(false); // No more data to fetch
            }
            setPrintedImageInput(prevPrintedImages => [...prevPrintedImages, ...response.data]);
            offsetRef1.current += response.data.length;
        } catch (error) {
            console.error("Error fetching images:", error);
        } finally {
            setIsLoading1(false);
        }
    }

    //only runs one time when switching activbutton 
    const fetchFirstRoundImages = async (posted = '') => {
        let url = `https://wordwallrebuild.herokuapp.com/mosaicDashboard/allImages?eventName=${encodeURIComponent(location.state.eventName)}&offset=${offsetRef.current}&limit=10`;
        if (posted) {
            url += `&posted=${posted}`;
        }
        try {
            const response = await axios.get(url);
            if (response.data.length < 10) {
                setHasMore(false); // No more data to fetch
            }
            setImageInput(prevImages => [...prevImages, ...response.data]);
            offsetRef.current += response.data.length;
        } catch (error) {
            console.error("Error fetching images:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPrintedImages = async (printed = '') => {
        if (isLoading1 || !hasMore1) return;
        setIsLoading1(true)
        let url = `https://wordwallrebuild.herokuapp.com/mosaicDashboard/allPrintedImages?eventName=${encodeURIComponent(location.state.eventName)}&offset1=${offsetRef1.current}&limit1=10`;
        if (printed) {
            url += `&printed=${printed}`;
        }
        try {
            const response = await axios.get(url);
            if (response.data.length < 10) {
                setHasMore1(false); // No more data to fetch
            }
            setPrintedImageInput(prevPrintedImages => [...prevPrintedImages, ...response.data]);
            offsetRef1.current += response.data.length;
        } catch (error) {
            console.error("Error fetching printed images:", error);
        } finally {
            setIsLoading1(false);
        }
    }


    const fetchPostSearchResult = async () => {
        let url = `https://wordwallrebuild.herokuapp.com/mosaicDashboard/searchPrint?eventName=${encodeURIComponent(location.state.eventName)}&rowNumber=${postRowNumber}&colNumber=${postColNumber}`;
        try {
            const response = await axios.get(url);
            setSearchPostResult(response.data)
            // setSearchResult(response.data);
            // offsetRef2.current += response.data.length;
        } catch (error) {
            console.error("Error fetching printed images:", error);
        } finally {

        }
    }

    const fetchSearchResult = async () => {
        let url = `https://wordwallrebuild.herokuapp.com/mosaicDashboard/searchPrint?eventName=${encodeURIComponent(location.state.eventName)}&rowNumber=${rowNumber}&colNumber=${colNumber}`;
        try {
            const response = await axios.get(url);
            setSearchResult(response.data);
            // offsetRef2.current += response.data.length;
        } catch (error) {
            console.error("Error fetching printed images:", error);
        } finally {

        }
    }

    const fetchImages = async (posted = '') => {
        console.log(isLoading)
        console.log(hasMore)
        if (isLoading || !hasMore) return;
        setIsLoading(true);
        let url = `https://wordwallrebuild.herokuapp.com/mosaicDashboard/allImages?eventName=${encodeURIComponent(location.state.eventName)}&offset=${offsetRef.current}&limit=10`;
        if (posted) {
            url += `&posted=${posted}`;
        }
        try {
            const response = await axios.get(url);
            if (response.data.length < 10) {
                setHasMore(false); // No more data to fetch
            }
            console.log(response.data)
            setImageInput(prevImages => [...prevImages, ...response.data]);
            offsetRef.current += response.data.length;
        } catch (error) {
            console.error("Error fetching images:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Observe when to load more images
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const first = entries[0]
                if (entries[0].isIntersecting && hasMore && activeTab == 'Imported Posts') {
                    let postedFilter = '';
                    switch (activeButton) {
                        case 'allPosts':
                            postedFilter = null;
                            break;
                        case 'approvedPosts':
                            postedFilter = 'Approved';
                            break;
                        case 'rejectedPosts':
                            postedFilter = 'Rejected';
                            break;
                        case 'pendingPosts':
                            postedFilter = 'Pending';
                            break;
                        default:
                            // allPosts or any other state leads to fetching without filtering
                            postedFilter = null;
                            break;
                    }
                    fetchImages(postedFilter);
                }
            },
            { threshold: 1.0 }
        );

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [hasMore, activeButton, activeTab]);

    // Observe when to load more printed images
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const first = entries[0]
                if (entries[0].isIntersecting && hasMore1 && activeTab == 'Print Queue') {
                    let printedFilter = '';
                    switch (activeButton1) {
                        case 'allPrints':
                            printedFilter = null;
                            break;
                        case 'queuedPrints':
                            printedFilter = 'Pending';
                            break;
                        case 'finishedPrints':
                            printedFilter = 'Finished';
                            break;
                        case 'failedPrints':
                            printedFilter = 'Failed';
                            break;
                        default:
                            // allPosts or any other state leads to fetching without filtering
                            printedFilter = null;
                            break;
                    }
                    fetchPrintedImages(printedFilter);
                }
            },
            { threshold: 1.0 }
        );

        if (loadMoreRef1.current) {
            observer.observe(loadMoreRef1.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [hasMore1, activeButton1, activeTab]);

    //for the posts amount
    const fetchPostsAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countPosts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setModerationTotalAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }
    const fetchApprovedAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countApprovedPosts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setModerationApprovedAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchRejectedAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countRejectedPosts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setModerationRejectedAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchPendingAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countPendingPosts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setModerationPendingAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    // printing amount
    const fetchPrintingAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countAllPrints", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPrintingTotalAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }
    const fetchFinishedAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countFinishedPrints", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPrintingFinishedAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchFailedAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countFailedPrints", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPrintingFailedAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const fetchQueuedAmount = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await axios.get("https://wordwallrebuild.herokuapp.com/mosaicDashboard/countQueuedPrints", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    eventName: location.state.eventName, // replace with the name you want to search for
                },
            });
            setPrintingQueuedAmount(res.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    const handleApprovePost = async (id) => {
        try {
            const token = localStorage.getItem("token");
            const headers = {
                'Custom-Header': 'CustomHeaderValue',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            };
            const data = {
                eventName: location.state.eventName,
                imageID: id,
            }
            const response = await axios.put('https://wordwallrebuild.herokuapp.com/mosaicDashboard/approvePost', data, { headers: headers });
            if (response.status === 200) {
                Swal.fire('Success', 'Post Approved', 'success').then(() => {
                    setSelectedImageId(null); // Deselect the image and close the overlay
                    // Optionally, refresh the images list to reflect the changes
                });
            } else {
                Swal.fire('Error', 'Something went wrong while Approving', 'error');
            }
        } catch (error) {
            console.error(`Error approving post`);
            Swal.fire('Error', 'Something went wrong', 'error');
        }
    }

    const handleRejectPost = async (id) => {
        try {
            const token = localStorage.getItem("token");
            const headers = {
                'Custom-Header': 'CustomHeaderValue',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            };
            const data = {
                eventName: location.state.eventName,
                imageID: id,
            }
            const response = await axios.put('https://wordwallrebuild.herokuapp.com/mosaicDashboard/rejectPost', data, { headers: headers });
            if (response.status === 200) {
                Swal.fire('Success', 'Post Rejected', 'success').then(() => {
                    setSelectedImageId(null); // Deselect the image and close the overlay
                    // Optionally, refresh the images list to reflect the changes
                });
            } else {
                Swal.fire('Error', 'Something went wrong while rejecting', 'error');
            }
        } catch (error) {
            console.error(`Error rejecting post`);
            Swal.fire('Error', 'Something went wrong', 'error');
        }
    }

    const showImages = () => {
        let rows = [];
        let currentRow = [];

        for (let i = 0; i < imageInput.length; i++) {
            const image = imageInput[i];
            const isSelected = image._id === selectedImageId;

            currentRow.push(
                <Grid.Column key={i} style={{ position: 'relative', textAlign: 'left', overflowWrap: 'anywhere' }}>
                    <img src={`data:image/jpeg;base64,${image.imageData}`} alt="Description"
                        onClick={() => handleImageClick(image._id)}
                        style={{ display: 'inline-block', cursor: 'pointer', width: '100%', height: 'auto' }} />
                    {isSelected && (
                        <div
                            onClick={handleOverlayClose}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(255,255,255,0.3)',
                                display: 'flex',
                                flexDirection: 'column', // Change to column to stack vertically
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <div style={{ marginBottom: '20px', fontSize: '20px', fontWeight: 'bold', color: 'white' }}>Moderation</div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}> {/* Container for buttons */}
                                <Button circular icon='check' color='green' onClick={(e) => { e.stopPropagation(); handleApprovePost(image._id); }} style={{ marginRight: '10px' }} />
                                <Button circular icon='close' color='red' onClick={(e) => { e.stopPropagation(); handleRejectPost(image._id); }} />
                            </div>
                        </div>
                    )}
                    <Grid style={{ marginLeft: 0, marginRight: 0, marginBottom: 0, marginTop: '-0.5vh', background: 'white' }}>
                        <Grid.Column floated='left' textAlign="left" width={10} style={{ color: 'grey', fontSize: '11px', paddingLeft: '0.7vw', paddingRight: 0 }}>
                            {image.postTime}
                        </Grid.Column>
                        <Grid.Column floated='right' textAlign="right" width={6} style={{ color: 'white', fontSize: '12px', paddingLeft: 0, paddingRight: 0 }}>
                            {
                                image.posted == 'Approved' ?
                                    <Label size='tiny' style={{ color: 'green', background: 'transparent' }}>
                                        <Icon name='check' /> Approved
                                    </Label>
                                    :
                                    image.posted == 'Rejected' ?
                                        <Label size='tiny' style={{ color: 'red', background: 'transparent' }}>
                                            <Icon name='close' /> Rejected
                                        </Label>
                                        :
                                        image.posted == 'Pending' ?
                                            <Label size='tiny' style={{ color: 'blue', background: 'transparent' }}>
                                                <Icon name='hourglass half' /> Pending
                                            </Label>
                                            : ''
                            }
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            );

            if (currentRow.length === 4 || i === imageInput.length - 1) {
                rows.push(
                    <React.Fragment key={i}>
                        <Grid.Row columns={4}>
                            {currentRow}
                        </Grid.Row>
                        {i !== imageInput.length - 1 && <Divider style={{ margin: 0 }} />}
                    </React.Fragment>

                );
                currentRow = [];
            }
        }
        return rows;
    };

    function convertUtcToLocal(utcDateString) {
        // Create a new Date object using the UTC time
        const date = new Date(utcDateString);

        // Convert it to the local time string
        return date.toLocaleString();
    }

    const showPrintedImages = () => {
        let rows = []

        for (let i = 0; i < printedImageInput.length; i++) {
            const image = printedImageInput[i];
            if (image.printed != 'Finished' && image.printed != 'Pending' && image.printed != 'Failed' && image.printed != 'Cancelled') {
                // meaning that printed status being null or empty 
                // then render nothing
                rows.push(<>
                </>)
            } else {
                rows.push(
                    <>
                        <Grid.Row columns={3}>
                            <Grid.Column style={{ textAlign: 'center' }} width={10} >
                                <Segment style={{ padding: 0 }}>
                                    <Grid columns={2} style={{ height: '20vh', margin: 0 }}>
                                        <Grid.Column width={4} style={{ paddingTop: 0, paddingBottom: 0, display: 'inline-grid', alignContent: 'center' }}>
                                            <Grid.Row>
                                                <Button fluid style={{
                                                    background: image.printed == 'Finished' ? '#0BAF68' :
                                                        image.printed == 'Pending' ? '#0A47C1' :
                                                            image.printed == 'Failed' ? '#F24462' :
                                                                image.printed == 'Cancelled' ? '#000033' : '#8b8989', color: '#fff'
                                                }}>
                                                    {
                                                        image.printed
                                                            ? image.printed == 'Finished'
                                                                ? 'Complete'
                                                                : image.printed == 'Pending'
                                                                    ? 'Pending'
                                                                    : image.printed == 'Failed'
                                                                        ? 'Failed'
                                                                        : image.printed == 'Cancelled'
                                                                            ? 'Cancelled'
                                                                            : 'Processing'
                                                            : 'Processing'
                                                    }
                                                </Button>
                                            </Grid.Row>
                                            <Grid.Row style={{ opacity: 0 }}>
                                                invisible
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Button size='big' fluid onClick={() => handleReprint(image._id, location.state.eventName)}
                                                    style={{ background: '#F0AEBA', color: '#fff', borderRadius: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    Reprint
                                                    <Image src={printerIcon} alt="Printer Icon" size='tiny' style={{ marginRight: '8px', height: '2em' }} />
                                                </Button>
                                            </Grid.Row>
                                        </Grid.Column>
                                        <Grid.Column width={12} style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'left', display: 'inline-grid', alignContent: 'center' }}>
                                            <Grid.Row style={{ padding: 0, fontWeight: '700', fontSize: '1.5em !important' }}>
                                                :Printing Device
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
                                                <span style={{ color: 'grey' }}>{image.computerID ? image.computerID : '12123'}</span>
                                                <span style={{ marginLeft: '8px' }}>-Computer ID</span>
                                                <span style={{ color: 'grey' }}>{image.computer ? image.computer : 'abc'}</span>
                                                <span style={{}}> -Computer Name </span>
                                                <Icon name="tv" style={{ marginRight: '8px' }}>
                                                </Icon>
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
                                                <span style={{ color: 'grey' }}>{image.printingDeviceID ? image.printingDeviceID : '123'}</span>
                                                <span style={{ marginLeft: '8px' }}>-Printer ID</span>
                                                <span style={{ color: 'grey' }}>{image.printingDevice ? image.printingDevice : 'abc'}</span>
                                                <span style={{}}>-Printer Name </span>

                                                <Image src={printerIcon} style={{ width: '1.2em', height: '1.2em', marginRight: '8px' }} />
                                            </Grid.Row>

                                            <Grid.Row style={{ opacity: 0 }}>
                                                invisible
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, fontWeight: '700', fontSize: '1.5em !important' }}>
                                                :Printing Time
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, color: 'grey' }}>
                                                {image.printingTime ? convertUtcToLocal(image.printingTime)
                                                    : '12/12/12 12:12:12'}
                                            </Grid.Row>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column style={{ textAlign: 'center' }} width={3} >
                                <Segment style={{ padding: 0 }}>
                                    <Grid.Row style={{ height: '20vh', display: 'inline-grid', alignContent: 'center', justifyContent: 'center', fontSize: "2em", fontWeight: '700' }}>
                                        {image.imagePosition.x + 1}/{image.imagePosition.y + 1}
                                    </Grid.Row>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column key={i} style={{ textAlign: 'left', overflowWrap: 'anywhere' }} width={3}>
                                <img src={`data:image/jpeg;base64,${image.generatedImageData}`} alt="Description"
                                    style={{
                                        // border: isSelected ? '5px solid blue' : '5px solid transparent',
                                        display: 'inline-block',
                                        height: '20vh'
                                    }} />
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )
            }
        }
        return rows;
    }

    const showPostSearchResult = () => {
        let rows = [];
        let currentRow = [];
    
        console.log(searchPostResult);
    
        for (let i = 0; i < searchPostResult.length; i++) {
            const image = searchPostResult[i];
            const isSelected = image._id === selectedImageId;
    
            console.log("is pushing to render ");
    
            currentRow.push(
                <Grid.Column key={i} style={{ position: 'relative', textAlign: 'left', overflowWrap: 'anywhere' }}>
                    <img src={`data:image/jpeg;base64,${image.imageData}`} alt="Description"
                        onClick={() => handleImageClick(image._id)}
                        style={{ display: 'inline-block', cursor: 'pointer', width: '100%', height: 'auto' }} />
                    {isSelected && (
                        <div
                            onClick={handleOverlayClose}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(255,255,255,0.3)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <div style={{ marginBottom: '20px', fontSize: '20px', fontWeight: 'bold', color: 'white' }}>Moderation</div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Button circular icon='check' color='green' onClick={(e) => { e.stopPropagation(); handleApprovePost(image._id); }} style={{ marginRight: '10px' }} />
                                <Button circular icon='close' color='red' onClick={(e) => { e.stopPropagation(); handleRejectPost(image._id); }} />
                            </div>
                        </div>
                    )}
                    <Grid style={{ marginLeft: 0, marginRight: 0, marginBottom: 0, marginTop: '-0.5vh', background: 'white' }}>
                        <Grid.Column floated='left' textAlign="left" width={10} style={{ color: 'grey', fontSize: '11px', paddingLeft: '0.7vw', paddingRight: 0 }}>
                            {image.postTime}
                        </Grid.Column>
                        <Grid.Column floated='right' textAlign="right" width={6} style={{ color: 'white', fontSize: '12px', paddingLeft: 0, paddingRight: 0 }}>
                            {
                                image.posted === 'Approved' ?
                                    <Label size='tiny' style={{ color: 'green', background: 'transparent' }}>
                                        <Icon name='check' /> Approved
                                    </Label>
                                    :
                                    image.posted === 'Rejected' ?
                                        <Label size='tiny' style={{ color: 'red', background: 'transparent' }}>
                                            <Icon name='close' /> Rejected
                                        </Label>
                                        :
                                        image.posted === 'Pending' ?
                                            <Label size='tiny' style={{ color: 'blue', background: 'transparent' }}>
                                                <Icon name='hourglass half' /> Pending
                                            </Label>
                                            : ''
                            }
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            );
    
            if (currentRow.length === 4 || i === searchPostResult.length - 1) {
                rows.push(
                    <React.Fragment key={i}>
                        <Grid.Row columns={4}>
                            {currentRow}
                        </Grid.Row>
                        {i !== searchPostResult.length - 1 && <Divider style={{ margin: 0 }} />}
                    </React.Fragment>
                );
                currentRow = [];
            }
        }
        return rows;
    };
    
    const showSearchResult = () => {
        let rows = []

        for (let i = 0; i < searchResult.length; i++) {
            const image = searchResult[i];
            if (image.printed != 'Finished' && image.printed != 'Pending' && image.printed != 'Failed' && image.printed != 'Cancelled') {
                // meaning that search result being null or empty 
                // then render nothing
                rows.push(<>
                </>)
            } else {
                rows.push(
                    <>
                        <Grid.Row columns={3}>
                            <Grid.Column style={{ textAlign: 'center' }} width={10} >
                                <Segment style={{ padding: 0 }}>
                                    <Grid columns={2} style={{ height: '20vh', margin: 0 }}>
                                        <Grid.Column width={4} style={{ paddingTop: 0, paddingBottom: 0, display: 'inline-grid', alignContent: 'center' }}>
                                            <Grid.Row>
                                                <Button fluid style={{
                                                    background: image.printed == 'Finished' ? '#0BAF68' :
                                                        image.printed == 'Pending' ? '#0A47C1' :
                                                            image.printed == 'Failed' ? '#F24462' :
                                                                image.printed == 'Cancelled' ? '#000033' : '#8b8989', color: '#fff'
                                                }}>
                                                    {
                                                        image.printed
                                                            ? image.printed == 'Finished'
                                                                ? 'Complete'
                                                                : image.printed == 'Pending'
                                                                    ? 'Pending'
                                                                    : image.printed == 'Failed'
                                                                        ? 'Failed'
                                                                        : image.printed == 'Cancelled'
                                                                            ? 'Cancelled'
                                                                            : 'Processing'
                                                            : 'Processing'
                                                    }
                                                </Button>
                                            </Grid.Row>
                                            <Grid.Row style={{ opacity: 0 }}>
                                                invisible
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Button size='big' fluid onClick={() => handleReprint(image._id, location.state.eventName)}
                                                    style={{ background: '#F0AEBA', color: '#fff', borderRadius: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    Reprint
                                                    <Image src={printerIcon} alt="Printer Icon" size='tiny' style={{ marginRight: '8px', height: '2em' }} />
                                                </Button>
                                            </Grid.Row>
                                        </Grid.Column>
                                        <Grid.Column width={12} style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'left', display: 'inline-grid', alignContent: 'center' }}>
                                            <Grid.Row style={{ padding: 0, fontWeight: '700', fontSize: '1.5em !important' }}>
                                                :Printing Device
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
                                                <span style={{ color: 'grey' }}>{image.computerID ? image.computerID : '12123'}</span>
                                                <span style={{ marginLeft: '8px' }}>-Computer ID</span>
                                                <span style={{ color: 'grey' }}>{image.computer ? image.computer : 'abc'}</span>
                                                <span style={{}}> -Computer Name </span>
                                                <Icon name="tv" style={{ marginRight: '8px' }}>
                                                </Icon>
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
                                                <span style={{ color: 'grey' }}>{image.printingDeviceID ? image.printingDeviceID : '123'}</span>
                                                <span style={{ marginLeft: '8px' }}>-Printer ID</span>
                                                <span style={{ color: 'grey' }}>{image.printingDevice ? image.printingDevice : 'abc'}</span>
                                                <span style={{}}>-Printer Name </span>

                                                <Image src={printerIcon} style={{ width: '1.2em', height: '1.2em', marginRight: '8px' }} />
                                            </Grid.Row>

                                            <Grid.Row style={{ opacity: 0 }}>
                                                invisible
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, fontWeight: '700', fontSize: '1.5em !important' }}>
                                                :Printing Time
                                            </Grid.Row>
                                            <Grid.Row style={{ padding: 0, color: 'grey' }}>
                                                {image.printingTime ? convertUtcToLocal(image.printingTime)
                                                    : '12/12/12 12:12:12'}
                                            </Grid.Row>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column style={{ textAlign: 'center' }} width={3} >
                                <Segment style={{ padding: 0 }}>
                                    <Grid.Row style={{ height: '20vh', display: 'inline-grid', alignContent: 'center', justifyContent: 'center', fontSize: "2em", fontWeight: '700' }}>
                                        {image.imagePosition.x + 1}/{image.imagePosition.y + 1}
                                    </Grid.Row>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column key={i} style={{ textAlign: 'left', overflowWrap: 'anywhere' }} width={3}>
                                <img src={`data:image/jpeg;base64,${image.generatedImageData}`} alt="Description"
                                    style={{
                                        // border: isSelected ? '5px solid blue' : '5px solid transparent',
                                        display: 'inline-block',
                                        height: '20vh'
                                    }} />
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )
            }
        }
        return rows;
    }

    useEffect(() => {
        let intervalId;

        const fetchDataBasedOnActiveButtons = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                navigate('/');
                // return;
            }

            await axios.post("https://wordwallrebuild.herokuapp.com/api/auth/verify", { mytoken: token });

            if (activeTab == "Imported Posts") {
                setImageInput([]);
                offsetRef.current = 0;
                setHasMore(true)

                if (isIpadRef.current) {
                    switch (activeButton) {
                        case 'allPosts':
                            // fetchFirstRoundImages(null)
                            break;
                        case 'approvedPosts':
                            // fetchFirstRoundImages('Approved')
                            break;
                        case 'rejectedPosts':
                            // fetchFirstRoundImages('Rejected')
                            break;
                        case 'pendingPosts':
                            // fetchFirstRoundImages('Pending')
                            break;
                        default:
                            break;
                    }
                } else {
                    console.log('on laptop')
                    switch (activeButton) {
                        case 'allPosts':
                            // fetchFirstRoundImages(null)
                            break;
                        case 'approvedPosts':
                            // fetchFirstRoundImages('Approved')
                            break;
                        case 'rejectedPosts':
                            // fetchFirstRoundImages('Rejected')
                            break;
                        case 'pendingPosts':
                            // fetchFirstRoundImages('Pending')
                            break;
                        default:
                            break;
                    }
                }
            }
            else if (activeTab == "Print Queue") {
                setPrintedImageInput([]);
                offsetRef1.current = 0;
                setHasMore1(true)
                // Handling printed images based on activeButton1
                if (isIpadRef.current) {
                    switch (activeButton1) {
                        case 'allPrints':

                            break;
                        case 'queuedPrints':

                            break;
                        case 'finishedPrints':

                            break;
                        case 'failedPrints':

                            break;
                        default:
                            break;
                    }
                } else {
                    console.log('on laptop')
                    switch (activeButton1) {
                        case 'allPrints':
                            fetchFirstRoundPrintedImages(null)
                            break;
                        case 'queuedPrints':
                            fetchFirstRoundPrintedImages('Pending')
                            break;
                        case 'finishedPrints':
                            fetchFirstRoundPrintedImages('Finished')
                            break;
                        case 'failedPrints':
                            fetchFirstRoundPrintedImages('Failed')
                            break;
                        default:
                            break;
                    }
                }
            }
            else if (activeTab == "Event Setting") {
                await fetchPrinterStatus()
            }
        };

        const longPollingFetch = async () => {
            if (live) {
                await fetchDataBasedOnActiveButtons();

                //print amount
                await fetchPrintingAmount()
                await fetchFinishedAmount()
                await fetchFailedAmount()
                await fetchQueuedAmount()

                //posts
                await fetchPostsAmount()
                await fetchApprovedAmount()
                await fetchRejectedAmount()
                await fetchPendingAmount()

                await fetchPrinterStatus()
            }
        };

        const normalFetch = async () => {
            await fetchDataBasedOnActiveButtons();
            //print amount
            await fetchPrintingAmount()
            await fetchFinishedAmount()
            await fetchFailedAmount()
            await fetchQueuedAmount()

            //posts
            await fetchPostsAmount()
            await fetchApprovedAmount()
            await fetchRejectedAmount()
            await fetchPendingAmount()

            await fetchPrinterStatus()
        }

        if (live) {
            // Execute immediately when `live` is turned on and then set up the interval
            longPollingFetch();
            intervalId = setInterval(longPollingFetch, 60000); // Adjust the polling interval as needed
        } else {
            normalFetch();
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [live, activeTab, activeButton, activeButton1]); // Dependencies list includes states that affect the fetching logic

    const handleSaveButton = () => {
        updatingDashboard();
    }

    const updatingDashboard = async () => {
        try {
            const token = localStorage.getItem("token");

            const headers = {
                'Custom-Header': 'CustomHeaderValue',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            };

            const data = {
                eventName: location.state.eventName, // replace with the name you want to search for
                postModerationButton: postModerationButton,
            }
            const response = await axios.put('https://wordwallrebuild.herokuapp.com/mosaicDashboard/updateDashboardSimplified', data, { headers: headers });
            if (response.status == 200) {
                Swal.fire('Success', 'Update successful', 'success');
            } else {
                Swal.fire('Error', 'Something went wrong while saving, please try again after re-login', 'error');
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getButtonStyle = (buttonName) => {
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1vw',
            marginRight: 0,
            border: '1px solid #121212',
            backgroundColor: activeButton == buttonName ? '#292d33' : 'transparent', // Conditional background color
            color: 'white',
            borderRadius: 0
        };
    };

    const getButtonStyle1 = (buttonName) => {
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1vw',
            marginRight: 0,
            border: '1px solid #121212',
            backgroundColor: activeButton1 == buttonName ? '#292d33' : 'transparent', // Conditional background color
            color: 'white',
            borderRadius: 0
        };
    };

    const circleStyle = {
        display: 'inline-block',
        backgroundColor: 'white', // Choose a color that fits your theme
        color: '#62707a', // Text color
        minWidth: '20px', // Minimum width to ensure circle shape
        height: '20px', // Height to match minWidth for circle shape
        borderRadius: '50%', // This creates the circle shape
        textAlign: 'center',
        lineHeight: '20px', // Center the number vertically
        marginLeft: '14px', // Space from the text
        fontWeight: 'bolder', // Optional: makes the number more prominent
    };

    const redirectToEventsPage = () => {
        navigate('/MosaicSimplifiedEventListPage')
    }


    const handlePrintAllPendings = async () => {
        try {
            // Replace 'yourEventName' with the actual event name you want to use
            const eventName = location.state.eventName;
            const response = await axios.post(`https://wordwallrebuild.herokuapp.com/mosaicDashboard/reprintAllPendings?eventName=${eventName}`);

            console.log(response.data.message);
        } catch (error) {
            console.error('Error reprinting all pending print jobs:', error);
        }
    };

    return (
        <div >
            <Grid style={{ margin: 0 }}>
                <Grid.Row columns={3} style={{ background: '#404b53', height: '10vh' }}>
                    <Grid.Column width={3} textAlign="left" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button labelPosition='left' icon='chevron left' content='Events' style={{ background: '#62707a', color: 'white' }} onClick={() => redirectToEventsPage()} />
                    </Grid.Column>
                    <Grid.Column width={10} textAlign="center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                        {location.state.eventName ? location.state.eventName : 'Invalid event name'}
                    </Grid.Column>
                    <Grid.Column width={3} textAlign="right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                        <Checkbox
                            toggle
                            label={
                                <label>
                                    <span style={{ color: '#ffffff' }}> {/* Inline style for white text */}
                                        {live ? "Live" : "Idle"}
                                    </span>
                                </label>
                            }
                            onChange={handleIdleChange}
                            checked={live}
                            className="custom-checkbox"

                        />
                    </Grid.Column>
                </Grid.Row>
                {
                    activeTab == 'Imported Posts' && (
                        <>
                            <Grid.Row style={{ background: '#62707a', height: '10vh' }}>
                                <Grid.Column textAlign="center" >
                                    <Grid style={{ margin: 0 }}>
                                        <Grid.Row columns={3}>
                                            <Grid.Column width={3}>

                                            </Grid.Column>
                                            <Grid.Column width={10} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Button style={getButtonStyle('allPosts')} onClick={() => handleActiveButtonClick('allPosts')}>
                                                    <div>ALL POSTS</div>
                                                    <div style={circleStyle}>{moderationTotalAmount}</div>
                                                </Button>

                                                <Button style={getButtonStyle('approvedPosts')} onClick={() => handleActiveButtonClick('approvedPosts')}>
                                                    <div>APPROVED POSTS</div>
                                                    <div style={circleStyle}>{moderationApprovedAmount}</div>
                                                </Button>

                                                <Button style={getButtonStyle('rejectedPosts')} onClick={() => handleActiveButtonClick('rejectedPosts')}>
                                                    <div>REJECTED POSTS</div>
                                                    <div style={circleStyle}>{moderationRejectedAmount}</div>
                                                </Button>

                                                <Button style={getButtonStyle('pendingPosts')} onClick={() => handleActiveButtonClick('pendingPosts')}>
                                                    <div>PENDING POSTS</div>
                                                    <div style={circleStyle}>{moderationPendingAmount}</div>
                                                </Button>
                                            </Grid.Column>
                                            <Grid.Column width={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>



                                            </Grid.Column>
                                        </Grid.Row >
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            <Segment style={{ height: '70vh', background: "#292d33", margin: 0, width: '100%' }}>
                                <Grid>
                                    <Grid.Column style={{ padding: '2vw', overflow: 'auto', maxHeight: '70vh', direction: 'rtl', scrollbarWidth: 'thin', scrollbarColor: '#695fca #dbdaee', scrollBehaviour: 'smooth' }}>
                                        <div style={{ direction: 'ltr', width: '100%', textAlign: 'left' }}  >
                                            <Grid>
                                                {
                                                    showImages()
                                                }
                                            </Grid>
                                        </div>
                                        <div ref={loadMoreRef}></div>
                                    </Grid.Column>
                                </Grid>

                            </Segment>
                        </>
                    )
                }
                {
                    activeTab == 'Search Posts' && (
                        <>
                            <Grid.Row style={{ background: '#62707a', height: '10vh' }}>
                                <Grid.Column textAlign="center" >
                                    <Grid style={{ margin: 0 }}>
                                        <Grid.Row >
                                            <Grid.Column style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div style={{ marginRight: '10px', fontSize: '3vh', color: 'white' }}>
                                                    Row Number:
                                                </div>
                                                <Input placeholder='Row' name='row' value={postRowNumber} onChange={handlePostRowNumberChange} style={{ marginRight: '20px' }} />
                                                <div style={{ marginRight: '10px', fontSize: '3vh', color: 'white' }}>
                                                    Col Number:
                                                </div>
                                                <Input placeholder='Col' name='col' value={postColNumber} onChange={handlePostColNumberChange} style={{ marginRight: '20px' }} />
                                                <Button onClick={fetchPostSearchResult} >
                                                    Search
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row >
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            <Segment style={{ height: '70vh', background: "#292d33", margin: 0, width: '100%' }}>
                                <Grid>
                                    <Grid.Column style={{ padding: '2vw', overflow: 'auto', maxHeight: '70vh', direction: 'ltr', scrollbarWidth: 'thin', scrollbarColor: '#695fca #dbdaee', scrollBehaviour: 'smooth' }}>
                                        <div style={{ direction: 'rtl', width: '100%', textAlign: 'left' }}>
                                            <Grid>
                                                {
                                                    showPostSearchResult()
                                                }
                                            </Grid>
                                        </div>
                                        {/* <div ref={loadMoreRef2}></div> */}
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </>
                    )
                }
                {
                    activeTab == 'Print Queue' && (
                        <>
                            <Grid.Row style={{ background: '#62707a', height: '10vh' }}>
                                <Grid.Column textAlign="center" >
                                    <Grid style={{ margin: 0 }}>
                                        <Grid.Row columns={3}>
                                            <Grid.Column width={3}>

                                            </Grid.Column>
                                            <Grid.Column width={10} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Button style={getButtonStyle1('allPrints')} onClick={() => handleActiveButtonClick1('allPrints')}>
                                                    <div>ALL PRINTS</div>
                                                    <div style={circleStyle}>{printingTotalAmount}</div>
                                                </Button>

                                                <Button style={getButtonStyle1('queuedPrints')} onClick={() => handleActiveButtonClick1('queuedPrints')}>
                                                    <div>QUEUED PRINTS</div>
                                                    <div style={circleStyle}>{printingQueuedAmount}</div>
                                                </Button>

                                                <Button style={getButtonStyle1('finishedPrints')} onClick={() => handleActiveButtonClick1('finishedPrints')}>
                                                    <div>FINISHED PRINTS</div>
                                                    <div style={circleStyle}>{printingFinishedAmount}</div>
                                                </Button>

                                                <Button style={getButtonStyle1('failedPrints')} onClick={() => handleActiveButtonClick1('failedPrints')}>
                                                    <div>FAILED PRINTS</div>
                                                    <div style={circleStyle}>{printingFailedAmount}</div>
                                                </Button>
                                            </Grid.Column>
                                            <Grid.Column width={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Button onClick={() => handlePrintAllPendings()}>
                                                    Print Pendings
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row >
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            <Segment style={{ height: '70vh', background: "#292d33", margin: 0, width: '100%' }}>
                                <Grid>
                                    <Grid.Column style={{ padding: '2vw', overflow: 'auto', maxHeight: '70vh', direction: 'ltr', scrollbarWidth: 'thin', scrollbarColor: '#695fca #dbdaee', scrollBehaviour: 'smooth' }}>
                                        <div style={{ direction: 'rtl', width: '100%', textAlign: 'left' }}>
                                            <Grid>
                                                {
                                                    showPrintedImages()
                                                }
                                            </Grid>
                                        </div>
                                        <div ref={loadMoreRef1}></div>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </>
                    )
                }
                {
                    activeTab == 'Search' && (
                        <>
                            <Grid.Row style={{ background: '#62707a', height: '10vh' }}>
                                <Grid.Column textAlign="center" >
                                    <Grid style={{ margin: 0 }}>
                                        <Grid.Row >
                                            <Grid.Column style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div style={{ marginRight: '10px', fontSize: '3vh', color: 'white' }}>
                                                    Row Number:
                                                </div>
                                                <Input placeholder='Row' name='row' value={rowNumber} onChange={handleRowNumberChange} style={{ marginRight: '20px' }} />
                                                <div style={{ marginRight: '10px', fontSize: '3vh', color: 'white' }}>
                                                    Col Number:
                                                </div>
                                                <Input placeholder='Col' name='col' value={colNumber} onChange={handleColNumberChange} style={{ marginRight: '20px' }} />
                                                <Button onClick={fetchSearchResult} >
                                                    Search
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row >
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            <Segment style={{ height: '70vh', background: "#292d33", margin: 0, width: '100%' }}>
                                <Grid>
                                    <Grid.Column style={{ padding: '2vw', overflow: 'auto', maxHeight: '70vh', direction: 'ltr', scrollbarWidth: 'thin', scrollbarColor: '#695fca #dbdaee', scrollBehaviour: 'smooth' }}>
                                        <div style={{ direction: 'rtl', width: '100%', textAlign: 'left' }}>
                                            <Grid>
                                                {
                                                    showSearchResult()
                                                }
                                            </Grid>
                                        </div>
                                        {/* <div ref={loadMoreRef2}></div> */}
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </>
                    )
                }
                {
                    activeTab == 'Event Setting' && (
                        <>
                            <Segment style={{ height: '80vh', background: "#292d33", margin: 0, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Grid style={{ width: 'auto' }}>
                                    <Grid.Row verticalAlign='middle' style={{ height: '100%' }}>
                                        <Grid.Column textAlign='center'>
                                            {/* Container for the label and dropdown */}
                                            <div style={{ marginBottom: '20px' }}> {/* Added container for better control */}
                                                <label style={{ display: 'block', color: '#ffffff', marginBottom: '10px', fontSize: '16px' }}>
                                                    Post Moderation Mode
                                                </label>
                                                <Dropdown
                                                    selection
                                                    options={postModerationOptions}
                                                    value={postModerationButton}
                                                    onChange={handlePostModerationButtonChange}
                                                    placeholder='Select'
                                                    style={{
                                                        backgroundColor: '#f2f2f7',
                                                        margin: 'auto', // Ensure the dropdown is centered
                                                    }}
                                                />
                                            </div>
                                            <Button onClick={handleSaveButton} style={{ marginTop: '20px' }}>
                                                Save
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </>
                    )
                }

            </Grid>

            <div style={{
                position: 'fixed',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid black',
                background: '#fff',
                padding: '2vh 0', // Added padding for better spacing
                background: '#404b53',
                height: '10vh'
            }}>
                <Grid container style={{ width: '100%' }}>
                    <Grid.Row textAlign="center">
                        <Grid.Column width={3}>
                            {/* Adjusted button for Imported Posts with appropriate spacing */}
                            <Button icon style={{ background: 'transparent', color: 'white' }} onClick={() => handleTabClick('Imported Posts')}>
                                <Icon name='grid layout' />
                                <span style={{ marginLeft: '4px' }}>Imported Posts</span>
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Button icon style={{ background: 'transparent', color: 'white' }} onClick={() => handleTabClick('Search Posts')}>
                                <Icon name='search' />
                                <span style={{ marginLeft: '4px' }}>Search Posts</span>
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            {/* Adjusted button for Print Queue with appropriate spacing */}
                            <Button icon style={{ background: 'transparent', color: 'white' }} onClick={() => handleTabClick('Print Queue')}>
                                <Icon name='list' />
                                <span style={{ marginLeft: '4px' }}>Print Queue</span>
                            </Button>
                            <Label as='a' style={{ background: printerStatus == 'online' ? '#7fce83' : '#d57a80', colro: 'white' }}>
                                {
                                    printerStatus
                                }
                                {
                                    printerStatus == 'online' ? <Icon name='check' style={{ paddingLeft: '1vw' }} /> : <Icon name='delete' />
                                }
                            </Label>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Button icon style={{ background: 'transparent', color: 'white' }} onClick={() => handleTabClick('Search')}>
                                <Icon name='search' />
                                <span style={{ marginLeft: '10px' }}>Search Print</span>
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {/* Adjusted button for Event Setting with appropriate spacing */}
                            <Button icon style={{ background: 'transparent', color: 'white' }} onClick={() => handleTabClick('Event Setting')}>
                                <Icon name='settings' />
                                <span style={{ marginLeft: '4px' }}>Event Setting</span>
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>

        </div >
    )


}

export default MosaicSimplifiedDashboard;