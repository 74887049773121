import React, { useState, useEffect, useRef } from "react";
import { Grid, Button, Modal, Image, Header } from "semantic-ui-react";
import "./LivePhotoImportPortal.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import logo from '.././img/samplelogo2.png';
import LiveMosaicLoginPageMobile from "./LiveMosaicLoginPageMobile"
import Swal from 'sweetalert2';

function getQueryParamValue(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}

const LivePhotoImportPortal = () => {
  const [welcomeOpen, setWelcomeOpen] = useState(false)
  const [loginOpen, setLoginOpen] = useState(false)

  const location = useLocation();

  const [mosaicButtonFillColor, setMosaicButtonFillColor] = useState('')
  const [loginTextColor, setLoginTextColor] = useState('')
  const [loginButtonColor, setLoginButtonColor] = useState('')

  const [mosaicOverlayImageData, setMosaicOverlayImageData] = useState('');
  const eventName = getQueryParamValue("eventName");

  const loginOpenRef = useRef(loginOpen);

  // Update ref whenever loginOpen changes
  useEffect(() => {
    loginOpenRef.current = loginOpen;
  }, [loginOpen]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const fetchDataAndSetLoading = async () => {
      try {
        Swal.fire({
          title: 'Loading Contents...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        await fetchData();
        Swal.close()
        setWelcomeOpen(true)
      } catch (error) {
        console.error(error);
      }
    };
    fetchDataAndSetLoading();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://wordwallrebuild.herokuapp.com/mosaicDashboard/event-dashboard",
        {
          params: {
            eventName: eventName ? eventName : location.state.eventName,
          },
        }
      );

      setMosaicButtonFillColor(response.data.mosaicButtonFillColor)
      setLoginTextColor(response.data.loginTextColor)
      setLoginButtonColor(response.data.loginButtonColor)
      setMosaicOverlayImageData(response.data.mosaicOverlayImageData)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleContinue = () => {
    setWelcomeOpen(false);
    setLoginOpen(true);
  };

  return (
    <div
      style={{
        backgroundImage: `url(data:image/jpg;base64,${mosaicOverlayImageData})`,
        objectFit: 'contain',
        width: '100%',
        height: '100vh',
        margin: 0,
        padding: 0
      }}
    >
      <Grid
        centered
        style={{ height: "100vh", position: "relative", padding: 0, marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0 }}
      >
        <Grid.Column
          width={16}
          className="image-layer"
          textAlign="center"
          verticalAlign="middle"
          style={{ height: "100vh", padding: 0, margin: 0 }}
        >
          <Modal
            closeOnEscape={false}
            closeOnDimmerClick={false}
            onClose={() => setWelcomeOpen(false)}
            onOpen={() => setWelcomeOpen(true)}
            open={welcomeOpen}
            size="tiny"
          >
            <Modal.Header style={{ textAlign: 'center' }}>
              <Image src={logo} style={{ display: 'inline-block' }} />
            </Modal.Header>
            <Modal.Content style={{ textAlign: 'center' }}>
              <Modal.Description>
                <Header style={{ color: loginTextColor }}>
                  Welcome
                </Header>
                <p style={{ color: loginTextColor }} >
                  woohoo
                </p>
              </Modal.Description>
              <Button onClick={handleContinue} style={{ display: 'inline-block', color: loginTextColor, background: loginButtonColor,background:mosaicButtonFillColor }}>
                Continue
              </Button>
            </Modal.Content>
          </Modal>

          <Modal
            onClose={() => setLoginOpen(false)}
            onOpen={() => setLoginOpen(true)}
            open={loginOpen}
            size='tiny'
          >
            <LiveMosaicLoginPageMobile myEventName={eventName} />
          </Modal>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default LivePhotoImportPortal;
